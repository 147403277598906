import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function AccountSetting() { 
  const navigate=useNavigate();
  const handleClick=(route)=>{
    navigate (route);
  }
  return (
 <div className="account-setting-container">
  
    <div className="dummy-section"></div>

    <div className="account-setting-content">
    <div className="header-text"> <a style={{color:'white'}} href="/customer-service">Customer-Service </a> &nbsp;&gt;&gt; &nbsp;Account-FAQ</div>
      <div className="account-setting-content-faq">
        <div style={{padding:'5px',textAlign:'start'}} className="faq-header">
          <h2 >Account Setting</h2>
        </div>
    <details style={{marginTop:'5px'}}>
              <summary>Update mobile number/email ID on the tripinbudget website. </summary>
              <div className='faqopen'>
              <blockquote className='faqopen-blockquote'>
                <p>Log in to the tripinbudget website, click on Profile and select Edit/View Profile. You will be able to update your contact details from there.</p>
              </blockquote>
              </div>
              
            </details>

            <details style={{marginTop:'5px'}}>
              <summary>Update mobile number/email ID for IRCTC Account. </summary>
              <div className='faqopen'>
              <blockquote className='faqopen-blockquote'>
                <p>You can easily edit your phone number/ Email for your IRCTC account from the My Account section on the IRCTC website once you have signed in. Click on My profile and then Update profile to make the necessary changes.</p>
              </blockquote>
              </div>
              
            </details>
            <details style={{marginTop:'5px'}}>
              <summary>Steps to login to your tripinbudget account. </summary>
              <div className='faqopen'>
              <blockquote className='faqopen-blockquote'>
                <p>Please follow the steps below to log in to your ixigo account:</p>
                <p>1. Enter your email ID</p>
                <p>2. You will receive an OTP on the mentioned email ID</p>
                <p>3. Enter the OTP to log in to your tripinbudget account.</p>
              </blockquote>
              </div>
              
            </details>

            <details style={{marginTop:'5px'}}>
              <summary>Reset password for IRCTC account. </summary>
              <div className='faqopen'>
              <blockquote className='faqopen-blockquote'>
                <p>Go to your Profile page in the tripinbudget website, click on the profile: </p>
                <p> - Tap on "Link your IRCTC Account".</p>
                <p> - Click on "Reset your IRCTC password".</p>
                <p> - Enter your IRCTC user ID and registered email id, and then click on the Proceed tab.</p><br>
                </br>
                <p>You will receive a new password from IRCTC on your registered email id.</p>


              </blockquote>
              </div>
              
            </details>
            <div className="account-button">
              <button className='btn btn-primary ' style={{width:'auto'}} onClick={()=>handleClick('/login')}> Open Account Page</button>
            </div>
            </div>
    </div>
 </div>
  )
}
