/*
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

function ResultPage() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const origin = queryParams.get('origin');
  const destination = queryParams.get('destination');
  const date = queryParams.get('date');
  const traveler = queryParams.get('travelerType');
  const [travelerType, setTravelerType] = useState(traveler || 'ADULT');
  const [availabilities, setAvailabilities] = useState([]);
  const [offers, setOffers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [originLocationCode, setoriginLocationCode] = useState(origin ? { value: origin, label: origin } : '');
  const [destinationLocationCode, setDestinationLocationCode] = useState(destination ? { value: destination, label: destination } : '');
  const [departureDate, setDepartureDate] = useState(date||'');
  const [response, setResponse] = useState(null);
  const [searchTermTo, setSearchTermTo] = useState('');
  const [destinationOptions, setDestinationOptions] = useState([]);
  const [errorTo, setErrorTo] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [originOptions, setOriginOptions] = useState([]);
  const navigate=useNavigate();
  const [showDialog, setShowDialog] = useState(false);
  const [selectedFlight, setSelectedFlight] = useState(null);

  const handleBookNow = (flight) => {
    setSelectedFlight(flight);
    setShowDialog(true);
  };
  const handleSubmitBooking = async () => {
    try {
      const response = await axios.post(`${BASE_URL}/submit-booking', {
        airlineName: selectedFlight.itineraries[0].segments[0].carrierCode,
        flightNumber: `${selectedFlight.itineraries[0].segments[0].carrierCode}-${selectedFlight.itineraries[0].segments[0].number}`,
        departureLocation: selectedFlight.itineraries[0].segments[0].departure.iataCode,
        departureDateTime: `${formatDate(selectedFlight.itineraries[0].segments[0].departure.at)} - ${formatTime(selectedFlight.itineraries[0].segments[0].departure.at)}`,
        destinationLocation: selectedFlight.itineraries[0].segments[0].arrival.iataCode,
        arrivalDateTime: `${formatDate(selectedFlight.itineraries[0].segments[0].arrival.at)} - ${formatTime(selectedFlight.itineraries[0].segments[0].arrival.at)}`,
        totalDuration: formatDuration(selectedFlight.itineraries[0].duration),
        travelerType: travelerType,
      });
      console.log('Flight Booking Data Send Successfullyy!!'); // Log the response message
  
      // Show toast notification on success
      toast.success('Flight booking data sent successfully! Our member will contact you soon.', {
        onClose: () => {
          // Close the dialog box or perform any other action
          setShowDialog(false);
        }
      });
    } catch (error) {
      console.error('Error submitting booking:', error);
    }
  };
  
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        // First API for searching by name
        const responseName = await axios.get('https://airports15.p.rapidapi.com/airports', {
          params: { name: searchTerm },
          headers: {
            'X-RapidAPI-Key': 'baa5306601msh44eade617a3c3d1p188b0djsn9f7772669cc0',
            'X-RapidAPI-Host': 'airports15.p.rapidapi.com',
          },
        });
        // Second API for searching by IATA code
        const responseIata = await axios.get('https://airport-info.p.rapidapi.com/airport', {
          params: { iata: searchTerm },
          headers: {
            'X-RapidAPI-Key': 'baa5306601msh44eade617a3c3d1p188b0djsn9f7772669cc0',
            'X-RapidAPI-Host': 'airport-info.p.rapidapi.com',
          },
        });
        // Combine the results from both APIs
        const options = [
          ...responseName.data.map((airport) => ({
            value: airport.iataCode,
            label: `${airport.iataCode} - ${airport.city}`,
          })),
          {
            value: responseIata.data.iata,
            label: `${responseIata.data.iata} - ${responseIata.data.city}`,
          },
        ];
        setOriginOptions(options);
        setError(null);
      } catch (error) {
        setOriginOptions([]);
        setError(error.message);
      }
    };
    if (searchTerm) {
      fetchData();
    }
  }, [searchTerm]);

  useEffect(() => {
    const fetchDestinationData = async () => {
      try {
        // First API for searching by name
        const responseName = await axios.get('https://airports15.p.rapidapi.com/airports', {
          params: { name: searchTermTo },
          headers: {
            'X-RapidAPI-Key': 'baa5306601msh44eade617a3c3d1p188b0djsn9f7772669cc0',
            'X-RapidAPI-Host': 'airports15.p.rapidapi.com',
          },
        });
        // Second API for searching by IATA code
        const responseIata = await axios.get('https://airport-info.p.rapidapi.com/airport', {
          params: { iata: searchTermTo },
          headers: {
            'X-RapidAPI-Key': 'baa5306601msh44eade617a3c3d1p188b0djsn9f7772669cc0',
            'X-RapidAPI-Host': 'airport-info.p.rapidapi.com',
          },
        });
        // Combine the results from both APIs
        const OptionsTo = [
          ...responseName.data.map((airportTo) => ({
            value: airportTo.iataCode,
            label: `${airportTo.iataCode} - ${airportTo.city}`,
          })),
          {
            value: responseIata.data.iata,
            label: `${responseIata.data.iata} - ${responseIata.data.city}`,
          },
        ];
        setDestinationOptions(OptionsTo);
        setError(null);
      } catch (error) {
        setDestinationOptions([]);
        setError(error.message);
      }
    };
    if (searchTermTo) {
      fetchDestinationData();
    }
  }, [searchTermTo]);

  const airlineNames = {
    UK: 'Vistara',
    SG: 'SpiceJet',
    H1: 'Hahn Air',
    AI: 'Air India',
  };
  const airlineLogos = {
    AI: "airindia.png",
    UK: "Vistara_Logo.svg.png",
    SG: "SpiceJet-Logo.png",
    H1: "hahnair.png",
    // Add other carrier codes and their respective logos here
  };
  const getAirlineLogo = (carrierCode) => {
    if (carrierCode in airlineLogos) {
      return require(`../Images/${airlineLogos[carrierCode]}`);
    }
    // If no matching logo found, return a default image or null
    return null;
  };
  
  useEffect(() => {
   
    const fetchOffers = async () => {
      try {
        const response = await axios.post(`${BASE_URL}/flight-offers', {
          originLocationCode: origin,
          destinationLocationCode: destination,
          departureDate: date,
          travelerType: travelerType,
        });
        setOffers(response.data.data);
      } catch (error) {
        setError(error.message);
      }
    };
    const fetchData = async () => {
      setLoading(true);
      await Promise.all([ fetchOffers()]);
      setLoading(false);
    };
    fetchData();
  }, [origin, destination, date, travelerType]);
 
//if loading added the loader using html css
  if (loading) return <div style={{padding:'20px',display:'flex',justifyContent:'center',alignItems:'center',backgroundColor:'black'}}><div class="loader">
  <span></span>
</div></div>;
  if (error) return <div style={{padding:'50px'}}>Error: {error}</div>;
  const handleSubmit = async () => {
    try {
      // Redirect to results page with query parameters inside the url
      navigate(`/flightavailable/result?origin=${originLocationCode.value}&destination=${destinationLocationCode.value}&date=${departureDate}&travelerType=${travelerType}`);
    } catch (error) {
      console.error('Error:', error.message);
    }
  };
// Function to format date
const formatDate = (dateString) => {
  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  return new Date(dateString).toLocaleDateString('en-US', options);
};

// Function to format time
const formatTime = (timeString) => {
  const options = { hour: 'numeric', minute: '2-digit', hour12: true };
  return new Date(timeString).toLocaleTimeString('en-US', options);
};

// Function to format duration from PT1H5M to 01 h 5 min
function formatDuration(durationString) {
  console.log('durationString:', durationString);
  if (durationString.startsWith('PT')) {
    const hoursMatch = durationString.match(/(\d+)H/);
    const hours = hoursMatch ? hoursMatch[1] : 0;
    const minutesMatch = durationString.match(/(\d+)M/);
    const minutes = minutesMatch ? minutesMatch[1] : 0;
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    console.log('hours:', hours);
    console.log('minutes:', minutes);
    return `${formattedHours} h ${formattedMinutes} min`;
  } else {
    return 'Invalid duration format';
  }
}
  return (
    <div>  
      <div className="dummy2"></div>
      <div className='availablecontaineronly'>
        <div className="availablecontainerrowonly">
          <div className="flightcol">
            <label className="form-label">Origin Location Code:</label>
            <Select
        options={originOptions}
        value={originLocationCode}
        onChange={(selectedOption) => setoriginLocationCode(selectedOption)}
        onInputChange={(inputValue) => setSearchTerm(inputValue)}
        placeholder="Enter airport"
      />
          </div>
          <div className="flightcol">
            <label className="form-label">Destination Location Code:</label>
            <Select
        options={destinationOptions}
        value={destinationLocationCode}
        onChange={(selectedOption) => setDestinationLocationCode(selectedOption)}
        onInputChange={(inputValueTo) => setSearchTermTo(inputValueTo)}
        placeholder="Enter airport"
      />
          </div>
          <div className="flightcol">
            <label className="form-label">Departure Date:</label>
            <input className="form-control" type="date" value={departureDate} onChange={(e) => setDepartureDate(e.target.value)} />
          </div>
          <div className="flightcol">
  <label className="form-label">Traveler Type:</label>
  <Select
    options={[
      { value: 'ADULT', label: 'Adult' },
      
    ]}
    value={{ value: travelerType, label: travelerType }} // Set the value and label
    onChange={(selectedOption) => setTravelerType(selectedOption.value)} // Set the selected value
    placeholder="Select traveler type"
  />
</div>
          <div className="flightcol">
            <button className="btn btn-primary" onClick={handleSubmit}>Modify Search</button>
          </div>
        </div>
      </div>
      
      <h2>Flight Offers:</h2>
  


      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {offers.map((flight) => (
          <div key={flight.id} style={{ border: '1px solid navy', borderRadius: '5px', padding: '10px', margin: '10px', width: '700px', display: 'flex', flexWrap: 'wrap', backgroundColor: 'white' }}>
            <div className="flexcol" style={{ flex: '1',}}>
            <p style={{fontSize:'1.2rem',fontWeight:'700',textAlign:'start'}}>{airlineNames[flight.itineraries[0].segments[0].carrierCode]}</p>
            <p style={{textAlign:'start'}}>{flight.itineraries[0].segments[0].carrierCode}-{flight.itineraries[0].segments[0].number}</p>


            {flight.itineraries[0].segments[0].carrierCode && (
              <span style={{ width: '70px', height: '50px',display:'flex',justifyContent:'center',alignItems:'center',padding:'5px' }}>
                <img src={getAirlineLogo(flight.itineraries[0].segments[0].carrierCode)} alt={`${flight.itineraries[0].segments[0].carrierCode} Logo`} style={{ width: '100%', height: '100%' }} />
              </span>
            )}

            </div>
       
            <div className="flexcol" style={{ flex: '1',  }}>
            <p style={{fontSize:'0.8rem'}}>From</p>
              <p style={{fontWeight:'600',fontSize:'1.8rem'}}>{flight.itineraries[0].segments[0].departure.iataCode}</p>
              <p style={{  fontSize: '0.8rem' }}>
                {formatDate(flight.itineraries[0].segments[0].departure.at)}
              </p>
              <p style={{  fontSize: '0.8rem' }}>{formatTime(flight.itineraries[0].segments[0].departure.at)}</p>

            </div>
            <div className="flexcol" style={{ flex: '1', margin: '5px' }}>
            <p style={{padding:'5px'}}>Duration</p>
         
    <p><hr />
    </p>   <p>{formatDuration(flight.itineraries[0].duration)}</p>
            </div>
            <div className="flexcol" style={{ flex: '1',}}>
            <p style={{fontSize:'0.8rem'}}>To</p>
              <p style={{fontWeight:'600',fontSize:'1.8rem'}}>{flight.itineraries[0].segments[0].arrival.iataCode}</p>
              <p style={{  fontSize: '0.8rem' }}>
                {formatDate(flight.itineraries[0].segments[0].arrival.at)}
              </p>
              <p style={{  fontSize: '0.8rem' }}>{formatTime(flight.itineraries[0].segments[0].arrival.at)}</p>


            </div>
            <div className="flexcol" style={{ flex: '1', margin: '5px' }}>
              <p><button className="btn btn-primary" onClick={() => handleBookNow(flight)}>Book Now!</button></p>
            <p><i class="fa-solid fa-indian-rupee-sign"></i> &nbsp;{flight.price.grandTotal}</p>
            </div>
           
          </div>
        ))}
      </div>

      {showDialog && (
  <div className="dialog-container">
<div className="dialog">
  <center>Selected Flight Data</center>
    <table  >
    <tr>
    <th>Airline Name:</th>
    <td>{airlineNames[selectedFlight.itineraries[0].segments[0].carrierCode]}</td>
    <th>Flight Number:</th>
    <td>{selectedFlight.itineraries[0].segments[0].carrierCode}-{selectedFlight.itineraries[0].segments[0].number}</td>
  </tr>
  <tr>
    <th>Departure Location Code:</th>
    <td>{selectedFlight.itineraries[0].segments[0].departure.iataCode}</td>
    <th>Departure Date & Time:</th>
    <td>{formatDate(selectedFlight.itineraries[0].segments[0].departure.at)}-{formatTime(selectedFlight.itineraries[0].segments[0].departure.at)}</td>
  </tr>
  <tr>
    <th>Destination Location Code:</th>
    <td>{selectedFlight.itineraries[0].segments[0].arrival.iataCode}</td>
    <th>Arrival Date & Time:</th>
    <td>{formatDate(selectedFlight.itineraries[0].segments[0].arrival.at)}-{formatTime(selectedFlight.itineraries[0].segments[0].arrival.at)}</td>
  </tr>
 
  <tr>
    <th>Total Duration:</th>
    <td>{formatDuration(selectedFlight.itineraries[0].duration)}</td>
    <th>Traveler Type:</th>
    <td>{formatDuration(selectedFlight.itineraries[0].duration)}</td>
  </tr>

  <tr>
    <th>Total Traveler:</th>
    <td>01</td>
  </tr>
  <tr>
    <th>Traveler Class:</th>
    <td>ECONOMY</td>
  </tr>
</table>
<button onClick={handleSubmitBooking}>Submit</button>
      <button onClick={() => setShowDialog(false)}>Close</button>
  </div>
  </div>
)}


    </div>
  );
}

export default ResultPage;
*/

import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import BASE_URL from '../Components/apiConfig';

function ResultPage() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const origin = queryParams.get('origin');
  const destination = queryParams.get('destination');
  const date = queryParams.get('date');
  const traveler = queryParams.get('travelerType');
  const [travelerType, setTravelerType] = useState(traveler || 'ADULT');
  const [availabilities, setAvailabilities] = useState([]);
  const [offers, setOffers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [originLocationCode, setoriginLocationCode] = useState(origin ? { value: origin, label: origin } : '');
  const [destinationLocationCode, setDestinationLocationCode] = useState(destination ? { value: destination, label: destination } : '');
  const [departureDate, setDepartureDate] = useState(date||'');
  const [response, setResponse] = useState(null);
  const [searchTermTo, setSearchTermTo] = useState('');
  const [destinationOptions, setDestinationOptions] = useState([]);
  const [errorTo, setErrorTo] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [originOptions, setOriginOptions] = useState([]);
  const navigate=useNavigate();
  const [showDialog, setShowDialog] = useState(false);
  const [showLoginDialog, setShowLoginDialog] = useState(false);
  const [selectedFlight, setSelectedFlight] = useState(null);

  const [userData, setUserData] = useState(null);

  useEffect(() => {
    // Fetch user data if user is logged in
    const isLoggedIn = localStorage.getItem('isLoggedIn');
    if (isLoggedIn) {
      const userEmail = localStorage.getItem('email');
      fetchUserData(userEmail);
    }
  }, []);

  const fetchUserData = async (email) => {
    try {
      const response = await axios.post(`${BASE_URL}/api/userdata`, { email });
      setUserData(response.data.userData);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const handleBookNow = async (flight) => {
    const isLoggedIn = localStorage.getItem('isLoggedIn');

    if (isLoggedIn) {
      setSelectedFlight(flight);
      setShowDialog(true);

      // Fetch user data
      const userEmail = localStorage.getItem('email');
      await fetchUserData(userEmail);
    } else {
      setShowLoginDialog(true);
    }
  };
  const handleLogin = () => {
    // Your logic to handle user login, e.g., redirect to login page
    navigate('/login');
  };
  const handleSubmitBooking = async () => {
    try {
   
      const response = await axios.post(`${BASE_URL}/submit-booking`, {
        airlineName:
        
        `${airlineNames[selectedFlight.itineraries[0].segments[0].carrierCode]}`,
        flightNumber: `${selectedFlight.itineraries[0].segments[0].carrierCode}-${selectedFlight.itineraries[0].segments[0].number}`,
        departureLocation: selectedFlight.itineraries[0].segments[0].departure.iataCode,
        departureDateTime: `${formatDate(selectedFlight.itineraries[0].segments[0].departure.at)} - ${formatTime(selectedFlight.itineraries[0].segments[0].departure.at)}`,
        destinationLocation: selectedFlight.itineraries[0].segments[0].arrival.iataCode,
        arrivalDateTime: `${formatDate(selectedFlight.itineraries[0].segments[0].arrival.at)} - ${formatTime(selectedFlight.itineraries[0].segments[0].arrival.at)}`,
        totalDuration: formatDuration(selectedFlight.itineraries[0].duration),
        travelerType: travelerType,
      userData:userData,
      });
      console.log('Flight Booking Data Send Successfullyy!!'); // Log the response message
  
      // Show toast notification on success
      toast.success('Flight booking data sent successfully! Our member will contact you soon.', {
        onClose: () => {
          // Close the dialog box or perform any other action
          setShowDialog(false);
        }
      });
    } catch (error) {
      console.error('Error submitting booking:', error);
    }
  };
  
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        // First API for searching by name
        const responseName = await axios.get('https://airports15.p.rapidapi.com/airports', {
          params: { name: searchTerm },
          headers: {
            'X-RapidAPI-Key': 'baa5306601msh44eade617a3c3d1p188b0djsn9f7772669cc0',
            'X-RapidAPI-Host': 'airports15.p.rapidapi.com',
          },
        });
        // Second API for searching by IATA code
        const responseIata = await axios.get('https://airport-info.p.rapidapi.com/airport', {
          params: { iata: searchTerm },
          headers: {
            'X-RapidAPI-Key': 'baa5306601msh44eade617a3c3d1p188b0djsn9f7772669cc0',
            'X-RapidAPI-Host': 'airport-info.p.rapidapi.com',
          },
        });
        // Combine the results from both APIs
        const options = [
          ...responseName.data.map((airport) => ({
            value: airport.iataCode,
            label: `${airport.iataCode} - ${airport.city}`,
          })),
          {
            value: responseIata.data.iata,
            label: `${responseIata.data.iata} - ${responseIata.data.city}`,
          },
        ];
        setOriginOptions(options);
        setError(null);
      } catch (error) {
        setOriginOptions([]);
        setError(error.message);
      }
    };
    if (searchTerm) {
      fetchData();
    }
  }, [searchTerm]);

  useEffect(() => {
    const fetchDestinationData = async () => {
      try {
        // First API for searching by name
        const responseName = await axios.get('https://airports15.p.rapidapi.com/airports', {
          params: { name: searchTermTo },
          headers: {
            'X-RapidAPI-Key': 'baa5306601msh44eade617a3c3d1p188b0djsn9f7772669cc0',
            'X-RapidAPI-Host': 'airports15.p.rapidapi.com',
          },
        });
        // Second API for searching by IATA code
        const responseIata = await axios.get('https://airport-info.p.rapidapi.com/airport', {
          params: { iata: searchTermTo },
          headers: {
            'X-RapidAPI-Key': 'baa5306601msh44eade617a3c3d1p188b0djsn9f7772669cc0',
            'X-RapidAPI-Host': 'airport-info.p.rapidapi.com',
          },
        });
        // Combine the results from both APIs
        const OptionsTo = [
          ...responseName.data.map((airportTo) => ({
            value: airportTo.iataCode,
            label: `${airportTo.iataCode} - ${airportTo.city}`,
          })),
          {
            value: responseIata.data.iata,
            label: `${responseIata.data.iata} - ${responseIata.data.city}`,
          },
        ];
        setDestinationOptions(OptionsTo);
        setError(null);
      } catch (error) {
        setDestinationOptions([]);
        setError(error.message);
      }
    };
    if (searchTermTo) {
      fetchDestinationData();
    }
  }, [searchTermTo]);

  const airlineNames = {
    UK: 'Vistara',
    SG: 'SpiceJet',
    H1: 'Hahn Air',
    AI: 'Air India',
  };
  const airlineLogos = {
    AI: "airindia.png",
    UK: "Vistara_Logo.svg.png",
    SG: "SpiceJet-Logo.png",
    H1: "hahnair.png",
    // Add other carrier codes and their respective logos here
  };
  const getAirlineLogo = (carrierCode) => {
    if (carrierCode in airlineLogos) {
      return require(`../Images/${airlineLogos[carrierCode]}`);
    }
    // If no matching logo found, return a default image or null
    return null;
  };
  
  useEffect(() => {
   
    const fetchOffers = async () => {
      try {
        const response = await axios.post(`${BASE_URL}/flight-offers`, {
          originLocationCode: origin,
          destinationLocationCode: destination,
          departureDate: date,
          travelerType: travelerType,
        });
        setOffers(response.data.data);
      } catch (error) {
        setError(error.message);
      }
    };
    const fetchData = async () => {
      setLoading(true);
      await Promise.all([ fetchOffers()]);
      setLoading(false);
    };
    fetchData();
  }, [origin, destination, date, travelerType]);
 
//if loading added the loader using html css
  if (loading) return <div style={{padding:'20px',display:'flex',justifyContent:'center',alignItems:'center',backgroundColor:'black'}}><div class="loader">
  <span></span>
</div></div>;
  if (error) return <div style={{padding:'50px'}}>Error: {error}</div>;
  const handleSubmit = async () => {
    try {
      // Redirect to results page with query parameters inside the url
      navigate(`/flightavailable/result?origin=${originLocationCode.value}&destination=${destinationLocationCode.value}&date=${departureDate}&travelerType=${travelerType}`);
    } catch (error) {
      console.error('Error:', error.message);
    }
  };
// Function to format date
const formatDate = (dateString) => {
  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  return new Date(dateString).toLocaleDateString('en-US', options);
};

// Function to format time
const formatTime = (timeString) => {
  const options = { hour: 'numeric', minute: '2-digit', hour12: true };
  return new Date(timeString).toLocaleTimeString('en-US', options);
};

// Function to format duration from PT1H5M to 01 h 5 min
function formatDuration(durationString) {
  console.log('durationString:', durationString);
  if (durationString.startsWith('PT')) {
    const hoursMatch = durationString.match(/(\d+)H/);
    const hours = hoursMatch ? hoursMatch[1] : 0;
    const minutesMatch = durationString.match(/(\d+)M/);
    const minutes = minutesMatch ? minutesMatch[1] : 0;
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    console.log('hours:', hours);
    console.log('minutes:', minutes);
    return `${formattedHours} h ${formattedMinutes} min`;
  } else {
    return 'Invalid duration format';
  }
}    
  return (
    <div>  
      <div className="dummy2"></div>
      <div className='availablecontaineronly'>
        <div className="availablecontainerrowonly">
          <div className="flightcol">
            <label className="form-label">Origin Location Code:</label>
            <Select
        options={originOptions}
        value={originLocationCode}
        onChange={(selectedOption) => setoriginLocationCode(selectedOption)}
        onInputChange={(inputValue) => setSearchTerm(inputValue)}
        placeholder="Enter airport"
      />
          </div>
          <div className="flightcol">
            <label className="form-label">Destination Location Code:</label>
            <Select
        options={destinationOptions}
        value={destinationLocationCode}
        onChange={(selectedOption) => setDestinationLocationCode(selectedOption)}
        onInputChange={(inputValueTo) => setSearchTermTo(inputValueTo)}
        placeholder="Enter airport"
      />
          </div>
          <div className="flightcol">
            <label className="form-label">Departure Date:</label>
            <input className="form-control" type="date" value={departureDate} onChange={(e) => setDepartureDate(e.target.value)} />
          </div>
          <div className="flightcol">
  <label className="form-label">Traveler Type:</label>
  <Select
    options={[
      { value: 'ADULT', label: 'Adult' },
      
    ]}
    value={{ value: travelerType, label: travelerType }} // Set the value and label
    onChange={(selectedOption) => setTravelerType(selectedOption.value)} // Set the selected value
    placeholder="Select traveler type"
  />
</div>
          <div className="flightcol">
            <button className="btn btn-primary" onClick={handleSubmit}>Modify Search</button>
          </div>
        </div>
      </div>
      
      <h2>Flight Offers:</h2>
      {/* response section start  */}


      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {offers.map((flight) => (
          <div key={flight.id} style={{ border: '1px solid navy', borderRadius: '5px', padding: '10px', margin: '10px', width: '700px', display: 'flex', flexWrap: 'wrap', backgroundColor: 'white' }}>
            <div className="flexcol" style={{ flex: '1',}}>
            <p style={{fontSize:'1.2rem',fontWeight:'700',textAlign:'start'}}>{airlineNames[flight.itineraries[0].segments[0].carrierCode]}</p>
            <p style={{textAlign:'start'}}>{flight.itineraries[0].segments[0].carrierCode}-{flight.itineraries[0].segments[0].number}</p>


            {flight.itineraries[0].segments[0].carrierCode && (
              <span style={{ width: '70px', height: '50px',display:'flex',justifyContent:'center',alignItems:'center',padding:'5px' }}>
                <img src={getAirlineLogo(flight.itineraries[0].segments[0].carrierCode)} alt={`${flight.itineraries[0].segments[0].carrierCode} Logo`} style={{ width: '100%', height: '100%' }} />
              </span>
            )}

            </div>
       
            <div className="flexcol" style={{ flex: '1',  }}>
            <p style={{fontSize:'0.8rem'}}>From</p>
              <p style={{fontWeight:'600',fontSize:'1.8rem'}}>{flight.itineraries[0].segments[0].departure.iataCode}</p>
              <p style={{  fontSize: '0.8rem' }}>
                {formatDate(flight.itineraries[0].segments[0].departure.at)}
              </p>
              <p style={{  fontSize: '0.8rem' }}>{formatTime(flight.itineraries[0].segments[0].departure.at)}</p>

            </div>
            <div className="flexcol" style={{ flex: '1', margin: '5px' }}>
            <p style={{padding:'5px'}}>Duration</p>
         
    <p><hr className='hrline'/>
    </p>   <p>{formatDuration(flight.itineraries[0].duration)}</p>
            </div>
            <div className="flexcol" style={{ flex: '1',}}>
            <p style={{fontSize:'0.8rem'}}>To</p>
              <p style={{fontWeight:'600',fontSize:'1.8rem'}}>{flight.itineraries[0].segments[0].arrival.iataCode}</p>
              <p style={{  fontSize: '0.8rem' }}>
                {formatDate(flight.itineraries[0].segments[0].arrival.at)}
              </p>
              <p style={{  fontSize: '0.8rem' }}>{formatTime(flight.itineraries[0].segments[0].arrival.at)}</p>


            </div>
            <div className="flexcol" style={{ flex: '1', margin: '5px' }}>
              <p><button className="btn btn-primary" onClick={() => handleBookNow(flight)}>Book Now!</button></p>
            <p><i class="fa-solid fa-indian-rupee-sign"></i> &nbsp;{flight.price.grandTotal}</p>
            </div>
           
          </div>
        ))}
      </div>
      {showLoginDialog && (
        <div className="dialog-container">
          <div className="dialog">
          <p>Please log in / SignUp to continue The.</p>
          <button onClick={handleLogin}>Login</button>
        </div>
        </div>
      )}

      {userData && showDialog && (
  <div className="dialog-container">
<div className="dialog">
<h3>User Data</h3>
<table  >
<tr>
    <th>User Name:</th>
    <th>User Email:</th>
    <th>User Mobile:</th>
   
    
    </tr>
    <td>{userData.name}</td>
    <td>{userData.email}</td>
    <td>{userData.mobile}</td>
</table>
            <br />

  <h3>Selected Flight Data</h3>
    <table  >
    <tr>
    <th>Airline Name:</th>
    <td>{airlineNames[selectedFlight.itineraries[0].segments[0].carrierCode]}</td>
    <th>Flight Number:</th>
    <td>{selectedFlight.itineraries[0].segments[0].carrierCode}-{selectedFlight.itineraries[0].segments[0].number}</td>
  </tr>
  <tr>
    <th>Departure Location Code:</th>
    <td>{selectedFlight.itineraries[0].segments[0].departure.iataCode}</td>
    <th>Departure Date & Time:</th>
    <td>{formatDate(selectedFlight.itineraries[0].segments[0].departure.at)}-{formatTime(selectedFlight.itineraries[0].segments[0].departure.at)}</td>
  </tr>
  <tr>
    <th>Destination Location Code:</th>
    <td>{selectedFlight.itineraries[0].segments[0].arrival.iataCode}</td>
    <th>Arrival Date & Time:</th>
    <td>{formatDate(selectedFlight.itineraries[0].segments[0].arrival.at)}-{formatTime(selectedFlight.itineraries[0].segments[0].arrival.at)}</td>
  </tr>
 
  <tr>
    <th>Total Duration:</th>
    <td>{formatDuration(selectedFlight.itineraries[0].duration)}</td>
    <th>Traveler Type:</th>
    <td>{formatDuration(selectedFlight.itineraries[0].duration)}</td>
  </tr>

  <tr>
    <th>Total Traveler:</th>
    <td>01</td>
  </tr>
  <tr>
    <th>Traveler Class:</th>
    <td>ECONOMY</td>
  </tr>
</table>
<button onClick={handleSubmitBooking}>Submit</button>
      <button onClick={() => setShowDialog(false)}>Close</button>
  </div>
  </div>
)}


    </div>
  );
}

export default ResultPage;


