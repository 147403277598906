import React from 'react'

export default function ManagePayments() {
  return (
    <div className="manage-payments-container">
       <div className="dummy-section"></div>

<div className="account-setting-content">
<div className="header-text"> <a style={{color:'white'}} href="/customer-service">Customer-Service </a> &nbsp;&gt;&gt; &nbsp;Account-FAQ</div>
  <div className="account-setting-content-faq">
    <div style={{padding:'5px',textAlign:'start'}} className="faq-header">
      <h2 >Manage Payment Methods</h2>
    </div>
<details style={{marginTop:'5px'}}>
          <summary>Payment methods available on TripInBudget </summary>
          <div className='faqopen'>
          <blockquote className='faqopen-blockquote'>
            <p>You can pay for your booking using the following methods:</p>
            <ul>
              <li>a. Credit/Debit Card</li>
              <li>b. Net banking</li>
              <li>c. UPI (GPay, Paytm, Amazon Wallet, etc.)</li>
              <li>d. Wallets (CRED Pay, OLA Money, Mobikwik, Amazon pay)</li>
            </ul>
          </blockquote>
          </div>
          
        </details>

        <details style={{marginTop:'5px'}}>
          <summary>Saving card details on TripInBudget </summary>
          <div className='faqopen'>
          <blockquote className='faqopen-blockquote'>
            <p>You can save your card details on TripInBudget to avoid filling in these details for every booking. While making a payment, you can also add a new card to your account.</p>
          </blockquote>
          </div>
          
        </details>
        <details style={{marginTop:'5px'}}>
          <summary>Deleting saved cards on TripInBudget </summary>
          <div className='faqopen'>
          <blockquote className='faqopen-blockquote'>
            <p>You can delete your card details using the TripInBudget app. You can go to the Profile section in the app and delete the details from the Manage Payment Methods.</p>
          </blockquote>
          </div>
          
        </details>

        
       
        </div>
</div>
    </div>
  )
}
