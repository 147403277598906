import React from 'react';

function FlightAvailableRes({ location }) {
  const response = location?.state?.response || null;

  return (
    <div style={{padding:'110px'}}>
      <h2>Flight Availabilities:</h2>
      {response ? (
        <table className="table table-bordered" id="customers">
          <thead>
            <tr>
              <th>Flight Number</th>
              <th>carrierCode</th>
              <th>aircraft code</th>
              <th>Departure Location</th>
              <th>Departure Date time</th>
              <th>Departure terminal</th>
              <th>Arrival Location</th>
              <th>Arrival Date time</th>
              <th>Arrival Terminal</th>
              <th>Total Duration</th>
              <th>No of Stops</th>
            </tr>
          </thead>
          <tbody>
            {response.data.map((flight) => (
              <tr key={flight.id}>
                <td>{flight.segments[0].number}</td>
                <td>{flight.segments[0].carrierCode}</td>
                <td>{flight.segments[0].aircraft.code}</td>
                <td>{flight.segments[0].departure.iataCode}</td>
                <td>{flight.segments[0].departure.at}</td>
                <td>{flight.segments[0].departure.terminal}</td>
                <td>{flight.segments[0].arrival.iataCode}</td>
                <td>{flight.segments[0].arrival.at}</td>
                <td>{flight.segments[0].arrival.terminal}</td>
                <td>{flight.duration}</td>
                <td>{flight.segments[0].numberOfStops}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No flight availabilities found.</p>
      )}
    </div>
  );
}

export default FlightAvailableRes;
