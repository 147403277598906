import React from 'react'

export default function IssueWithBanking() {
  return (
    <div className="issue-with-banking-container">
        
    <div className="dummy-section"></div>

<div className="account-setting-content">
<div className="header-text"> <a style={{color:'white'}} href="/customer-service">Customer-Service </a> &nbsp;&gt;&gt; &nbsp;Account-FAQ</div>
  <div className="account-setting-content-faq">
    <div style={{padding:'5px',textAlign:'start'}} className="faq-header">
      <h2 >Account Setting</h2>
    </div>
<details style={{marginTop:'5px'}}>
          <summary>Why is tripinbudget different from other travel booking platforms? </summary>
          <div className='faqopen'>
          <blockquote className='faqopen-blockquote'>
            <p>TripinBudget is an intelligent, AI-based travel platform designed to help you organize, book, and track your trips seamlessly. With unique features such as Online/Offline Train Running Status, PNR Prediction, Refund Calculator, TDR Tracking, Station Alarm, and more, TripinBudget ensures a smooth booking experience. Catering to diverse traveler needs with efficiency and convenience.</p>
          </blockquote>
          </div>
          
        </details>

        <details style={{marginTop:'5px'}}>
          <summary>Why is tripinbudget different from other travel booking platforms? </summary>
          <div className='faqopen'>
          <blockquote className='faqopen-blockquote'>
            <p>TripinBudget is an intelligent, AI-based travel platform designed to help you organize, book, and track your trips seamlessly. With unique features such as Online/Offline Train Running Status, PNR Prediction, Refund Calculator, TDR Tracking, Station Alarm, and more, TripinBudget ensures a smooth booking experience. Catering to diverse traveler needs with efficiency and convenience.</p>
          </blockquote>
          </div>
          
        </details>
        <details style={{marginTop:'5px'}}>
          <summary>Why is tripinbudget different from other travel booking platforms? </summary>
          <div className='faqopen'>
          <blockquote className='faqopen-blockquote'>
            <p>TripinBudget is an intelligent, AI-based travel platform designed to help you organize, book, and track your trips seamlessly. With unique features such as Online/Offline Train Running Status, PNR Prediction, Refund Calculator, TDR Tracking, Station Alarm, and more, TripinBudget ensures a smooth booking experience. Catering to diverse traveler needs with efficiency and convenience.</p>
          </blockquote>
          </div>
          
        </details>

        <details style={{marginTop:'5px'}}>
          <summary>Why is tripinbudget different from other travel booking platforms? </summary>
          <div className='faqopen'>
          <blockquote className='faqopen-blockquote'>
            <p>TripinBudget is an intelligent, AI-based travel platform designed to help you organize, book, and track your trips seamlessly. With unique features such as Online/Offline Train Running Status, PNR Prediction, Refund Calculator, TDR Tracking, Station Alarm, and more, TripinBudget ensures a smooth booking experience. Catering to diverse traveler needs with efficiency and convenience.</p>
          </blockquote>
          </div>
          
        </details>
       
        </div>
</div>
    </div>
  )
}
