import React,{useState,useEffect,useRef} from 'react'
import Select from 'react-select';
import axios from 'axios';
import '../Components/flightlist.css'

export default function FlightList() {
  
  const [searchTerm, setSearchTerm] = useState('');
  const [originOptions, setOriginOptions] = useState([]);
  const [originLocationCode, setoriginLocationCode] = useState(null);
  const [error, setError] = useState(null);
  const [searchTermTo, setSearchTermTo] = useState('');
  const [destinationOptions, setDestinationOptions] = useState([]);
  const [destinationLocationCode, setDestinationLocationCode] = useState(null);
  const [errorTo, setErrorTo] = useState(null);
  const [selectedOption, setSelectedOption] = useState('oneWay');

  const [isFormVisible, setFormVisible] = useState(false);
  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(0);
  const [infants, setInfants] = useState(0);
  const [selectedClass, setSelectedClass] = useState('');
  const [totalTravelers, setTotalTravelers] = useState(1);
  const handleIncrement = (type) => {
    switch (type) {
      case 'adults':
        setAdults(adults + 1);
        break;
      case 'children':
        setChildren(children + 1);
        break;
      case 'infants':
        setInfants(infants + 1);
        break;
      default:
        break;
    }
  };

  const handleDecrement = (type) => {
    switch (type) {
      case 'adults':
        setAdults(adults > 1 ? adults - 1 : 1);
        break;
      case 'children':
        setChildren(children > 0 ? children - 1 : 0);
        break;
      case 'infants':
        setInfants(infants > 0 ? infants - 1 : 0);
        break;
      default:
        break;
    }
  };

  const handleClassChange = (classType) => {
    setSelectedClass(classType);
  };

  const handleDone = () => {
    const total = adults + children + infants;
    setTotalTravelers(total);
    setFormVisible(!isFormVisible);
    // Add any other logic you want to perform on 'Done' click
  };


  const toggleFormVisibility = () => {
    setFormVisible(!isFormVisible);
  };

  

  useEffect(() => {
    const fetchData = async () => {
      try {
        // First API for searching by name
        const responseName = await axios.get('https://airports15.p.rapidapi.com/airports', {
          params: { name: searchTerm },
          headers: {
            'X-RapidAPI-Key': 'baa5306601msh44eade617a3c3d1p188b0djsn9f7772669cc0',
            'X-RapidAPI-Host': 'airports15.p.rapidapi.com',
          },
        });
        // Second API for searching by IATA code
        const responseIata = await axios.get('https://airport-info.p.rapidapi.com/airport', {
          params: { iata: searchTerm },
          headers: {
            'X-RapidAPI-Key': 'baa5306601msh44eade617a3c3d1p188b0djsn9f7772669cc0',
            'X-RapidAPI-Host': 'airport-info.p.rapidapi.com',
          },
        });
        // Combine the results from both APIs
        const options = [
          ...responseName.data.map((airport) => ({
            value: airport.iataCode,
            label: `${airport.iataCode} - ${airport.city}`,
          })),
          {
            value: responseIata.data.iata,
            label: `${responseIata.data.iata} - ${responseIata.data.city}`,
          },
        ];
        setOriginOptions(options);
        setError(null);
      } catch (error) {
        setOriginOptions([]);
        setError(error.message);
      }
    };
    if (searchTerm) {
      fetchData();
    }
  }, [searchTerm]);

  const handleAirportSearchDestination = async () => {
    try {
      const response = await axios.get('https://airports15.p.rapidapi.com/airports', {
        params: { name: searchTermTo },
        headers: {
          'X-RapidAPI-Key': 'baa5306601msh44eade617a3c3d1p188b0djsn9f7772669cc0',
          'X-RapidAPI-Host': 'airports15.p.rapidapi.com',
        },
      });
      const options = response.data.map((airport) => ({
        value: airport.iataCode,
        label: `${airport.iataCode} - ${airport.city}`,
      }));
      setDestinationOptions(options);
      setErrorTo(null);
    } catch (error) {
      setDestinationOptions([]);
      setErrorTo(error.message);
    }
  };

  useEffect(() => {
    if (searchTermTo) {
      handleAirportSearchDestination();
    }
  }, [searchTermTo]);
  
  const handleButtonClick = (option) => {
    setSelectedOption(option === selectedOption ? null : option);
    
  };
 
 
  return (
    <div className="flightlistcontainer">
      <div className="dummy" ></div>
          <div class="flightsearchcontainernew">
         <div class="flightsearchcontainerrow1new">
         <button className='button-namee'
        onClick={() => handleButtonClick('oneWay')}
        style={{ backgroundColor: selectedOption === 'oneWay' ? 'white' : '', color:selectedOption==='oneWay'? 'black':'white' }}
      >
        One Way
      </button>
      <button className='button-namee'
        onClick={() => handleButtonClick('return')}
        style={{ backgroundColor: selectedOption === 'return' ? 'white' : '', color:selectedOption==='return'? 'black':'white' }}
      >
        Round Trip
      </button>
     </div>
<div class="flightsearchcontainerrow2new">
<div className="flightrownew">
      <div className="flightcol">
      <label htmlFor="fromStation" className="form-label">From:</label>
      <div>
      <Select
        options={originOptions}
        value={originLocationCode}
        onChange={(selectedOption) => setoriginLocationCode(selectedOption)}
        onInputChange={(inputValue) => setSearchTerm(inputValue)}
        placeholder="Enter airport"
      />
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {originLocationCode ? (
        <div>
          {/* Additional details for the selected airport if needed */}
        </div>
      ) : (
        <p style={{fontSize:'0.8rem'}}>No matching airports found</p>
      )}
    </div>
    </div>
    <div className="flightcol">
        <label htmlFor="toStation" className="form-label">To:</label>
        <div>
          <Select
            options={destinationOptions}
            value={destinationLocationCode}
            onChange={(selectedOption) => setDestinationLocationCode(selectedOption)}
            onInputChange={(inputValue) => setSearchTermTo(inputValue)}
            placeholder="Enter airport"
          />
          {errorTo && <p style={{ color: 'red' }}>{errorTo}</p>}
          {destinationLocationCode ? (
            <div>
              {/* Additional details for selected airport if needed */}
            </div>
          ) : (
            <p style={{fontSize:'0.8rem'}}>No matching airports found</p>
          )}
        </div>
      </div>
        <div className="flightcol">          
          <label htmlFor="dateOfJourney" className="form-label">Departure Date</label>
          <input type="date" className="form-control" id="dateOfJourney"/>
        </div>
        {selectedOption === 'return' && (
        <div className="flightcol">          
          <label htmlFor="dateOfReturn" className="form-label">Return Date</label>
          <input type="date" className="form-control" id="dateOfReturn"/>
        </div>)}
        <div className="flightcol">
      <label className="form-label">Traveler & class</label>
      <p style={{ fontSize: '1.8rem', fontWeight: '700', cursor: 'pointer' }} onClick={toggleFormVisibility}>
        {totalTravelers}-Travellers
      </p>
      <p>{selectedClass} &nbsp;class</p>
      {isFormVisible && (
        <div className="dropdown-form2new"  style={{
          position: 'absolute',
          transform: 'translate(-50%, -50%)',
          width: '100%',
          padding: '10px',
          zIndex: 1000,
        }}>
<div className='dropdownformdesign'>
  <div className="dropdownformdesignsub1">Adults (12+ yrs) - </div>
  <div className="dropdownformdesignsub2"><button style={{padding:'2px'}} onClick={() => handleIncrement('adults')}>+</button>&nbsp;{adults} <button style={{padding:'2px'}} onClick={() => handleDecrement('adults')}>-</button></div>
  <div className="dropdownformdesignsub3">Children (2-12 yrs) -</div>
  <div className="dropdownformdesignsub4"><button style={{padding:'2px'}} onClick={() => handleIncrement('children')}>+</button> {children}<button style={{padding:'2px'}} onClick={() => handleDecrement('children')}>-</button></div>
  <div className="dropdownformdesignsub5">Infants (0-2 yrs) -</div>
  <div className="dropdownformdesignsub6"><button style={{padding:'2px'}} onClick={() => handleIncrement('infants')}>+</button> {infants}<button style={{padding:'2px'}} onClick={() => handleDecrement('infants')}>-</button></div>
      </div>
      <div className="radio-group">
        <p><input type="radio" name="class" value="Economy" onChange={() => handleClassChange('Economy')} /> Economy </p>
        <p><input type="radio" name="class" value="Prem Economy" onChange={() => handleClassChange('Prem Economy')} /> Prem Economy</p>
        <p> <input type="radio" name="class" value="Business" onChange={() => handleClassChange('Business')} /> Business</p>
        <p> <input type="radio" name="class" value="First" onChange={() => handleClassChange('First')} /> First </p>
      </div> 
      <button onClick={handleDone} className='dropdownbutton'>Done</button>
        </div>
      )}
    </div>

        <div className="flightcol">
          <button className="btn btn-primary" >Search Flights</button>
        </div>
      </div>
       </div>
<div class="flightsearchcontainerrow3"> </div>
</div>
<div className="f1body">
  <div className="f1bodybox"></div>
  <div className="f2bodybox">

    <div className="f2bodyboxcontainer">
    <div className="flightavailablebox1">
    <div class="flightavailablebox1col">
      <p>IATA CODE</p>
      <p style={{fontSize:'2rem'}}>12.40</p>
      <p>Date Time</p>
    </div>
   <div class="flightavailablebox1col" style={{paddingTop:'3%'}}>
    <p style={{padding:'5px'}}>Duration</p>
    <p><hr /></p>
   </div>
   <div class="flightavailablebox1col">
   <p>IATA CODE</p>
      <p style={{fontSize:'2rem'}}>3.40</p>
      <p>Date Time</p>
   </div>
   <div class="flightavailablebox1col">
   <p>BOOK NOW</p>
   <p style={{fontSize:'2rem'}}><i class="fa-solid fa-indian-rupee-sign"></i>-3000</p>
   
   </div>
    </div>
    <div className="flightavailablebox2">
      <div className="flightavailableboxrow">
        <p style={{fontWeight:'700'}}>Cabin Baggage</p>
        <p>  7 kg (1 piece per pax)  </p>
      </div>
      <div className="flightavailableboxrow">
        <p style={{fontWeight:'700'}}>
        Check-in Baggage</p>
        <p>20 kilograms (1 piece per pax)
        </p>
      </div>
    </div>


  </div>
  
  
  
  </div>
</div>

    </div>
  )
}
