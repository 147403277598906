import React, { Component } from "react";
import Slider from "react-slick";
import slider1 from '../Images/hoteloffer1.avif'
import slider2 from '../Images/offersbackground.png'
import slider3 from '../Images/hoteloffer4.jpg'
import slider4 from '../Images/hoteloffer1.avif'
import slider5 from '../Images/hoteloffer1.png'
import slider6 from '../Images/hoteloffer4.jpg'
import '../Components/Carousel.css';

export default class PauseOnHover extends Component {
  render() {
    var settings = {
      dots: true,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 1500,
      pauseOnHover: true,
      variableWidth: true, // Set variableWidth to true
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        }
      ]
    };
    return (
      <div className="carouselcontainer">
       
        <Slider {...settings}>
          <div className="carousel-image">
            <img src={slider1} className="caroimg"/>
          </div>
          <div className="carousel-image">
            <img src={slider2} className="caroimg"/>
          </div>
          <div className="carousel-image">
            <img src={slider3} className="caroimg"/>
          </div>
          <div className="carousel-image">
            <img src={slider4} className="caroimg"/>
          </div>
          <div className="carousel-image">
            <img src={slider5} className="caroimg"/>
          </div>
          <div className="carousel-image">
            <img src={slider6} className="caroimg" />
          </div>
          
         
        </Slider>
      </div>
    );
  }
}