import React, { useState } from 'react';

export default function PrebookingQueries() {
  const [selectedOption, setSelectedOption] = useState('flight');

  return (
    <div className="prebooking-container">
      <div className="dummy-section"></div>
      <div className="account-setting-content">
        <div className="header-text">
          <a style={{color:'white'}} href="/customer-service">Customer-Service</a> &nbsp;&gt;&gt; &nbsp;PrebookingQueries-FAQ
        </div>
        <div className="account-setting-content-faq">
          <div style={{padding:'5px',textAlign:'start'}} className="faq-header">
            <h2>Prebooking Queries</h2>
          </div>
          <div className="assured-options">
            <span className={selectedOption === 'flight' ? 'selected' : ''} onClick={() => setSelectedOption('flight')}><i className="fas fa-plane" style={{color:'black',fontSize:'1.5rem'}}></i>Flight</span>
            <span className={selectedOption === 'train' ? 'selected' : ''} onClick={() => setSelectedOption('train')}><i className="fas fa-train" style={{colo:'black',fontSize:'1.5rem'}}></i>Train</span>
            <span className={selectedOption === 'hotel' ? 'selected' : ''} onClick={() => setSelectedOption('hotel')}><i className="fas fa-hotel" style={{color:'black',fontSize:'1.5rem'}}></i>Hotel</span>
            <span className={selectedOption === 'bus' ? 'selected' : ''} onClick={() => setSelectedOption('bus')}><i className="fas fa-bus" style={{color:'black',fontSize:'1.5rem'}}></i>Bus</span>
            <hr className='assured-hr'/>
          </div>
          {selectedOption === 'flight' && (
            <div>
              <details style={{marginTop:'5px'}}>
                <summary>Maximum number of seats that can be booked under a single flight booking</summary>
                <div className='faqopen'>
                  <blockquote className='faqopen-blockquote'>
                    <p>A maximum of 9 seats can be booked at one time.</p>

                  </blockquote>
                </div>
              </details>

              <details style={{marginTop:'5px'}}>
                <summary>Updating Passenger details in case traveller only has a first name</summary>
                <div className='faqopen'>
                  <blockquote className='faqopen-blockquote'>
                    <p>For domestic airlines, please enter your first name in both first and last name fields and proceed with the booking.</p>
                    <br></br>
                    <p>For international travel, please check with your respective airline before booking the ticket.</p>
                  </blockquote>
                </div>
              </details>

              <details style={{marginTop:'5px'}}>
                <summary>Online booking for a child traveling alone</summary>
                <div className='faqopen'>
                  <blockquote className='faqopen-blockquote'>
                    <p>No, you cannot make a booking for a child traveling alone. There has to be at least 1 adult in the booking.</p>
                  </blockquote>
                </div>
              </details>

              <details style={{marginTop:'5px'}}>
                <summary>Timeline for Booking confirmation</summary>
                <div className='faqopen'>
                  <blockquote className='faqopen-blockquote'>
                    <p>You will receive a confirmation within a few minutes on the email ID you provided at the time of booking. If you have not received a confirmation, please go to the My Trips section on the ixigo app or website and use the available customer service options.]</p>
                  </blockquote>
                </div>
              </details>

              <details style={{marginTop:'5px'}}>
                <summary>Guidelines for International flight bookings</summary>
                <div className='faqopen'>
                  <blockquote className='faqopen-blockquote'>
                    <p>Your name must be exactly the same as that on your passport.

Please check visa requirements as per your travel itinerary. ixigo or the service provider is not liable for issues related to visa and incorrect passport info.</p>
                  </blockquote>
                </div>
              </details>

              <details style={{marginTop:'5px'}}>
                <summary>Booking tickets for another person through my account</summary>
                <div className='faqopen'>
                  <blockquote className='faqopen-blockquote'>
                    <p>Just enter the details of the traveler you want to book for when you're asked to enter traveler details at the time of booking.</p>
                  </blockquote>
                </div>
              </details>

              <details style={{marginTop:'5px'}}>
                <summary>Reason for dynamic fares</summary>
                <div className='faqopen'>
                  <blockquote className='faqopen-blockquote'>
                    <p>Flight fares are dynamic and may change depending on seat availability. tripinbudget has no control over the availability of seats or dynamic airline fares.


</p>
                  </blockquote>
                </div>
              </details>

              <details style={{marginTop:'5px'}}>
                <summary>Selection of seats for Flight bookings</summary>
                <div className='faqopen'>
                  <blockquote className='faqopen-blockquote'>
                    <p>You can select your own seat. In most cases, you can do so at the time of web check-in. Some airline carriers may charge an additional fee for seat selection. Please contact the airline you are booked with for details on seat selection for your trip.</p>
                  </blockquote>
                </div>
              </details>

              <details style={{marginTop:'5px'}}>
                <summary>Boarding pass for Flight ticket</summary>
                <div className='faqopen'>
                  <blockquote className='faqopen-blockquote'>
                    <p>You need to show your ticket confirmation email at the check-in counter at the airport or do a web check-in on the airline website. An airline representative will issue your boarding pass or you could take a print-out if you are doing a web check-in.</p>
                  </blockquote>
                </div>
              </details>


              <details style={{marginTop:'5px'}}>
                <summary>ID proof requirement at the time of check-in</summary>
                <div className='faqopen'>
                  <blockquote className='faqopen-blockquote'>
                    <p>It is mandatory to carry a Govt. photo ID proof such as an Aadhaar card/PAN card/Driving license/Passport, etc., for domestic travel.</p>
                    <p>Additionally, a passport is mandatory for international travel.</p>
                  </blockquote>
                </div>
              </details>

              <details style={{marginTop:'5px'}}>
                <summary>Provision for Meals on flights and their charges</summary>
                <div className='faqopen'>
                  <blockquote className='faqopen-blockquote'>
                    <p>Some airlines provide free meals whereas some airlines charge for them. Please contact the airline for this information or refer to the details on your E-ticket.


</p>
                  </blockquote>
                </div>
              </details>

              <details style={{marginTop:'5px'}}>
                <summary>Delay in first flight in case of Flight layovers</summary>
                <div className='faqopen'>
                  <blockquote className='faqopen-blockquote'>
                    <p>If you have booked both your flight tickets together with the same airline, it is the airline's responsibility to get you an onward connecting flight or process a refund in case of a delay. However, if you have booked both tickets with a separate airline and your first flight gets delayed, you will have to book a different onward flight for yourself.</p>
                  </blockquote>
                </div>
              </details>

              <details style={{marginTop:'5px'}}>
                <summary>Redemption process of Frequent Flyer Miles</summary>
                <div className='faqopen'>
                  <blockquote className='faqopen-blockquote'>
                    <p>Each airline has a different process for Frequent Flyer Miles redemption. Please visit the airline website or directly give them a call to find out more.


</p>
                  </blockquote>
                </div>
              </details>

              <details style={{marginTop:'5px'}}>
                <summary>Prohibited items in cabin baggage</summary>
                <div className='faqopen'>
                  <blockquote className='faqopen-blockquote'>
                    <p>Some items that cannot be carried in your hand baggage include:</p>
                    <br></br>
                    <p>Sharp Objects -- toy or dummy weapons (plastic or metal), scissors, box cutters, utility knives,sabres, sword, razor blades, catapults</p>
                    <p>Sport items -- baseball bats, bows and arrows, cricket bats, golf clubs, hockey sticks, lacrosse sticks, billiard, snooker or pool cues, ski poles, spear guns, sports rackets, darts</p>
                    <p>Others -- cutlery items, petrol lighters, guns and ammunition, syringes, knitting needles, tools.</p>
                  </blockquote>
                </div>
              </details>

              <details style={{marginTop:'5px'}}>
                <summary>Free Cancellation policy</summary>
                <div className='faqopen'>
                  <blockquote className='faqopen-blockquote'>
                    <p>Free Cancellation allows you to claim a refund of cancellation charges levied by airlines making it a zero-charge cancellation.</p>
                  </blockquote>
                </div>
              </details>
            </div>
          )}
          {selectedOption === 'train' && (
            <div>
              <details style={{marginTop:'5px'}}>
                <summary>Payment gateway charge on train bookings</summary>
                <div className='faqopen'>
                  <blockquote className='faqopen-blockquote'>
                    <p>Gateway fee is the cost charged when you access the networks that handle the financial data processing of online purchases.


</p>
                  </blockquote>
                </div>
              </details>

              <details style={{marginTop:'5px'}}>
                <summary>Maximum number of people under a single PNR for Train bookings</summary>
                <div className='faqopen'>
                  <blockquote className='faqopen-blockquote'>
                    <p>You can book tickets for a maximum of six(6) people under a single booking. However, for Tatkal booking you can only book a maximum of four(4) people under a single booking. This number includes children and senior citizens traveling together.</p>
                  </blockquote>
                </div>
              </details>

              <details style={{marginTop:'5px'}}>
                <summary>Features of Tatkal booking</summary>
                <div className='faqopen'>
                  <blockquote className='faqopen-blockquote'>
                    <p>Tatkal is a quota offered by the Indian Railways. Bookings under Tatkal are meant for last minute plans.Tatkal booking opens 24 hrs in advance, at 10 am for AC class and 11 AM for Non AC class. While booking through ixigo, bookings can only be made 15 minutes after the Tatkal booking window opens on IRCTC. You can also check tatkal availability for all trains.</p>
                  </blockquote>
                </div>
              </details>

              <details style={{marginTop:'5px'}}>
                <summary>Booking Schedule for Tatkal tickets via tripinbudget</summary>
                <div className='faqopen'>
                  <blockquote className='faqopen-blockquote'>
                    <p>Yes, you can book tatkal tickets via tripinbudget. However, the booking window opens 15 minutes after IRCTC, i.e. if bookings start on IRCTC at 10:00 AM then, you will be able to book via tripinbudget from 10:15 AM (for AC classes). For non-AC classes, you can book tickets from 11:15 AM.</p>
                  </blockquote>
                </div>
              </details>

              <details style={{marginTop:'5px'}}>
                <summary>Concessions available on Tatkal tickets</summary>
                <div className='faqopen'>
                  <blockquote className='faqopen-blockquote'>
                    <p>Tatkal scheme which allows passengers to book train tickets at a very short notice, doesn't allow any concession on the booked tic</p>
                  </blockquote>
                </div>
              </details>

              <details style={{marginTop:'5px'}}>
                <summary>Boarding the train in case of Waitlisted (WL) Ticket</summary>
                <div className='faqopen'>
                  <blockquote className='faqopen-blockquote'>
                    <p>You can only board the train when the ticket status is CNF (Confirmed) or RAC (reservation against cancellation). Note : In case of partial ticket confirmation (where multiple passengers are present and only few passengers end up with confirmed booking status post chart preparation), customers will be eligible to get only the confirmed seats during their train journey.</p>
                  </blockquote>
                </div>
              </details>

            
            </div>
          )}
          {selectedOption === 'hotel' && (
            <div>
              <details style={{marginTop:'5px'}}>
                <summary>Steps to book a hotel on tripinbudget</summary>
                <div className='faqopen'>
                  <blockquote className='faqopen-blockquote'>
                    <p>Please follow the below mentioned steps to book a hotel on tripinbudget:</p>
                    <br></br>
                    <p>1.Log in to the tripinbudget website</p>
                    <p>2.Enter the destination you want to search for</p>
                    <p>3.Select your check-in and check-out dates</p>
                    <p>4.Select the number of rooms and guests</p>
                    <p>5.Hit ‘Search’ and a list of available hotels will show up.</p>
                    <p>6.Choose the hotel, room of your choice</p>
                    <p>7.Review your booking details & enter the primary guest details</p>
                    <p>8.Select your preferred payment mode to complete your booking</p>

                  </blockquote>
                </div>
              </details>

              <details style={{marginTop:'5px'}}>
                <summary>Amount deducted, Booking confirmation not received</summary>
                <div className='faqopen'>
                  <blockquote className='faqopen-blockquote'>
                    <p>In this case, please get in touch with tripinbudget Customer Service team. If the booking still doesn’t get confirmed after the suggested timeline, the deducted amount will be refunded to the original mode of payment.</p>
                  </blockquote>
                </div>
              </details>

              <details style={{marginTop:'5px'}}>
                <summary>Hotel staff unable to trace confirmed booking</summary>
                <div className='faqopen'>
                  <blockquote className='faqopen-blockquote'>
                    <p>Don't worry! In this case, you can directly call tripinbudget Customer Service number to re-generate the booking confirmation email. If the issue is not resolved, our team will get in touch with the hotel partner to help you sort it out.</p>
                  </blockquote>
                </div>
              </details>

              <details style={{marginTop:'5px'}}>
                <summary>Timeline for refund in case of booking failure</summary>
                <div className='faqopen'>
                  <blockquote className='faqopen-blockquote'>
                    <p>We will initiate the refund immediately. The amount should reflect in your source account depending on the timelines of the same.</p>
                  </blockquote>
                </div>
              </details>

              <details style={{marginTop:'5px'}}>
                <summary>Need to call the hotel for booking confirmation</summary>
                <div className='faqopen'>
                  <blockquote className='faqopen-blockquote'>
                    <p>There is no need to call the hotel to confirm your booking. The booking voucher sent by tripinbudget indicates a confirmed reservation.</p>
                    <p>In case you still wish to confirm your booking, we recommend doing so 2 hours after the booking by contacting tripinbudget, as it may take up to 2 hours for a booking to reflect in the hotel's system.</p>
                  </blockquote>
                </div>
              </details>

              <details style={{marginTop:'5px'}}>
                <summary>Contact details of the Hotel</summary>
                <div className='faqopen'>
                  <blockquote className='faqopen-blockquote'>
                    <p>The hotel details (such as name and address) are mentioned in your booking confirmation voucher sent to your email ID and phone number provided at the time of booking. You can also view the booking voucher in the 'My Trips' section of the tripinbudget web.</p>
                  </blockquote>
                </div>
              </details>
            </div>
          )}
          {selectedOption === 'bus' && (
            <div>
              <details style={{marginTop:'5px'}}>
                <summary>Checking Bus route/operator information</summary>
                <div className='faqopen'>
                  <blockquote className='faqopen-blockquote'>
                    <p>To get information on Bus route/operator:</p>
                    <br></br>
                    <p>- Click on ‘Buses’ on the tripinbudget home page.</p>
                    <p>- Select origin, destination & date of journey.</p>
                    <p>- Click on ‘Search’ to see all available buses and operators.</p>

                  </blockquote>
                </div>
              </details>

              <details style={{marginTop:'5px'}}>
                <summary>Booking Confirmed but I haven't received the driver & bus details</summary>
                <div className='faqopen'>
                  <blockquote className='faqopen-blockquote'>
                    <p>You will receive the driver and bus details 1 hour prior to your departure time or as per the Terms and Conditions mentioned on your E-Ticket.</p>
                  </blockquote>
                </div>
              </details>

              <details style={{marginTop:'5px'}}>
                <summary>Rescheduling Bus bookings</summary>
                <div className='faqopen'>
                  <blockquote className='faqopen-blockquote'>
                    <p>Since all bookings made via tripinbudget are powered by our travel partners. Please contact the provider with the details mentioned on your E-ticket, as they will be able to assist you better with rescheduling.</p>
                  </blockquote>
                </div>
              </details>

              <details style={{marginTop:'5px'}}>
                <summary>Cancellation Policy for Bus Bookings</summary>
                <div className='faqopen'>
                  <blockquote className='faqopen-blockquote'>
                    <p>Please refer to your E-ticket for the cancellation policy.</p>
                  </blockquote>
                </div>
              </details>

              <details style={{marginTop:'5px'}}>
                <summary>Transferability of Bus tickets</summary>
                <div className='faqopen'>
                  <blockquote className='faqopen-blockquote'>
                    <p>Bus tickets are non-transferable. However, you may get in touch with the service provider.</p>
                  </blockquote>
                </div>
              </details>

              <details style={{marginTop:'5px'}}>
                <summary>Refunds in case missed to board the Bus</summary>
                <div className='faqopen'>
                  <blockquote className='faqopen-blockquote'>
                    <p>Please get in touch with the service provider using the contact details mentioned on E-ticket.</p>
                  </blockquote>
                </div>
              </details>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
