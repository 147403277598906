import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Flight from './Components/FLight';

import Buses from './Components/Bus/Buses';
import Hotels from './Components/Hotel/Hotels';
import Offers from './Components/Offers';
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';
import TrainSearch from './Components/TrainSearch';
import RailwayStationsList from './Components/TrainSearch';
import Train from './Components/Train';
import PnrStatus from './Components/TrainSearch';
import AuthForm from './Components/AuthForm';
import TrainFinder from './Components/TrainFinder';
import AirportInfo from './Components/AirportCode';
import TrainSearchsecond from './Components/TrainSearchBetween2Station';
import AirportNameSearch from './Components/AirportNameSearch';
import FlightList from './Components/FlightList';
import FlightOffers from './Components/FlightOffers';
import FlightAvailable from './Components/FlightAvailable';
import AirportCitySearch from './Components/AirportCitynameSearch';
import FlightAvailableRes from './Components/FlightAvailableRes';
import ResultPage from './Components/ResultPage';
import FlightConnectionPage from './Components/FlightConnectionPage';

import HotelAvailable from './Components/Hotel/HotelAvailable';
import HotelOffers from './Components/Hotel/HotelOffers';

import CustomerService from './Components/Customer-Service/CustomerService';
import AccountSetting from './Components/Customer-Service/AccountSetting';
import IssueWithBanking from './Components/Customer-Service/IssueWithBanking';
import ManagePayments from './Components/Customer-Service/ManagePayments';
import PrebookingQueries from './Components/Customer-Service/PrebookingQueries';
import TripInBudgetAssured from './Components/Customer-Service/TripInBudgetAssured';
import HotelResultPage from './Components/Hotel/HotelResultPage';
import BusAvailable from './Components/Bus/BusAvailable';
import TrainAvailable from './Components/Train/TrainAvailable';
import PrivacyPolicy from './Components/TermsConditon/PrivacyPolicy';
import Phonepe from './Components/phonepe/Phonepe';
import Success from './Components/phonepe/Success';
import Failure from './Components/phonepe/Failure';
import TripPayment from './Components/phonepe/TripPayment';
import TermsAndCondition from './Components/TermsConditon/TermsAndCondition';
import InternalServerError from './Components/internalError';


function App() {
  return (
    <Router>
    <Navbar/>
     <div className="App">
     <Routes>
      <Route path="/flights" element={<Flight />}/>
      <Route path="/" element={<Flight />}/>
      <Route path="/trains" element={<Train />}/>
      <Route path="/buses" element={<Buses />}/>
      
      <Route path="/flightconnection" element={<FlightConnectionPage />}/>
      <Route path="/flightlist" element={<FlightList />}/>
      <Route path="/flightavailable" element={<FlightAvailable />}/>
      <Route path="/flightavailable/result" element={<ResultPage />} />
      <Route path="/flightavailable/flightavailableres" element={<FlightAvailableRes />}/>
      <Route path="/flightoffers" element={<FlightOffers />}/>
      <Route path="/airportcityname" element={<AirportCitySearch />}/>
      <Route path="/trainsearch" element={<TrainSearchsecond />}/>
      <Route path="/airport" element={<AirportInfo />}/>
      <Route path="/airportname" element={<AirportNameSearch />}/>
     
      <Route path="/login" element={<AuthForm />}/>
      <Route path="/trainnn" element={<TrainFinder />}/>
      <Route path="/trains" element={<Train />}/>
      <Route path="/trains/available" element={<TrainAvailable />}/>
      <Route path="/buses" element={<Buses />}/>
      <Route path="/buses/available" element={<BusAvailable />}/>


      <Route path="/hotels" element={<Hotels />}/>
      <Route path="/hotels/available" element={<HotelAvailable />}/>
      <Route path="/hotels/offers" element={<HotelOffers />}/>
      <Route path="/offers" element={<Offers />}/>
      <Route path="/hotels/available/result" element={<HotelResultPage />}/>

      <Route path="/customer-service" element={<CustomerService />}/>
      <Route path="/customer-service/account-settings" element={<AccountSetting />}/>
      <Route path="/customer-service/issuewith-banking" element={<IssueWithBanking />}/>
      <Route path="/customer-service/manage-payments" element={<ManagePayments />}/>
      <Route path="/customer-service/prebooking-queries" element={<PrebookingQueries />}/>
      <Route path="/customer-service/assured" element={<TripInBudgetAssured />}/>

      <Route path="/privacy-policy" element={<PrivacyPolicy />}/>
      <Route path="/terms-of-service" element={<TermsAndCondition />}/>

      <Route path="/payment" element={<Phonepe />}/>
      <Route path="/search/trips/pay" element={<TripPayment />}/>
      <Route exact path='/success' element={<Success />} />
        <Route exact path='/failure' element={<Failure />} />
         {/* <Route path="/flightconnection" element={<InternalServerError />}/>
      <Route path="/flightlist" element={<InternalServerError />}/>
      <Route path="/flightavailable" element={<InternalServerError />}/>
      <Route path="/flightavailable/result" element={<InternalServerError />} />
      <Route path="/flightavailable/flightavailableres" element={<InternalServerError />}/>
      <Route path="/flightoffers" element={<InternalServerError />}/>
      <Route path="/airportcityname" element={<InternalServerError />}/>
      <Route path="/trainsearch" element={<InternalServerError />}/>
      <Route path="/airport" element={<InternalServerError />}/>
      <Route path="/airportname" element={<InternalServerError />}/>
     
      <Route path="/login" element={<InternalServerError />}/>
      <Route path="/trainnn" element={<InternalServerError />}/>
      <Route path="/trains" element={<Train />}/>
      <Route path="/trains/available" element={<InternalServerError />}/>
      <Route path="/buses" element={<Buses />}/>
      <Route path="/buses/available" element={<InternalServerError />}/>


      <Route path="/hotels" element={<Hotels />}/>
      <Route path="/hotels/available" element={<InternalServerError />}/>
      <Route path="/hotels/offers" element={<InternalServerError />}/>
      <Route path="/offers" element={<InternalServerError />}/>
      <Route path="/hotels/available/result" element={<InternalServerError />}/>

      <Route path="/customer-service" element={<InternalServerError />}/>
      <Route path="/customer-service/account-settings" element={<InternalServerError />}/>
      <Route path="/customer-service/issuewith-banking" element={<InternalServerError />}/>
      <Route path="/customer-service/manage-payments" element={<InternalServerError />}/>
      <Route path="/customer-service/prebooking-queries" element={<InternalServerError />}/>
      <Route path="/customer-service/assured" element={<InternalServerError />}/>

      <Route path="/privacy-policy" element={<InternalServerError />}/>
      <Route path="/terms-of-service" element={<InternalServerError />}/>

      <Route path="/payment" element={<InternalServerError />}/>
      <Route path="/search/trips/pay" element={<InternalServerError />}/>
      <Route exact path='/success' element={<InternalServerError />} />
        <Route exact path='/failure' element={<InternalServerError />} /> */}

     </Routes>
     </div>
<Footer/>
    </Router>
  
  );
}

export default App;
