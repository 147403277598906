
import { ToastContainer, toast } from 'react-toastify';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../Components/train.css';
import '../Components/flight.css'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from "react-slick";
import image1 from '../Images/train.jpg'
import image2 from '../Images/train2.png'
import image3 from '../Images/trainoffer4.jpeg'
import image4 from '../Images/trainoffer1.jpeg'
import image5 from '../Images/trainoffer2.jpg'
import image6 from '../Images/trainoffer3.jpg'
import backgroundslider1 from '../Images/flightbackground5.png'
import backgroundslider2 from '../Images/flightbackground2.png'
import backgroundslider3 from '../Images/flightbackground1.png'
import backgroundslider4 from '../Images/flightbacground3.png'
import backgroundslider5 from '../Images/flightbackground4.png'
import trainboximg1 from '../Images/irctc.png'
import trainboximg2 from '../Images/LiveStation.svg'
import trainboximg3 from '../Images/LiveStatus.svg'
import trainboximg4 from '../Images/FoodonTrackLogo.png'
import trainboximg5 from '../Images/instrcb.svg'
import trainboximg6 from '../Images/CustomerService.svg'
import BASE_URL from '../Components/apiConfig';
export default function Train() {
  const isMobile = window.innerWidth <= 760;

  const settingsDesktop = {
    focusOnSelect: true,
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "100px",
    slidesToShow: 3,
    speed: 800,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2400,
    pauseOnHover: true
  };

  const settingsMobile = {
    focusOnSelect: true,
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 1,
    speed: 600,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2400,
    pauseOnHover: true
  };

  const settings = isMobile ? settingsMobile : settingsDesktop;
  const [originStations, setOriginStations] = useState([]);
  const [destinationStations, setDestinationStations] = useState([]);
  const [error, setError] = useState(null);
  const [originCitycode, setOriginCitycode] = useState('');
  const [destinationCityCode, setDestinationCityCode] = useState('');
  const [travelDate, setTravelDate] = useState('');
  const [traveller, setTraveller] = useState(1);
  const [seatType, setSeatType] = useState('');
  const [showDialog, setShowDialog] = useState(false); 
  const [showLoginDialog, setShowLoginDialog] = useState(false); 
  const [selectedData, setSelectedData] = useState(null); 
  const navigate=useNavigate();
  const handleLoginClick=()=>{
    navigate('/login');
  }
  const handleOriginInputChange = async (e) => {
    const originCitycode = e.target.value;
    setOriginCitycode(originCitycode);
    if (!originCitycode) {
      setOriginStations([]);
      return;
    }
    try {
      // Make requests to both APIs
      const [response1, response2] = await Promise.all([
        axios.post(`${BASE_URL}/stations`, { search: originCitycode }),
        axios.post(`${BASE_URL}/fetch-stations`, { search: originCitycode })
      ]);
      
      // Extract data from responses
      const station1 = { code: response1.data.station_code, name: response1.data.station_name };
      const station2 = { code: response2.data.station_code, name: response2.data.station_name };
  
      // Update origin stations with data from both APIs
      setOriginStations([station1, station2]);
      setError(null);
    } catch (error) {
      setError(error);
      setOriginStations([]);
    }
  };
  
  const handleDestinationInputChange = async (e) => {
    const destinationCityCode = e.target.value;
    setDestinationCityCode(destinationCityCode);
    if (!destinationCityCode) {
      setDestinationStations([]);
      return;
    }
    try {
      const [response1, response2] = await Promise.all([
        axios.post(`${BASE_URL}/stations`, { search: destinationCityCode }),
        axios.post(`${BASE_URL}/fetch-stations`, { search: destinationCityCode })
      ]);
      
      // Extract data from responses
      const station1 = { code: response1.data.station_code, name: response1.data.station_name };
      const station2 = { code: response2.data.station_code, name: response2.data.station_name };
  
      // Update origin stations with data from both APIs
      setDestinationStations([station1, station2]);
      setError(null);
    } catch (error) {
      setError(error);
      setDestinationStations([]);
    }
  };

  const seatTypeOptions = [
    { value: 'First Class', label: 'First Class' },
    { value: 'Second Class', label: 'Second Class' },
    { value: 'Third Class', label: 'Third Class' },
    { value: 'Sleeper Class', label: 'Sleeper Class' },
    { value: 'Executive Class', label: 'Executive Class' },
    { value: 'Business Class', label: 'Business Class' },
    { value: 'Economy Class', label: 'Economy Class' },
    { value: 'Premium Economy Class', label: 'Premium Economy Class' }
  ];

  const handleSearchClick = () => {
    if (!originCitycode || !destinationCityCode || !travelDate || !traveller || !seatType) {
      // setErrorMessage('All fields are mandatory');
      toast.error('All fields are mandatory');
      return;
    }
    const isLoggedIn=localStorage.getItem('isLoggedIn');
    if(isLoggedIn){
    const selectedData = {
      originCitycode,
      destinationCityCode,
      travelDate,
      traveller,
      seatType: seatType.value 
    };
    axios.post(`${BASE_URL}/api/userdata`, { email: localStorage.getItem('email') })
    .then(response => {
      const userData = response.data.userData;
      // store response name,email,mobile in variable userdata array
      setSelectedData({ ...selectedData, userData });
      setShowDialog(true);
    })
    .catch(error => {
      console.error('Error fetching user data:', error);
    });
  }else{
    setShowLoginDialog(true);
  }
  };
  const handleDialogClose = () => {
    setShowDialog(false);
  };



  
  const handleTrainDataSend = () => {
    axios.post(`${BASE_URL}/send-train-data`, {
      selectedData: selectedData,
      userData: selectedData.userData
    })
    .then(response => {
      console.log('Email sent successfully');
      toast.success('One of our members will contact you shortly', {
        onClose: handleDialogClose // Close the dialog box after the toast is closed
      });
      setTimeout(() => {
        setShowDialog(false);
      }, 2000);
      // Add any further logic here if needed
    })
    .catch(error => {
      console.error('Error sending email:', error);
    });
  };
  
  return (
    <div className="traincontainer">
      <div className="traincontainerfirstrow">
      </div>
     <div className="train-second-row">
     <div className='availablecontainer'>
        <div className="availablecontainerrow">
        <div className="train-flightcol">
          <label className="form-label"> From City </label>
          <input
            className='form-control'
            list="originCityOptions"
            placeholder="Select City or Type"
            value={originCitycode}
            onChange={handleOriginInputChange}
          />
          <datalist id="originCityOptions" className='form-control'>
            {Array.isArray(originStations) && originStations.map(({ code, name }) => (
              <option key={code} value={`${code}-${name}`} />
            ))}
          </datalist>
        </div>

        <div className="train-flightcol">
          <label className="form-label"> To City </label>
          <input
            className='form-control'
            list="destinationCityOptions"
            placeholder="Select City or Type"
            value={destinationCityCode}
            onChange={handleDestinationInputChange}
          />
          <datalist id="destinationCityOptions" className='form-control'>
            {Array.isArray(destinationStations) && destinationStations.map(({ code, name }) => (
              <option key={code} value={`${code}-${name}`} />
            ))}
          </datalist>
        </div>

        <div className="train-flightcol">
          <label className="form-label">Travel Date</label>
          <input
            type="date"
            className="form-control"
            value={travelDate}
            onChange={(e) => setTravelDate(e.target.value)}
          />
        </div>
        <div className="train-flightcol">
          <label className="form-label">No of Traveller</label>
          <input
            type="number"
            className="form-control"
            value={traveller}
            onChange={(e) => setTraveller(e.target.value)}
          />
        </div>
        <div className="train-flightcol">
          <label className="form-label">Class</label>
          <Select
            options={seatTypeOptions}
            value={seatType}
            onChange={(selectedOption) => setSeatType(selectedOption)}
            placeholder="Seat Select"
          />
        </div>

        <div className="flightcol" style={{marginTop:'10px'}}>
          <button className="btn btn-primary" onClick={handleSearchClick}>Search</button>
        </div>
      </div>
      </div>
      {showLoginDialog && (
        <div className="dialog-container">
          <div className="dialog">
           <p>please login/Register to complete the booking</p>
            <div className="dialog-buttons">
              <button className="btn btn-primary" onClick={handleLoginClick}>Login</button>
              <button className="btn btn-secondary" onClick={handleDialogClose}>Close</button>
            </div>
          </div>
        </div>
      )}
      {showDialog && (
        <div className="dialog-container">
           <div className="dialog">
           <table>
              <tbody>
              <tr>
              <th className='table-td'>Category</th>
              <th className='table-td'>Details</th>
            </tr>
            {selectedData.userData && (
            <>
            
              <tr>
                <td className='table-td'>Name</td>
                <td>{selectedData.userData.name}</td>
              </tr>
              <tr>
                <td className='table-td'>Email</td>
                <td>{selectedData.userData.email}</td>
              </tr>
              <tr>
                <td className='table-td'>Mobile</td>
                <td>{selectedData.userData.mobile}</td>
              </tr>
            </>
          )}
                <tr>
                  <td className='table-td'>Origin City</td>
                  <td>{selectedData.originCitycode}</td>
                </tr>
                <tr>
                  <td className='table-td'>Destination City</td>
                  <td>{selectedData.destinationCityCode}</td>
                </tr>
                <tr>
                  <td className='table-td'>Travel Date</td>
                  <td>{selectedData.travelDate}</td>
                </tr>
                <tr>
                  <td className='table-td'>No Of Traveller</td>
                  <td>{selectedData.traveller}</td>
                </tr>
                <tr>
                  <td className='table-td'>Seat Type</td>
                  <td>{selectedData.seatType}</td>
                </tr>
              </tbody>
            </table>
            <div className="dialog-buttons">
              <button onClick={handleDialogClose} className="btn btn-secondary">Close</button>
              <button  className="btn btn-secondary" onClick={handleTrainDataSend}>Submit</button>
            </div>
        </div>
        </div>
      )}
     </div>
      <div className="FourthContainer">
      <div className="FourthContainerRow1">
       <h2>Why Book Train Tickets from TripInBudget</h2>
      </div>
      <div className="FourthContainerRow2">
     <div className="FourthContainerRow2box">
          <div className="subbox1"><img src={trainboximg1} alt="" className='trainboximg'/></div>
          <div className="subbox2">
              <h1 className='subbox2text1'> IRCTC Authorized Partner</h1>
              <p className='subbox2text2'>TripInBudget is authorized partner of IRCTC, booking train tickets since 2018</p>
          </div>
          <div className="subbox3"></div>
     </div>
     <div className="FourthContainerRow2box">
     <div className="subbox1"><img src={trainboximg2} alt="" className='trainboximg'/></div>
          <div className="subbox2">
              <h1 className='subbox2text1'> Live Station Status</h1>
              <p className='subbox2text2'>Get a complete list of trains that shall be arriving at the railway station of your choice at the time selected by you.</p>
          </div>
          <div className="subbox3"></div>
     </div>
     <div className="FourthContainerRow2box">
     <div className="subbox1"><img src={trainboximg3} alt="" className='trainboximg'/></div>
          <div className="subbox2">
              <h1 className='subbox2text1'> Live Train Status</h1>
              <p className='subbox2text2'>Get to know the Live Status of railway trains and delays, if any.</p>
          </div>
          <div className="subbox3"></div>
     </div>
     <div className="FourthContainerRow2box">
     <div className="subbox1"><img src={trainboximg4} alt="" className='trainboximg'/></div>
          <div className="subbox2">
              <h1 className='subbox2text1'> IRCTC Train Food Booking</h1>
              <p className='subbox2text2'>Enjoy booking IRCTC Food &
Get Food Delivered on the Train</p>
          </div>
          <div className="subbox3"></div>
     </div>
     <div className="FourthContainerRow2box">
     <div className="subbox1"><img src={trainboximg5} alt="" className='trainboximg'/></div>
          <div className="subbox2">
              <h1 className='subbox2text1'>Instant Refund And Concellation</h1>
              <p className='subbox2text2'>Get an instant refund and book your next train ticket without any hassle.</p>
          </div>
          <div className="subbox3"></div>
     </div>
     <div className="FourthContainerRow2box">
     <div className="subbox1"><img src={trainboximg6} alt="" className='trainboximg'/></div>
          <div className="subbox2">
              <h1 className='subbox2text1'> 24 * 7 Customer Service</h1>
              <p className='subbox2text2'>We work 24 hours a day to make sure our availability whenever our customers need us.</p>
          </div>
          <div className="subbox3"></div>
     </div>
      </div>
    </div>
      <div className="traincontainerthirdrow">
{/* train offer carousel third row */}
<div className="traincontainerthirdrowcol1">
<div className='carousel-heading-text'>Todays Exclusive Offers</div>
        <Slider {...settings}>
          <div className="carousel2">
            <img src={image1} alt="" className="flightimage"/>
          </div>
          <div className="carousel2">
            <img src={image2} alt="" className="flightimage" />
          </div>
          <div className="carousel2">
            <img src={image3} alt="" className="flightimage" />
          </div>
          <div className="carousel2">
            <img src={image4} alt="" className="flightimage" />
          </div>
          <div className="carousel2">
            <img src={image5} alt="" className="flightimage" />
          </div>
          <div className="carousel2">
            <img src={image6} alt="" className="flightimage" />
          </div>
        </Slider>
  </div>
      </div>
     
      {/* popular train route sextion start */}
<div className="populertrainroutecontainer">
{/* popular train route sextion 2 rows row1 start */}
<div className="populertrainrouteheading">
  Popular Trains Routes
</div>
{/* popular train route sextion 2 rows row1 end */}
{/* popular train route sextion 2 rows row2 start */}
<div className="populertrainroute">
  <div className="populertrainroutebox">
 <div className="populertrainrouteboxcol1"><img src={backgroundslider1} alt="" className='populerflightimage'/></div>
 <div className="populertrainrouteboxcol2"> <strong>Mumbai Trains</strong> <p style={{fontSize:'0.8rem'}}>To: Goa, Delhi, Bangalore, Ahmedabad</p>  </div>
    </div>
  <div className="populertrainroutebox">
    <div className="populertrainrouteboxcol1"><img src={backgroundslider1} alt="" className='populerflightimage'/></div>
    <div className="populertrainrouteboxcol2"><strong>Delhi Trains</strong>  <p style={{fontSize:'0.8rem'}}>To: Goa, Delhi, Bangalore, Ahmedabad</p>   </div>
  </div>
  <div className="populertrainroutebox">
    <div className="populertrainrouteboxcol1"><img src={backgroundslider2} alt="" className='populerflightimage'/></div>
    <div className="populertrainrouteboxcol2"><strong>Kolkata Trains</strong>   <p style={{fontSize:'0.8rem'}}>To: Goa, Delhi, Bangalore, Ahmedabad</p>   </div>
  </div>
  <div className="populertrainroutebox">
    <div className="populertrainrouteboxcol1"><img src={backgroundslider3} alt="" className='populerflightimage'/></div>
    <div className="populertrainrouteboxcol2"><strong>Chennai Trains</strong>   <p style={{fontSize:'0.8rem'}}>To: Goa, Delhi, Bangalore, Ahmedabad</p>   </div>
  </div>
  <div className="populertrainroutebox">
    <div className="populertrainrouteboxcol1"><img src={backgroundslider4} alt="" className='populerflightimage'/></div>
    <div className="populertrainrouteboxcol2"><strong>Hyderabad Trains</strong> <p style={{fontSize:'0.8rem'}}>To: Goa, Delhi, Bangalore, Ahmedabad</p>    </div>
  </div>
  <div className="populertrainroutebox">
    <div className="populertrainrouteboxcol1"><img src={backgroundslider5} alt="" className='populerflightimage'/></div>
    <div className="populertrainrouteboxcol2"><strong>Ahemdabad Trains</strong> <p style={{fontSize:'0.8rem'}}>To: Goa, Delhi, Bangalore, Ahmedabad</p>    </div>
  </div>
  <div className="populertrainroutebox">
    <div className="populertrainrouteboxcol1"><img src={backgroundslider1} alt="" className='populerflightimage'/></div>
    <div className="populertrainrouteboxcol2"><strong>Bangalore Trains</strong> <p style={{fontSize:'0.8rem'}}>To: Goa, Delhi, Bangalore, Ahmedabad</p>       </div>
  </div>
  <div className="populertrainroutebox">
    <div className="populertrainrouteboxcol1"><img src={backgroundslider2} alt="" className='populerflightimage'/></div>
    <div className="populertrainrouteboxcol2"><strong>Pune Trains</strong>      <p style={{fontSize:'0.8rem'}}>To: Goa, Delhi, Bangalore, Ahmedabad</p>    </div>
  </div>
  <div className="populertrainroutebox">
    <div className="populertrainrouteboxcol1"><img src={backgroundslider3} alt="" className='populerflightimage'/></div>
    <div className="populertrainrouteboxcol2"><strong>Goa Trains</strong>       <p style={{fontSize:'0.8rem'}}>To: Goa, Delhi, Bangalore, Ahmedabad</p></div>
  </div>
</div>
{/* popular train route sextion 2 rows row2 end */}
</div>
{/* popular train route sextion end */}
      <div className="traincontainerfifthrow">
        <div className="faqcontainer">
          <h1>Frequently Asked Questions</h1>
          <details>
            <summary>How do I create an accordion?</summary>
            <div>
              The tags <em>details</em> and <em>summary</em> have you covered.
            </div>
          </details>
          <details>
            <summary>How do I create an accordion?</summary>
            <div>
              The tags <em>details</em> and <em>summary</em> have you covered.
            </div>
          </details>
          <details>
            <summary>How do I create an accordion?</summary>
            <div>
              The tags <em>details</em> and <em>summary</em> have you covered.
            </div>
          </details>
          <details >
            <summary>FAQ 1</summary>
            <div class="faq__content">
              <p>Answer 1</p>
            </div>
          </details>
          <details>
            <summary>FAQ 2</summary>
            <div class="faq__content">
              <p>Answer 2</p>
            </div>
          </details>
          <details>
            <summary>FAQ 3</summary>
            <div class="faq__content">
              <p>Answer 3</p>
            </div>
          </details>
        </div>
      </div>
      <div className="faqdetails">
<h2 className='faqdetailsh2'> Train Ticket Booking</h2>
  <ul className='faqdetailsul'>
    <li className='faqdetailsli'>Tripinbudget offers a user-friendly interface designed to simplify the process of searching and booking flights. Our platform boasts a comprehensive database comprising both domestic and international flights, encompassing a wide range of carriers worldwide. The seamless experience provided by Tripinbudget ensures efficient and convenient train bookings for users.</li>
    <li className='faqdetailsli'>Benefit from each airline reservation made through Tripinbudget, and maximize your savings by leveraging your Tripinbudget funds for subsequent bookings. Our platform is committed to delivering cost-effective solutions, allowing you to secure cheaper airline tickets and enhance your overall travel experience.</li>
  </ul>

  <h2 className='faqdetailsh2'>Cheap Train Ticket</h2>
  <ul className='faqdetailsul'>
    <li className='faqdetailsli'>Tripinbudget offers cost-effective airfares for both domestic and international destinations.</li>
    <li className='faqdetailsli'>To secure the best savings on flights, exclusive offers, and discounts, Tripinbudget meticulously compiles and compares travel data, including rates, availability, and future travel dates.</li>
    <li className='faqdetailsli'>Discover more affordable airline tickets with ease compared to other websites.</li>
    <li className='faqdetailsli'>Tripinbudget facilitates swift savings on airline tickets through strategic partnerships with various banks, airlines, and businesses. With Tripinbudget's innovative fare alert tool, you can conveniently book both local and international airline tickets online, enjoying savings of up to 40%.</li>
    <li className='faqdetailsli'>For those seeking budget-friendly options, Tripinbudget provides Special Fares tailored for specific categories such as Students, Senior Citizens, and Armed Forces personnel. Opting for these Special Fares allows you to capitalize on substantial savings while selecting from a range of available train options. Make your travel experience efficient, economical, and enjoyable with Tripinbudget.</li>
  </ul>

  <h2 className='faqdetailsh2'>Domestic Train Booking</h2>
  <blockquote>
    As a premier platform in India, Tripinbudget stands out for providing economical train options for both domestic and international travel. Our platform ensures that you can effortlessly discover a cost-effective train that aligns with your vacation requirements, thanks to our extensive array of train alternatives offered by various airlines.
    Moreover, Tripinbudget facilitates the acquisition of affordable airline tickets, allowing you to efficiently compare train costs. For a seamless and hassle-free travel experience, we recommend booking flights with leading domestic airlines such as Air India, Vistara, Indigo Airlines, Spicejet, AirAsia, Go First Flights, and Air-India Express.
  </blockquote>
</div>
    </div>
    // main container end
  );
}