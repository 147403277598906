
import React, { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../Components/flight.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import FocusOnSelect from './Carousel2';
import backgroundslider3 from '../Images/flightbackground1.png'
import backgroundslider2 from '../Images/flightbackground2.png'
import backgroundslider1 from '../Images/plane3.png'
import backgroundslider4 from '../Images/flightbacground3.png'
import backgroundslider5 from '../Images/flightbackground4.png'
import backgroundslider6 from '../Images/plane4.png'
import axios from 'axios';
import Select from 'react-select';
export default function FLight() {

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
 
  const [destinationLocationCode, setDestinationLocationCode] = useState('');
  const [departureDate, setDepartureDate] = useState('');
  const [travelerType, setTravelerType] = useState('ADULT');
  const [searchTermTo, setSearchTermTo] = useState('');
  const [destinationOptions, setDestinationOptions] = useState([]);
  const [errorTo, setErrorTo] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [originOptions, setOriginOptions] = useState([]);
  const [originLocationCode, setOriginLocationCode] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // First API for searching by name for arrival airport location code
        const responseName = await axios.get('https://airports15.p.rapidapi.com/airports', {
          params: { name: searchTerm },
          headers: {
            'X-RapidAPI-Key': 'baa5306601msh44eade617a3c3d1p188b0djsn9f7772669cc0',
            'X-RapidAPI-Host': 'airports15.p.rapidapi.com',
          },
        });
        // Second API for searching by IATA code  for arrival airport location code
        const responseIata = await axios.get('https://airport-info.p.rapidapi.com/airport', {
          params: { iata: searchTerm },
          headers: {
            'X-RapidAPI-Key': 'baa5306601msh44eade617a3c3d1p188b0djsn9f7772669cc0',
            'X-RapidAPI-Host': 'airport-info.p.rapidapi.com',
          },
        });
        // Combine the results from both APIs in options formate it will show inside dropdown
        const options = [
          ...responseName.data.map((airport) => ({
            value: airport.iataCode,
            label: `${airport.iataCode} - ${airport.city}`,
          })),
          {
            value: responseIata.data.iata,
            label: `${responseIata.data.iata} - ${responseIata.data.city}`,
          },
        ];
        setOriginOptions(options);
        setError(null);
      } catch (error) {
        setOriginOptions([]);
        setError(error.message);
      }
    };
    if (searchTerm) {
      fetchData();
    }
  }, [searchTerm]);

  useEffect(() => {
    //useeffect for fetching the iata airport code by searching airportname and iata code
    const fetchDestinationData = async () => {
      try {
        // First API for searching by name for destination location code
        const responseName = await axios.get('https://airports15.p.rapidapi.com/airports', {
          params: { name: searchTermTo },
          headers: {
            'X-RapidAPI-Key': 'baa5306601msh44eade617a3c3d1p188b0djsn9f7772669cc0',
            'X-RapidAPI-Host': 'airports15.p.rapidapi.com',
          },
        });
        // Second API for searching by IATA code for destination location code
        const responseIata = await axios.get('https://airport-info.p.rapidapi.com/airport', {
          params: { iata: searchTermTo },
          headers: {
            'X-RapidAPI-Key': 'baa5306601msh44eade617a3c3d1p188b0djsn9f7772669cc0',
            'X-RapidAPI-Host': 'airport-info.p.rapidapi.com',
          },
        });
        // Combine the results from both APIs
        const OptionsTo = [
          ...responseName.data.map((airportTo) => ({
            value: airportTo.iataCode,
            label: `${airportTo.iataCode} - ${airportTo.city}`,
          })),
          {
            value: responseIata.data.iata,
            label: `${responseIata.data.iata} - ${responseIata.data.city}`,
          },
        ];
        setDestinationOptions(OptionsTo);
        setError(null);
      } catch (error) {
        setDestinationOptions([]);
        setError(error.message);
      }
    };
    if (searchTermTo) {
      fetchDestinationData();
    }
  }, [searchTermTo]);

  const handleSubmit = async () => {
    try {
      // Redirect to results page with query parameters inside the url
      navigate(`/flightavailable/result?origin=${originLocationCode.value}&destination=${destinationLocationCode.value}&date=${departureDate}&travelerType=${travelerType}`);
    } catch (error) {
      console.error('Error:', error.message);
    }
  };
 
  useEffect(() => {
    // Automatically change the image every 3 seconds
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % 6);
    }, 5000);
    // Clear interval on component unmount
    return () => clearInterval(interval);
  }, []);

  const images = [backgroundslider1, backgroundslider2, backgroundslider3,backgroundslider4,backgroundslider5,backgroundslider6];
  return (
            // main container
                  <div className="flightcontainer">
              {/* fisrtrow background image section */}
              <div className="flightcontainerfirstrow">
              <div
          className="image-slider"
          style={{
            backgroundImage: `url(${images[currentImageIndex]})`,
            transition: 'background-image 1s ease-in-out',
          }}
        />
      </div>
   <div class="flightsearchcontainer">

        <div className='availablecontainer'>
        <div className="availablecontainerrow">
          <div className="flight-flightcol">
            <label className="form-label">Origin Location Code:</label>
            <Select
              options={originOptions}
              value={originLocationCode}
              onChange={(selectedOption) => setOriginLocationCode(selectedOption)}
              onInputChange={(inputValue) => setSearchTerm(inputValue)}
              placeholder="Enter airport"
            />
          </div>
          <div className="flight-flightcol">
            <label className="form-label">Destination Location Code:</label>
            <Select
              options={destinationOptions}
              value={destinationLocationCode}
              onChange={(selectedOption) => setDestinationLocationCode(selectedOption)}
              onInputChange={(inputValueTo) => setSearchTermTo(inputValueTo)}
              placeholder="Enter airport"
            />
          </div>
          <div className="flight-flightcol">
            <label className="form-label">Departure Date:</label>
            <input className="form-control" type="date" value={departureDate} onChange={(e) => setDepartureDate(e.target.value)} />
          </div>
          <div className="flight-flightcol">
  <label className="form-label">Traveler Type:</label>
  <Select
    options={[
      { value: 'ADULT', label: 'Adult' },
  
    ]}
    value={{ value: travelerType, label: travelerType }} // Set the value and label
    onChange={(selectedOption) => setTravelerType(selectedOption.value)} // Set the selected value
    placeholder="Select traveler type"
  />
</div>
          <div className="flight-flightcol">
            <button className="btn btn-primary" onClick={handleSubmit}>Search</button>
          </div>
        </div>
      </div>
    </div>
{/* </div> */}
{/* flight offer carousel third row */}
<div className="flightcontainerthirdrow">
  <div className="flightcontainerthirdrowcol1">
    <FocusOnSelect/>
  </div>
</div>




{/* popular flight route sextion start */}
<div className="populerflightroutecontainer">
{/* popular flight route sextion 2 rows row1 start */}
<div className="populerflightrouteheading">
  Popular Flight Routes
</div>
{/* popular flight route sextion 2 rows row1 end */}
{/* popular flight route sextion 2 rows row2 start */}
<div className="populerflightroute">
  <div className="populerflightroutebox">
 <div className="populerflightrouteboxcol1"><img src={backgroundslider1} alt="" className='populerflightimage'/></div>
 <div className="populerflightrouteboxcol2"> <strong>Mumbai Flights</strong> <p style={{fontSize:'0.8rem'}}>To: Goa, Delhi, Bangalore, Ahmedabad</p>  </div>
    </div>
  <div className="populerflightroutebox">
    <div className="populerflightrouteboxcol1"><img src={backgroundslider1} alt="" className='populerflightimage'/></div>
    <div className="populerflightrouteboxcol2"><strong>Delhi Flights</strong>  <p style={{fontSize:'0.8rem'}}>To: Goa, Delhi, Bangalore, Ahmedabad</p>   </div>
  </div>
  <div className="populerflightroutebox">
    <div className="populerflightrouteboxcol1"><img src={backgroundslider2} alt="" className='populerflightimage'/></div>
    <div className="populerflightrouteboxcol2"><strong>Kolkata Flights</strong>   <p style={{fontSize:'0.8rem'}}>To: Goa, Delhi, Bangalore, Ahmedabad</p>   </div>
  </div>
  <div className="populerflightroutebox">
    <div className="populerflightrouteboxcol1"><img src={backgroundslider3} alt="" className='populerflightimage'/></div>
    <div className="populerflightrouteboxcol2"><strong>Chennai Flights</strong>   <p style={{fontSize:'0.8rem'}}>To: Goa, Delhi, Bangalore, Ahmedabad</p>   </div>
  </div>
  <div className="populerflightroutebox">
    <div className="populerflightrouteboxcol1"><img src={backgroundslider4} alt="" className='populerflightimage'/></div>
    <div className="populerflightrouteboxcol2"><strong>Hyderabad Flights</strong> <p style={{fontSize:'0.8rem'}}>To: Goa, Delhi, Bangalore, Ahmedabad</p>    </div>
  </div>
  <div className="populerflightroutebox">
    <div className="populerflightrouteboxcol1"><img src={backgroundslider5} alt="" className='populerflightimage'/></div>
    <div className="populerflightrouteboxcol2"><strong>Ahemdabad Flights</strong> <p style={{fontSize:'0.8rem'}}>To: Goa, Delhi, Bangalore, Ahmedabad</p>    </div>
  </div>
  <div className="populerflightroutebox">
    <div className="populerflightrouteboxcol1"><img src={backgroundslider1} alt="" className='populerflightimage'/></div>
    <div className="populerflightrouteboxcol2"><strong>Bangalore Flights</strong> <p style={{fontSize:'0.8rem'}}>To: Goa, Delhi, Bangalore, Ahmedabad</p>       </div>
  </div>
  <div className="populerflightroutebox">
    <div className="populerflightrouteboxcol1"><img src={backgroundslider2} alt="" className='populerflightimage'/></div>
    <div className="populerflightrouteboxcol2"><strong>Pune Flights</strong>      <p style={{fontSize:'0.8rem'}}>To: Goa, Delhi, Bangalore, Ahmedabad</p>    </div>
  </div>
  <div className="populerflightroutebox">
    <div className="populerflightrouteboxcol1"><img src={backgroundslider3} alt="" className='populerflightimage'/></div>
    <div className="populerflightrouteboxcol2"><strong>Goa Flights</strong>       <p style={{fontSize:'0.8rem'}}>To: Goa, Delhi, Bangalore, Ahmedabad</p></div>
  </div>
</div>
{/* popular flight route sextion 2 rows row2 end */}
</div>
{/* popular flight route sextion end */}


{/* about us section start */}
<div className="aboutuscontainer">
  {/* about us section column 1 start */}
  <div className="aboutuscontainerrow1">
  <h2>About Us</h2><br />
<p className='aboutustitle1'>Explore all corners of the world with us.</p><br />
<p className='aboutussubtitle1'>Embark on a journey with us to explore all corners of the world. From iconic landmarks to hidden gems, our global destinations, comprehensive routes, and personalized travel experiences ensure that your adventure knows no bounds. Join us in discovering the diverse wonders our planet has to offer!</p>
<ul>
    <li className='aboutussubtitle2'>
        <strong style={{color:'#007BFF',fontSize:'1.2rem'}}><span className="icon">🌍</span>Global Destinations</strong><br /> Highlight the extensive range of destinations your airline or travel service covers. Emphasize that customers can choose from a wide array of locations across continents, providing them with the opportunity to explore diverse cultures and landscapes.
    </li>
    <li className='aboutussubtitle2'>
        <strong style={{color:'#007BFF',fontSize:'1.2rem'}}><span className="icon">🌐</span>Comprehensive Routes</strong><br /> Lorem Ipsum is available, but the majority have suffered alteration in some. We believe in providing affordable and budget-friendly travel options.
    </li>
    <li className='aboutussubtitle2'>
        <strong style={{color:'#007BFF',fontSize:'1.2rem'}}><span className="icon">🏞️</span>Adventure and Exploration</strong><br /> Lorem Ipsum is available, but the majority have suffered alteration in some. Count on us for reliable and well-organized tours that meet your expectations.
    </li>
    <li className='aboutussubtitle2'>
        <strong style={{color:'#007BFF',fontSize:'1.2rem'}}><span className="icon">✈️</span>Personalized Travel Experiences</strong><br /> Lorem Ipsum is available, but the majority have suffered alteration in some. Count on us for reliable and well-organized tours that meet your expectations.
    </li>
</ul>
<p>Embark on unforgettable adventures with our dedicated team. Your journey starts here!</p>
  </div>
    {/* about us section column 1 end */}
      {/* about us section column 2 start  for image*/}
  <div className="aboutuscontainerrow2">
  </div>
       {/* about us section column 2 end */}
  {/* about us section end */}
</div>
{/* faq section */}
<div className="flightcontainerfifthrow">
<div className="faqcontainer">
  <div className="populerflightrouteheading">Frequently Asked Questions</div>
    
    <details>
    <summary> Flight Ticket Booking</summary>
    <div className='faqopen'>
      <p style={{textAlign:'start',paddingLeft:'10px',paddingRight:'10px'}} className='faqli'>
      Tripinbudget offers a user-friendly interface designed to simplify the process of searching and booking flights. Our platform boasts a comprehensive database comprising both domestic and international flights, encompassing a wide range of carriers worldwide. The seamless experience provided by Tripinbudget ensures efficient and convenient flight bookings for users.

      </p>
      <p style={{textAlign:'start',paddingLeft:'10px',paddingRight:'10px'}}  className='faqli'>
      Benefit from each airline reservation made through Tripinbudget, and maximize your savings by leveraging your Tripinbudget funds for subsequent bookings. Our platform is committed to delivering cost-effective solutions, allowing you to secure cheaper airline tickets and enhance your overall travel experience.
      </p>
    
    </div>
  </details>
  <details>
      <summary>Why is flight ticket booking the cheapest on tripinbudget?
</summary>
      <div>
    <p style={{textAlign:'start',paddingLeft:'10px',paddingRight:'10px'}} className='faqli'>Visit our website to discover the best deals on airfare. Enter the departure and arrival cities to explore the lowest days to fly using our fare calendar. 
    </p>
    <p style={{textAlign:'start',paddingLeft:'10px',paddingRight:'10px'}} className='faqli'>
    Low-cost airlines offer lower fares compared to full-service carriers, allowing you to save a significant amount on airline tickets.
    </p>
      </div>
    </details>
    <details>
    <summary>How to book cheap flight tickets?
</summary>
    <div>
    <p style={{textAlign:'start',paddingLeft:'10px',paddingRight:'10px'}}>
  <ul>
    <li className='faqli'> <strong>Here's a guide on purchasing affordable airline tickets:</strong>
</li>
    <li >
      <p className='faqli'>To secure the best prices, make your airline ticket purchases in advance through <strong>Tripinbudget</strong>.</p>
    </li>

    <li>
      <p className='faqli'>Cultivate flexibility and consider acquiring tickets during off-peak times to optimize savings on your travel expenses.</p>
    </li>

    <li>
      <p className='faqli'>Utilize the <strong>Tripinbudget</strong> fare alerts feature by signing up to receive notifications when ticket prices decrease.</p>
    </li>

    <li>
      <p className='faqli'>If you have the flexibility, explore stopover flights as they tend to be more cost-effective.</p>
    </li>

    <li>
      <p className='faqli'>Avoid weekend travel, as airline tickets are generally more expensive during this period.</p>
    </li>

    <li>
      <p className='faqli'>Opt for budget carriers to find low-cost plane tickets.</p>
    </li>
  </ul></p>
    </div>
  </details>
  <details >
    <summary>How can I get domestic flight offers on tripinbudget?
</summary>
    <div>
    <ul style={{textAlign:'start',paddingLeft:'10px',paddingRight:'10px'}}>
    <li className='faqli'> <strong>To access exclusive offers for domestic flights on Tripinbudget, follow these steps:
</strong>
</li>
    <li >
      <p className='faqli'>Enter the dates and destination of your vacation on the <strong> Tripinbudget</strong> website.

</p>
    </li>

    <li>
      <p className='faqli'>Navigate to the <strong>"offers" </strong> page to view the latest specials and discounts on domestic flights.
</p>
    </li>

    <li>
      <p className='faqli'>Choose your preferred deal and complete the booking by making the payment using various methods such as wallets, credit and debit cards, and net banking.
</p>
    </li>

    <li>
      <p className='faqli'>Periodically, <strong>Tripinbudget</strong> provides a range of cashback deals on domestic airline reservations.</p>
    </li>

  
  </ul>
    </div>
  </details>
 
  </div>
</div>
{/* faq section end */}
<div className="faqdetails">
<h2 className='faqdetailsh2'> Flight Ticket Booking</h2>
  <ul className='faqdetailsul'>
    <li className='faqdetailsli'>Tripinbudget offers a user-friendly interface designed to simplify the process of searching and booking flights. Our platform boasts a comprehensive database comprising both domestic and international flights, encompassing a wide range of carriers worldwide. The seamless experience provided by Tripinbudget ensures efficient and convenient flight bookings for users.</li>
    <li className='faqdetailsli'>Benefit from each airline reservation made through Tripinbudget, and maximize your savings by leveraging your Tripinbudget funds for subsequent bookings. Our platform is committed to delivering cost-effective solutions, allowing you to secure cheaper airline tickets and enhance your overall travel experience.</li>
  </ul>

  <h2 className='faqdetailsh2'>Cheap Flight Ticket</h2>
  <ul className='faqdetailsul'>
    <li className='faqdetailsli'>Tripinbudget offers cost-effective airfares for both domestic and international destinations.</li>
    <li className='faqdetailsli'>To secure the best savings on flights, exclusive offers, and discounts, Tripinbudget meticulously compiles and compares travel data, including rates, availability, and future travel dates.</li>
    <li className='faqdetailsli'>Discover more affordable airline tickets with ease compared to other websites.</li>
    <li className='faqdetailsli'>Tripinbudget facilitates swift savings on airline tickets through strategic partnerships with various banks, airlines, and businesses. With Tripinbudget's innovative fare alert tool, you can conveniently book both local and international airline tickets online, enjoying savings of up to 40%.</li>
    <li className='faqdetailsli'>For those seeking budget-friendly options, Tripinbudget provides Special Fares tailored for specific categories such as Students, Senior Citizens, and Armed Forces personnel. Opting for these Special Fares allows you to capitalize on substantial savings while selecting from a range of available flight options. Make your travel experience efficient, economical, and enjoyable with Tripinbudget.</li>
  </ul>

  <h2 className='faqdetailsh2'>Domestic Flight Booking</h2>
  <blockquote>
    As a premier platform in India, Tripinbudget stands out for providing economical flight options for both domestic and international travel. Our platform ensures that you can effortlessly discover a cost-effective flight that aligns with your vacation requirements, thanks to our extensive array of flight alternatives offered by various airlines.
    Moreover, Tripinbudget facilitates the acquisition of affordable airline tickets, allowing you to efficiently compare flight costs. For a seamless and hassle-free travel experience, we recommend booking flights with leading domestic airlines such as Air India, Vistara, Indigo Airlines, Spicejet, AirAsia, Go First Flights, and Air-India Express.
  </blockquote>
</div>
</div>

// main container end
  );
}
