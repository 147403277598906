// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { toast, ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import '../Components/AuthForm.css'; 
// import logsvg from '../assets/log.svg';
// import registersvg from '../assets/register.svg';
// const AuthForm = () => {
//     const [isSignUpMode, setIsSignUpMode] = useState(false);
//     const [email, setEmail] = useState('');
//     const [password, setPassword] = useState('');
//     const [name, setName] = useState('');
//     const [emailError, setEmailError] = useState(false);
//     const [passwordError, setPasswordError] = useState(false);
//     const [nameError, setNameError] = useState(false);
//     const [showPassword, setShowPassword] = useState(false);


    
//   const navigate = useNavigate();
//     const handleSignUpClick = () => {
//       setIsSignUpMode(true);
//     };
  
//     const handleSignInClick = () => {
//       setIsSignUpMode(false);
//     };
//     const showToast = (message, type) => {
//         toast[type](message, {
//           position: 'top-center',
//           autoClose: 3000,
//           hideProgressBar: false,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//           progress: undefined,
//         });
//       };

    
//     const handleLogin = () => {
//            if (!email || !password) {
//       showToast('Email and password are required', 'error');
//       setEmailError(!email);
//       setPasswordError(!password);
//       return;
//     }
//     setEmailError(false);
//     setPasswordError(false);
//       // Make a POST request to the login endpoint
//       fetch('http:${BASE_URL}/api/login', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           email: email,
//           password: password,
//         }),
//       })
//         .then((response) => response.json())
//         .then((data) => {
           
//           if (data.success) {
//             // Handle successful login, e.g., redirect or update state
//             console.log('Login successful');
//             showToast('Login successful', 'success');
//             navigate('/');
//           } else {
//             // Handle login failure, e.g., show an error message
//             console.error('Login failed:', data.message);
//             showToast(`Login failed: ${data.message}`, 'error');
//           }
//         })
//         .catch((error) => {
//           console.error('Error during login:', error);
//           showToast('Error during login', 'error');
//         });
//     };
  
//     const handleSignUp = () => {
//         if (!name || !email || !password) {
//             showToast('Name, email, and password are required', 'error');
//             setNameError(!name);
//             setEmailError(!email);
//             setPasswordError(!password);
//             return;
//           }
//           setNameError(false);
//           setEmailError(false);
//           setPasswordError(false);
//       // Make a POST request to the register endpoint
//       fetch('http:${BASE_URL}/api/register', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           name: name, // Set the name as needed
//           email: email,
//           password: password,
//         }),
//       })
//         .then((response) => response.json())
//         .then((data) => {
          
//           if (data.success) {
//             // Handle successful registration, e.g., redirect or update state
//             console.log('Registration successful');
//             showToast('Registration successful', 'success');
//             navigate('/login');
//           } else {
//             // Handle registration failure, e.g., show an error message
//             console.error('Registration failed:', data.message);
//             showToast(`Registration failed: ${data.message}`, 'error');
//           }
//         })
//         .catch((error) => {
//           console.error('Error during registration:', error);
//           showToast('Error during registration', 'error');
//         });
//     };

//   return (
//     <div className="loginregistercontainer">
//     <div className={`container ${isSignUpMode ? 'sign-up-mode' : ''}`}>
//       <div className="forms-container">
//         <div className="signin-signup">
//         <form action="#" class="sign-in-form">
//             <h2 class="title">Sign in</h2>
//             <div className={`input-field ${emailError ? 'error' : ''}`}>
//               <i class="fas fa-user"></i>
              
//               <input
//                   type="email"
//                   placeholder="Email"
//                   value={email}
//                   onChange={(e) => setEmail(e.target.value)}
//                   onFocus={() => setEmailError(false)}
//                   required
//                 />
//             </div>
//             <div className={`input-field ${passwordError ? 'error' : ''}`}>
//               <i class="fas fa-lock"></i>
//               <input
//               type={showPassword ? 'text' : 'password'}
//               placeholder="Password"
//               value={password}
//               onChange={(e) => setPassword(e.target.value)}
//               onFocus={() => setPasswordError(false)}
//               required
//             />
//             <i
//               className={`loginicon fas ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`}
//               onClick={() => setShowPassword(!showPassword)}
//             ></i>
//             </div>
//             <button type="button" className="btn" onClick={handleLogin}>
//                 Login
//             </button>
//             <p class="social-text">Or Sign in with social platforms</p>
//             <div class="social-media">
//               <a href="#" class="social-icon">
//                 <i class="fab fa-facebook-f"></i>
//               </a>
//               <a href="#" class="social-icon">
//                 <i class="fab fa-twitter"></i>
//               </a>
//               <a href="#" class="social-icon">
//                 <i class="fab fa-google"></i>
//               </a>
//               <a href="#" class="social-icon">
//                 <i class="fab fa-linkedin-in"></i>
//               </a>
//             </div>
//           </form>
//           <form action="#" class="sign-up-form">
//             <h2 class="title">Sign up</h2>
//             <div className={`input-field ${nameError ? 'error' : ''}`}>
//               <i class="fas fa-user"></i>
//               <input
//                   type="text"
//                   placeholder="Username"
//                   value={name}
//                   onChange={(e) => setName(e.target.value)}
//                   onFocus={() => setNameError(false)}
//                   required
//                 />
//             </div>
//             <div className={`input-field ${emailError ? 'error' : ''}`}>
//               <i class="fas fa-envelope"></i>
//               <input
//                   type="email"
//                   placeholder="Email"
//                   value={email}
//                   onChange={(e) => setEmail(e.target.value)}
//                   onFocus={() => setEmailError(false)}
//                   required
//                 />
//             </div>
//             <div className={`input-field ${passwordError ? 'error' : ''}`}>
//               <i class="fas fa-lock"></i>
//               <input
//               type={showPassword ? 'text' : 'password'}
//               placeholder="Password"
//               value={password}
//               onChange={(e) => setPassword(e.target.value)}
//               onFocus={() => setPasswordError(false)}
//               required
//             />
//             <i
//               className={`loginicon fas ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`}
//               onClick={() => setShowPassword(!showPassword)}
//             ></i>
//             </div>
//             <button type="button" className="btn" onClick={handleSignUp}>
//                 Sign up
//             </button>
//             <p class="social-text">Or Sign up with social platforms</p>
//             <div class="social-media">
//               <a href="#" class="social-icon">
//                 <i class="fab fa-facebook-f"></i>
//               </a>
//               <a href="#" class="social-icon">
//                 <i class="fab fa-twitter"></i>
//               </a>
//               <a href="#" class="social-icon">
//                 <i class="fab fa-google"></i>
//               </a>
//               <a href="#" class="social-icon">
//                 <i class="fab fa-linkedin-in"></i>
//               </a>
//             </div>
//           </form>
//         </div>
//       </div>

//       <div className="panels-container">
//         <div className="panel left-panel">
//           <div className="content">
//             <h3>New here ?</h3>
//             <p>
//             Your journey continues here – Sign in with us for a secure and tailored experience, just the way you like it!!!
//             </p>
//             <button className="btn transparent" onClick={handleSignUpClick}>
//               Sign up
//             </button>
//           </div>
//           <img src={logsvg} className="image" alt="" />
//         </div>
//         <div className="panel right-panel">
//           <div className="content">
//             <h3>One of us ?</h3>
//             <p>
//             Unlock a personalized experience – Login with us and discover seamless access to your account and exclusive features...!!!
//             </p>
//             <button className="btn transparent" onClick={handleSignInClick}>
//               Sign in
//             </button>
//           </div>
//           <img src={registersvg} className="image" alt="" />
//         </div>
//       </div>
//     </div>
//     </div>
//   );
// };

// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import axios from 'axios';
// import '../Components/AuthForm.css';
// import logsvg from '../assets/log.svg';
// import registersvg from '../assets/register.svg';

// const AuthForm = () => {
//   const [isSignUpMode, setIsSignUpMode] = useState(false);
//   const [email, setEmail] = useState('');
//   const [password, setPassword] = useState('');
//   const [name, setName] = useState('');
//   const [emailError, setEmailError] = useState(false);
//   const [passwordError, setPasswordError] = useState(false);
//   const [nameError, setNameError] = useState(false);
//   const [showPassword, setShowPassword] = useState(false);
//   const navigate = useNavigate();

//   const handleSignUpClick = () => {
//     setIsSignUpMode(true);
//   };

//   const handleSignInClick = () => {
//     setIsSignUpMode(false);
//   };

//   const showToast = (message, type) => {
//     toast[type](message, {
//       position: 'top-center',
//       autoClose: 3000,
//       hideProgressBar: false,
//       closeOnClick: true,
//       pauseOnHover: true,
//       draggable: true,
//       progress: undefined,
//     });
//   };

//   const handleLogin = async () => {
//     try {
//       // const response = await axios.post('h${BASE_URL}/api/login', {
//         const response = await axios.post('${BASE_URL}/api/login', {
//         email,
//         password,
//       });

//       if (response.data.success) {
//         showToast(response.data.message, 'success');
//         setEmail('');
//         setPassword('');
        
//         // Redirect or perform actions after successful login
//       } else {
//         showToast(response.data.message, 'error');
//       }
//     } catch (error) {
//       console.error('Error during login:', error);
//       showToast('An error occurred during login', 'error');
//     }
//   };

//   const handleRegister = async () => {
//     try {
//       // const response = await axios.post('h${BASE_URL}/api/register', {
//       const response = await axios.post('${BASE_URL}/api/register', {
//         name,
//         email,
//         password,
//       });
  
//       if (response.data.success) {
//         showToast(response.data.message, 'success');
//         setName('');
//         setEmail('');
//         setPassword('');
//         // Redirect or perform actions after successful registration
//       } else {
//         showToast(response.data.message, 'error');
//       }
//     } catch (error) {
//       console.error('Error during registration:', error);
//       if (error.response && error.response.status === 400) {
//         // If the server responds with a 400 status code, display the error message
//         showToast(error.response.data.message, 'error');
//       } else {
//         showToast('An error occurred during registration', 'error');
//       }
//     }
//   };
  
//   return (
//     <div className="loginregistercontainer">
//     <div className={`container ${isSignUpMode ? 'sign-up-mode' : ''}`}>
//       <div className="forms-container">
//         <div className="signin-signup">
//         <form action="#" class="sign-in-form">
//             <h2 class="title">Sign in</h2>
//             <div className={`input-field ${emailError ? 'error' : ''}`}>
//               <i class="fas fa-user"></i>
              
//               <input
//                   type="email"
//                   placeholder="Email"
//                   value={email}
//                   onChange={(e) => setEmail(e.target.value)}
//                   onFocus={() => setEmailError(false)}
//                   required
//                 />
//             </div>
//             <div className={`input-field ${passwordError ? 'error' : ''}`}>
//               <i class="fas fa-lock"></i>
//               <input
//               type={showPassword ? 'text' : 'password'}
//               placeholder="Password"
//               value={password}
//               onChange={(e) => setPassword(e.target.value)}
//               onFocus={() => setPasswordError(false)}
//               required
//             />
//             <i
//               className={`loginiconn fas ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`}
//               onClick={() => setShowPassword(!showPassword)}
//             ></i>
//             </div>
//             <button type="button" className="btn" onClick={handleLogin}>
//                 Login
//             </button>
//             <p class="social-text">Or Sign in with social platforms</p>
//             <div class="social-media">
//               <a href="#" class="social-icon">
//                 <i class="fab fa-facebook-f"></i>
//               </a>
//               <a href="#" class="social-icon">
//                 <i class="fab fa-twitter"></i>
//               </a>
//               <a href="#" class="social-icon">
//                 <i class="fab fa-google"></i>
//               </a>
//               <a href="#" class="social-icon">
//                 <i class="fab fa-linkedin-in"></i>
//               </a>
//             </div>
//           </form>
//           <form action="#" class="sign-up-form">
//             <h2 class="title">Sign up</h2>
//             <div className={`input-field ${nameError ? 'error' : ''}`}>
//               <i class="fas fa-user"></i>
//               <input
//                   type="text"
//                   placeholder="Username"
//                   value={name}
//                   onChange={(e) => setName(e.target.value)}
//                   onFocus={() => setNameError(false)}
//                   required
//                 />
//             </div>
//             <div className={`input-field ${emailError ? 'error' : ''}`}>
//               <i class="fas fa-envelope"></i>
//               <input
//                   type="email"
//                   placeholder="Email"
//                   value={email}
//                   onChange={(e) => setEmail(e.target.value)}
//                   onFocus={() => setEmailError(false)}
//                   required
//                 />
//             </div>
//             <div className={`input-field ${passwordError ? 'error' : ''}`}>
//               <i class="fas fa-lock"></i>
//               <input
//               type={showPassword ? 'text' : 'password'}
//               placeholder="Password"
//               value={password}
//               onChange={(e) => setPassword(e.target.value)}
//               onFocus={() => setPasswordError(false)}
//               required
//             />
//             <i
//               className={`loginiconn fas ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`}
//               onClick={() => setShowPassword(!showPassword)}
//             ></i>
//             </div>
//             <button type="button" className="btn" onClick={handleRegister} >
//                 Sign up
//             </button>
//             <p class="social-text">Or Sign up with social platforms</p>
//             <div class="social-media">
//               <a href="#" class="social-icon">
//                 <i class="fab fa-facebook-f"></i>
//               </a>
//               <a href="#" class="social-icon">
//                 <i class="fab fa-twitter"></i>
//               </a>
//               <a href="#" class="social-icon">
//                 <i class="fab fa-google"></i>
//               </a>
//               <a href="#" class="social-icon">
//                 <i class="fab fa-linkedin-in"></i>
//               </a>
//             </div>
//           </form>
//         </div>
//       </div>

//       <div className="panels-container">
//         <div className="panel left-panel">
//           <div className="content">
//             <h3>New here ?</h3>
//             <p>
//             Your journey continues here – Sign in with us for a secure and tailored experience, just the way you like it!!!
//             </p>
//             <button className="btn transparent" onClick={handleSignUpClick}>
//               Sign up
//             </button>
//           </div>
//           <img src={logsvg} className="image" alt="" />
//         </div>
//         <div className="panel right-panel">
//           <div className="content">
//             <h3>One of us ?</h3>
//             <p>
//             Unlock a personalized experience – Login with us and discover seamless access to your account and exclusive features...!!!
//             </p>
//             <button className="btn transparent" onClick={handleSignInClick}>
//               Sign in
//             </button>
//           </div>
//           <img src={registersvg} className="image" alt="" />
//         </div>
//       </div>
//     </div>
//     </div>
//   );
// };

// export default AuthForm;

import React, { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import '../Components/AuthForm.css';
import logsvg from '../assets/log.svg';
import registersvg from '../assets/register.svg';
import BASE_URL from '../Components/apiConfig';

const AuthForm = () => {
  const [isSignUpMode, setIsSignUpMode] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [mobile, setMobile] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [mobileError, setMobileError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isVerifyMode, setIsVerifyMode] = useState(false);
const [otpError, setOtpError] = useState(false);
const [otp, setOTP] = useState('');
const initialTimeRemaining = 118;
const [timeRemaining, setTimeRemaining] = useState(initialTimeRemaining);
  const navigate = useNavigate();
  const handleRfresh=()=>{
    setTimeout(()=>{
      window.location.reload();
    },1000);
  };

  const handleSignUpClick = () => {
    setIsSignUpMode(true);
    setIsVerifyMode(false);

  };

  const handleSignInClick = () => {
    setIsSignUpMode(false);
  };

  const showToast = (message, type) => {
    toast[type](message, {
      position: 'top-center',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleLogin = async () => {
    try {
      // const response = await axios.post('h${BASE_URL}/api/login', {
        const response = await axios.post(`${BASE_URL}/api/login`, {
        email,
        password,
      });

      if (response.data.success) {
        localStorage.setItem('isLoggedIn', 'true');
        localStorage.setItem('email', email);

        showToast(response.data.message, 'success');
        setEmail('');
        setPassword('');
        navigate('/');
        handleRfresh();
        // Redirect or perform actions after successful login
      } else {
        showToast(response.data.message, 'error');
      }
    } catch (error) {
      console.error('Error during login:', error);
      showToast('An error occurred during login', 'error');
    }
  };

  const handleRegister = async () => {
    
  
    try {
      // const response = await axios.post('h${BASE_URL}/api/register', {
      const response = await axios.post(`${BASE_URL}/api/register`, {
        name,
        email,
        password,
        mobile,
      });
  
      if (response.data.success) {
        showToast(response.data.message, 'success');
        setName('');
        setPassword('');
 
        setIsVerifyMode(true);
        // Redirect or perform actions after successful registration
      } else {
        showToast(response.data.message, 'error');
      }
    } catch (error) {
      console.error('Error during registration:', error);
      if (error.response && error.response.status === 400) {
        // If the server responds with a 400 status code, display the error message
        showToast(error.response.data.message, 'error');
      } else {
        showToast('An error occurred during registration', 'error');
      }
    }
  };
  const handleOtpValidation = async (e) => {
    e.preventDefault();
    try {
      // const response = await fetch('h${BASE_URL}/api/verify-otp', {
        const response = await fetch(`${BASE_URL}/api/verify-otp`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, otp }),
      });
      const data = await response.json();
      if (response.ok) {
        // If response is successful, show success toast
        toast.success(data.message);
        setIsSignUpMode(false);
        navigate('/login');
        setOTP('');
        setEmail('');
        // Redirect or perform actions after successful verification
      } else {
        // If response is not successful, show error toast
        toast.error(data.message);
      }
  
      console.log(data); // Handle the response accordingly
    } catch (error) {
      console.error('Error submitting OTP:', error);
      toast.error('Error submitting OTP. Please try again.');
    }
  };
  useEffect(() => {
    // Update timeRemaining every second
    const intervalId = setInterval(() => {
      setTimeRemaining((prevTime) => Math.max(0, prevTime - 1));
    }, 1000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);


  return (
    <div className="loginregistercontainer">
    <div className={`container ${isSignUpMode ? 'sign-up-mode' : ''}`}>
      <div className="forms-container">
        <div className="signin-signup">
        <form action="#" class="sign-in-form">
            <h2 class="title">Sign in</h2>
            <div className={`input-field ${emailError ? 'error' : ''}`}>
              <i class="fas fa-user"></i>
              <input
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onFocus={() => setEmailError(false)}
                  required
                />
            </div>
            <div className={`input-field ${passwordError ? 'error' : ''}`}>
              <i class="fas fa-lock"></i>
              <input
              type={showPassword ? 'text' : 'password'}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onFocus={() => setPasswordError(false)}
              required
            />
            <i
              className={`loginiconn fas ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`}
              onClick={() => setShowPassword(!showPassword)}
            ></i>
            </div>
            <button type="button" className="btn" onClick={handleLogin}>
                Login
            </button>
            <p class="social-text">Or Sign in with social platforms</p>
            <div class="social-media">
              <a href="#" class="social-icon">
                <i class="fab fa-facebook-f"></i>
              </a>
              <a href="#" class="social-icon">
                <i class="fab fa-twitter"></i>
              </a>
              <a href="#" class="social-icon">
                <i class="fab fa-google"></i>
              </a>
              <a href="#" class="social-icon">
                <i class="fab fa-linkedin-in"></i>
              </a>
            </div>
          </form>
          {!isVerifyMode ? (
          <form action="#" class="sign-up-form">
            <h2 class="title">Sign up</h2>
            <div className={`input-field ${nameError ? 'error' : ''}`}>
              <i class="fas fa-user"></i>
              <input
                  type="text"
                  placeholder="Username"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  onFocus={() => setNameError(false)}
                  required
                />
            </div>
            <div className={`input-field ${emailError ? 'error' : ''}`}>
              <i class="fas fa-envelope"></i>
              <input
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onFocus={() => setEmailError(false)}
                  required
                />
            </div>
            <div className={`input-field ${mobileError ? 'error' : ''}`}>
  <i className="fas fa-mobile-alt"></i>
  <input
    type="tel"
    placeholder="Mobile Number"
    value={mobile}
    onChange={(e) => setMobile(e.target.value)}
    onFocus={() => setMobileError(false)}
    required
  />
</div>


            <div className={`input-field ${passwordError ? 'error' : ''}`}>
              <i class="fas fa-lock"></i>
              <input
              type={showPassword ? 'text' : 'password'}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onFocus={() => setPasswordError(false)}
              required
            />
            <i
              className={`loginiconn fas ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`}
              onClick={() => setShowPassword(!showPassword)}
            ></i>
            </div>
            <button type="button" className="btn" onClick={handleRegister} >
                Sign up
            </button>
            <p class="social-text">Or Sign up with social platforms</p>
            <div class="social-media">
              <a href="#" class="social-icon">
                <i class="fab fa-facebook-f"></i>
              </a>
              <a href="#" class="social-icon">
                <i class="fab fa-twitter"></i>
              </a>
              <a href="#" class="social-icon">
                <i class="fab fa-google"></i>
              </a>
              <a href="#" class="social-icon">
                <i class="fab fa-linkedin-in"></i>
              </a>
            </div>
          </form>
          ) : (
            <form action="#" className="sign-up-form">
            <h2 className="title">Verify</h2>
            <div className={`input-field ${emailError ? 'error' : ''}`}>
              <i className="fas fa-envelope"></i>
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onFocus={() => setEmailError(false)}
                required
              />
            </div>
            <p className="remaining-time">Remaining Time: {Math.floor(timeRemaining / 60)} min {timeRemaining % 60} sec</p>
            <div className={`input-field ${otpError ? 'error' : ''}`}>
              <i className="fas fa-envelope"></i>
              <input
                type="text"
                placeholder="OTP"
                value={otp}
                onChange={(e) => setOTP(e.target.value)}
                onFocus={() => setOtpError(false)}
                required
              />
            </div>
            <button type="button" className="btn" onClick={handleOtpValidation}>
              Verify
            </button>
            <p className="social-text">Or Sign up with social platforms</p>
            <div className="social-media">
              <a href="#" className="social-icon">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href="#" className="social-icon">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="#" className="social-icon">
                <i className="fab fa-google"></i>
              </a>
              <a href="#" className="social-icon">
                <i className="fab fa-linkedin-in"></i>
              </a>
            </div>
            <button className="btn transparent" onClick={handleSignUpClick}>
              Back to Sign up
            </button>
          </form>
          )}
        </div>
      </div>

      <div className="panels-container">
        <div className="panel left-panel">
          <div className="content">
            <h3>New here ?</h3>
            <p>
            Your journey continues here – Sign in with us for a secure and tailored experience, just the way you like it!!!
            </p>
            <button className="btn transparent" onClick={handleSignUpClick}>
              Sign up
            </button>
          </div>
          <img src={logsvg} className="image" alt="" />
        </div>
        <div className="panel right-panel">
          <div className="content">
            <h3>One of us ?</h3>
            <p>
            Unlock a personalized experience – Login with us and discover seamless access to your account and exclusive features...!!!
            </p>
            <button className="btn transparent" onClick={handleSignInClick}>
              Sign in
            </button>
          </div>
          <img src={registersvg} className="image" alt="" />
        </div>
      </div>
    </div>
    </div>
  );
};

export default AuthForm;