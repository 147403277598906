
import '../Components/Footer.css';
import newlogo from '../Images/TripInBudget Logo New.png'
import { FaInstagram, FaLinkedin, FaXTwitter, FaYoutube } from 'react-icons/fa6';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
const Footer = () => {
    const handleButtonClick = () => {
        window.open('https://calendly.com/insecsys', '_blank');
      };
  return (
    // footer container
    <div className="footer">
        {/* footer first row */}
        <div className="footerfirstrow">
              {/* footer first row  column 1  for logo left side */}
            <div className="footerfirstrowcol1"> <img src={newlogo} alt="" className='logo1' /></div>
                  {/* footer first row  column 1  for social media icon rifht side */}
            <div className="footerfirstrowcol2">       
            <a href="https://www.instagram.com/insecsys_pvt_ltd/" target="_blank" rel="noopener noreferrer">
          <FaInstagram  className='footericon1' />
        </a>
        <a href="https://www.linkedin.com/search/results/all/?heroEntityKey=urn%3Ali%3Aorganization%3A10864106&keywords=InSecSys&origin=ENTITY_SEARCH_HOME_HISTORY&sid=Y!k" target="_blank" rel="noopener noreferrer">
          <FaLinkedin style={{ color: '#0077B5' }} className='footericon' />
        </a>
        <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
          <FaXTwitter style={{ color: '#1DA1F2' }} className='footericon' />
        </a>
        <a href="https://www.youtube.com/@insecsys9452" target="_blank" rel="noopener noreferrer">
          <FaYoutube style={{ color: '#FF0000' }} className='footericon' />
        </a>
        </div>
        </div>
           {/* footer first row  End*/}
        {/* footer second row */}
        <div className="footersecondrow" >
              {/* footer second row  column 1 for iso stamp and company description*/}
        <div className="footersecondrowcol1">

            <p style={{color:'white',fontSize:'0.8rem',paddingTop:'10px'}}>At TripInBudget, we understand that planning and budgeting for trips can be a challenging task, especially when it comes to managing expenses and meeting expectations. Traveling is a vital component of organizational growth, and we are here to optimize your travel experience while staying within your budget.</p>
        </div>
          {/* footer second row  column 1 end */}
        {/* footer second row  column 2 comapany list */}
        <div className="footersecondrowcol2">
        <ul class="box">
          <li class="link_name">Company</li>
          <li ><a href="/whyprivacypro" style={{color:'white'}}>About Us</a></li>
          <li ><a href="/contactus" style={{color:'white'}}>Contact Us</a></li>
          <li ><a href="#" style={{color:'white'}}>Blog</a></li>
          <li ><a href="#" style={{color:'white'}}>Get Started</a></li>
        </ul>
        </div>
           {/* footer second row  column 2 end */}
           {/* footer second row  column 3 Services list */}
        <div className="footersecondrowcol3">
        
        </div>
         {/* footer second row  column 3 end */}
          {/* footer second row  column 4 supports list */}
        <div className="footersecondrowcol4">
        <ul class="box">
        <li class="link_name">Supports</li>
          <li><a href="#" style={{color:'white'}}>Help Center</a></li>
          <li><a href="/terms-of-service" style={{color:'white'}} target='_blank'>Terms of Service</a></li>
          <li><a href="/privacy-policy" style={{color:'white'}} target='_blank'>Privacy Policy</a></li>
          <li><a href="#" style={{color:'white'}}>Status</a></li>
        </ul>
        </div>
           {/* footer second row  column 4 end */}
           {/* footer second row  column 5 get in touch address and schedule button */}
        <div className="footersecondrowcol5">
        <ul class="box input-box">
         
         <li class="link_name">Get In Touch</li>
         <p className='footertitle'>
           <LocationOnIcon style={{fontSize:'1.2rem',}}/>A-1003 Gagan Arena,NIBM -Corinthians Road,Undri, Pune 411060</p>
           <p className='footertitle'>
            
              <PhoneForwardedIcon style={{fontSize:'1.2rem',}}/> +91 7447447990

           </p>
           <p className='footertitle'>
        
             <MarkEmailReadIcon style={{fontSize:'1.2rem',}}/> service@tripinbudget.in
           </p>
         <li><button className='navbarbutton' onClick={handleButtonClick}>Book Ticket Now</button></li>
       </ul>
        </div>
      </div>
      {/* footer second row  column 5 end */}
      {/* footer thirst row */}
      <div className="footerthirdrow">
              {/* footer thirst row  column */}
            <div className="footerthirdrowcol1"> </div>
            <div class="bottom_text">
        <span class="copyright_text">Copyright © 2023 TripInBudget. | All rights reserved</span>
        <span class="policy_terms">
          <a>Privacy policy
          Terms & Condition</a>
        </span>
      </div>
        </div>
       
    </div>
  );
};

export default Footer;

