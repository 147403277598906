import React, { useState, useEffect } from 'react';
import axios from 'axios';

function AirportCitySearch() {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    const fetchAirportCityData = async () => {
      try {
        const response = await axios.get(
          `https://test.api.amadeus.com/v1/reference-data/locations?subType=CITY&keyword=${searchTerm}&page[limit]=10&page[offset]=0&sort=analytics.travelers.score&view=FULL`,
          {
            headers: {
              Authorization: 'Bearer z1spfz5OA8C5fpEIejGNAlvHUHX9'
            }
          }
        );
        setSearchResults(response.data.data);
      } catch (error) {
        console.error('Error fetching data:', error.message);
      }
    };

    if (searchTerm) {
      fetchAirportCityData();
    }
  }, [searchTerm]);

  return (
    <div style={{ padding: '20px' }}>
      <h2>Airport and City Search</h2>
      <input
        type="text"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder="Enter search term"
      />
      <table style={{ marginTop: '20px' }}>
        <thead>
          <tr>
            <th>Name</th>
            <th>IATA code</th>
            <th>City</th>
            <th>Country</th>
            <th>Country Code</th>
          </tr>
        </thead>
        <tbody>
          {searchResults.map((item) => (
            <tr key={item.id}>
              <td>{item.name}</td>
              <td>{item.iataCode}</td>
              <td>{item.address.cityName}</td>
              <td>{item.address.countryName}</td>
              <td>{item.address.countryCode}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default AirportCitySearch;
