// import React, { useState } from 'react';
// import axios from 'axios';

// function FlightAvailable() {
//   const [originLocationCode, setOriginLocationCode] = useState('');
//   const [destinationLocationCode, setDestinationLocationCode] = useState('');
//   const [departureDate, setDepartureDate] = useState('');
//   const [response, setResponse] = useState(null);

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const res = await axios.post('http://localhost:3001/flight-availabilities', {
//         originLocationCode,
//         destinationLocationCode,
//         departureDate
//       });
//       setResponse(res.data);
//     } catch (error) {
//       console.error('Error:', error.message);
//     }
//   };

//   return (
//     <div style={{padding:'109px'}}>
//       <h1>Flight Availability</h1>
//       <form onSubmit={handleSubmit}>
//         <div>
//           <label>Origin Location Code:</label>
//           <input type="text" value={originLocationCode} onChange={(e) => setOriginLocationCode(e.target.value.toUpperCase())} />
//         </div>
//         <div>
//           <label>Destination Location Code:</label>
//           <input type="text" value={destinationLocationCode} onChange={(e) => setDestinationLocationCode(e.target.value.toUpperCase())} />
//         </div>
//         <div>
//           <label>Departure Date:</label>
//           <input type="text" value={departureDate} onChange={(e) => setDepartureDate(e.target.value)} />
//         </div>
//         <button type="submit">Search</button>
//       </form>
//       {response && (
//         <div id="results" className="mt-4">
//           <h2>Flight Availabilities:</h2>
//           <table className="table table-bordered" id="customers">
//             <thead>
//               <tr>
//                <th>Flight Number</th>
//                <th>carrierCode</th>
//                <th>aircraft code</th>
//                 <th>Departure Location</th>
//                 <th>Departure Date time</th>
//                 <th>Departure terminal</th>
//                 <th>Arrival Location</th>
//                 <th>Arrival Date time</th>
//                 <th>Arrival Terminal</th>
//                 <th>Total Duration</th>
//                 <th>No of Stops</th>
//               </tr>
//             </thead>
//             <tbody>
//               {response.data.map(flight => (
//                 <tr key={flight.id}>
//                    <td>{flight.segments[0].number}</td>
//                    <td>{flight.segments[0].carrierCode}</td>
//                    <td>{flight.segments[0].aircraft.code}</td>
//                   <td>{flight.segments[0].departure.iataCode}</td>
//                   <td>{flight.segments[0].departure.at}</td>
//                   <td>{flight.segments[0].departure.terminal}</td>
//                   <td>{flight.segments[0].arrival.iataCode}</td>
//                   <td>{flight.segments[0].arrival.at}</td>
//                   <td>{flight.segments[0].arrival.terminal}</td>
//                   <td>{flight.duration}</td>
//                   <td>{flight.segments[0].numberOfStops}</td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>
//       )}
//     </div>
//   );
// }

// export default FlightAvailable;

// import React, { useState } from 'react';
// import axios from 'axios';

// function FlightAvailable() {
//   const [originLocationCode, setOriginLocationCode] = useState('');
//   const [destinationLocationCode, setDestinationLocationCode] = useState('');
//   const [departureDate, setDepartureDate] = useState('');
//   const [response, setResponse] = useState(null);

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const res = await axios.post('http://localhost:3001/flight-availabilities', {
//         originLocationCode,
//         destinationLocationCode,
//         departureDate
//       });
//       setResponse(res.data);
//     } catch (error) {
//       console.error('Error:', error.message);
//     }
//   };

//   return (
//     <div style={{ padding: '20px' }} >
//       <h1>Flight Availability</h1>
//       d
//       <form onSubmit={handleSubmit}>
//         <div>
//           <label>Origin Location Code:</label>
//           <input type="text" value={originLocationCode} onChange={(e) => setOriginLocationCode(e.target.value.toUpperCase())} />
//         </div>
//         <div>
//           <label>Destination Location Code:</label>
//           <input type="text" value={destinationLocationCode} onChange={(e) => setDestinationLocationCode(e.target.value.toUpperCase())} />
//         </div>
//         <div>
//           <label>Departure Date:</label>
//           <input type="text" value={departureDate} onChange={(e) => setDepartureDate(e.target.value)} />
//         </div>
//         <button type="submit">Search</button>
//       </form>
      // {response && (
      //   <div className="mt-4">
      //     <h2>Flight Availabilities:</h2>
      //     <div style={{ display: 'flex', flexWrap: 'wrap'}}>
      //       {response.data.map(flight => (
      //         <div key={flight.id} style={{ border: '1px solid navy', borderRadius: '5px', padding: '10px', margin: '10px', width: '600px' ,display:'flex',flexWrap:'wrap',backgroundColor:'white' }}>
      //           <div className="flexcol" style={{flex:'1',margin:'5px'}}>
      //             <p>{flight.segments[0].departure.iataCode}</p>
      //             <p>{flight.segments[0].number}</p>
  
      //           </div>
      //           <div className="flexcol" style={{flex:'1',margin:'5px'}}>
      //             <p>{flight.duration}</p>
      //             <p>{flight.segments[0].number}</p>
  
      //           </div>
      //           <div className="flexcol" style={{flex:'1',margin:'5px'}}>
      //             <p>{flight.segments[0].departure.at}</p>
      //             <p>{flight.segments[0].arrival.at}</p>
  
      //           </div>
      //           <div className="flexcol" style={{flex:'1',margin:'5px'}}>
      //             <p>{flight.segments[0].departure.at}</p>
      //             <p>{flight.segments[0].arrival.at}</p>
  
      //           </div>
                
                
      //         </div>
      //       ))}
//           </div>
//         </div>
//       )}
//     </div>
//   );
// }

// export default FlightAvailable;


// all code work with simple input citycode and date and all card printing
// import React, { useState } from 'react';
// import axios from 'axios';

// function FlightAvailable() {
//   const [originLocationCode, setOriginLocationCode] = useState('');
//   const [destinationLocationCode, setDestinationLocationCode] = useState('');
//   const [departureDate, setDepartureDate] = useState('');
//   const [response, setResponse] = useState(null);

//   const handleSubmit = async () => {
//     try {
//       const res = await axios.post('http://localhost:3001/flight-availabilities', {
//         originLocationCode,
//         destinationLocationCode,
//         departureDate
//       });
//       setResponse(res.data);
//     } catch (error) {
//       console.error('Error:', error.message);
//     }
//   };

//   return (
//     <div >
//       <div className="dummy2" ></div>
//       <div className='availablecontainer'>
//         <div className="availablecontainerrow">
//           <div className="flightcol">
//             <label className="form-label">Origin Location Code:</label>
//             <input className="form-control" type="text" value={originLocationCode} onChange={(e) => setOriginLocationCode(e.target.value.toUpperCase())} />
//           </div>
//           <div className="flightcol">
//             <label className="form-label">Destination Location Code:</label>
//             <input className="form-control" type="text" value={destinationLocationCode} onChange={(e) => setDestinationLocationCode(e.target.value.toUpperCase())} />
//           </div>
//           <div className="flightcol">
//             <label className="form-label">Departure Date:</label>
//             <input className="form-control" type="date" value={departureDate} onChange={(e) => setDepartureDate(e.target.value)} />
//           </div>
//           <div className="flightcol">
//             <button className="btn btn-primary" onClick={handleSubmit}>Search </button>
//           </div>
//         </div>
//       </div>
//       {response && (
//         <div className="mt-4">
//           <h2>Flight Availabilities:</h2>
//           <div style={{ display: 'flex', flexWrap: 'wrap'}}>
//             {response.data.map(flight => (
//               <div key={flight.id} style={{ border: '1px solid navy', borderRadius: '5px', padding: '10px', margin: '10px', width: '600px' ,display:'flex',flexWrap:'wrap',backgroundColor:'white' }}>
//                 <div className="flexcol" style={{flex:'1',margin:'5px'}}>
//                   <p>{flight.segments[0].departure.iataCode}</p>
//                   <p>{flight.segments[0].number}</p>
//                 </div>
//                 <div className="flexcol" style={{flex:'1',margin:'5px'}}>
//                   <p>{flight.duration}</p>
//                   <p>{flight.segments[0].number}</p>
//                 </div>
//                 <div className="flexcol" style={{flex:'1',margin:'5px'}}>
//                   <p>{flight.segments[0].departure.at}</p>
//                   <p>{flight.segments[0].arrival.at}</p>
//                 </div>
//                 <div className="flexcol" style={{flex:'1',margin:'5px'}}>
//                   <p>{flight.segments[0].departure.at}</p>
//                   <p>{flight.segments[0].arrival.at}</p>
//                 </div>
//               </div>
//             ))}
//           </div>
//         </div>
//       )}
//     </div>
//   );
// }

// export default FlightAvailable;


// this code work with react select and date 
/*
import React, { useState } from 'react';
import axios from 'axios';
import Select from 'react-select';

function FlightAvailable() {
  const [originLocationCode, setOriginLocationCode] = useState('');
  const [destinationLocationCode, setDestinationLocationCode] = useState('');
  const [departureDate, setDepartureDate] = useState('');
  const [response, setResponse] = useState(null);

  const originOptions = [
    { value: 'PNQ', label: 'Pune (PNQ)' },
    { value: 'BOM', label: 'Mumbai (BOM)' },
    { value: 'DEL', label: 'Delhi (DEL)' }
  ];

  const destinationOptions = [
    { value: 'PNQ', label: 'Pune (PNQ)' },
    { value: 'BOM', label: 'Mumbai (BOM)' },
    { value: 'DEL', label: 'Delhi (DEL)' }
  ];

  const handleSubmit = async () => {
    try {
      const res = await axios.post('http://localhost:3001/flight-availabilities', {
        originLocationCode: originLocationCode.value,
        destinationLocationCode: destinationLocationCode.value,
        departureDate
      });
      setResponse(res.data);
    } catch (error) {
      console.error('Error:', error.message);
    }
  };

  return (
    <div>
      <div className="dummy2"></div>
      <div className='availablecontainer'>
        <div className="availablecontainerrow">
          <div className="flightcol">
            <label className="form-label">Origin Location Code:</label>
            <Select
              options={originOptions}
              value={originLocationCode}
              onChange={setOriginLocationCode}
            />
          </div>
          <div className="flightcol">
            <label className="form-label">Destination Location Code:</label>
            <Select
              options={destinationOptions}
              value={destinationLocationCode}
              onChange={setDestinationLocationCode}
            />
          </div>
          <div className="flightcol">
            <label className="form-label">Departure Date:</label>
            <input className="form-control" type="date" value={departureDate} onChange={(e) => setDepartureDate(e.target.value)} />
          </div>
          <div className="flightcol">
            <button className="btn btn-primary" onClick={handleSubmit}>Search</button>
          </div>
        </div>
      </div>
      {response && (
        <div className="mt-4">
          <h2>Flight Availabilities:</h2>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {response.data.map(flight => (
              <div key={flight.id} style={{ border: '1px solid navy', borderRadius: '5px', padding: '10px', margin: '10px', width: '600px', display: 'flex', flexWrap: 'wrap', backgroundColor: 'white' }}>
                <div className="flexcol" style={{ flex: '1', margin: '5px' }}>
                  <p>{flight.segments[0].departure.iataCode}</p>
                  <p>{flight.segments[0].number}</p>
                </div>
                <div className="flexcol" style={{ flex: '1', margin: '5px' }}>
                  <p>{flight.duration}</p>
                  <p>{flight.segments[0].number}</p>
                </div>
                <div className="flexcol" style={{ flex: '1', margin: '5px' }}>
                  <p>{flight.segments[0].departure.at}</p>
                  <p>{flight.segments[0].arrival.at}</p>
                </div>
                <div className="flexcol" style={{ flex: '1', margin: '5px' }}>
                  <p>{flight.segments[0].departure.at}</p>
                  <p>{flight.segments[0].arrival.at}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default FlightAvailable;*/


// import React, { useState,useEffect } from 'react';
// import axios from 'axios';
// import Select from 'react-select';

// function FlightAvailable() {
//   // const [originLocationCode, setOriginLocationCode] = useState('');
//   const [destinationLocationCode, setDestinationLocationCode] = useState('');
//   const [departureDate, setDepartureDate] = useState('');
//   const [response, setResponse] = useState(null);
//   const [searchTermTo, setSearchTermTo] = useState('');
//   const [destinationOptions, setDestinationOptions] = useState([]);
//   const [errorTo, setErrorTo] = useState(null);
//   const [searchTerm, setSearchTerm] = useState('');
//   const [originOptions, setOriginOptions] = useState([]);
//   const [originLocationCode, setoriginLocationCode] = useState(null);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         // First API for searching by name
//         const responseName = await axios.get('https://airports15.p.rapidapi.com/airports', {
//           params: { name: searchTerm },
//           headers: {
//             'X-RapidAPI-Key': 'baa5306601msh44eade617a3c3d1p188b0djsn9f7772669cc0',
//             'X-RapidAPI-Host': 'airports15.p.rapidapi.com',
//           },
//         });
//         // Second API for searching by IATA code
//         const responseIata = await axios.get('https://airport-info.p.rapidapi.com/airport', {
//           params: { iata: searchTerm },
//           headers: {
//             'X-RapidAPI-Key': 'baa5306601msh44eade617a3c3d1p188b0djsn9f7772669cc0',
//             'X-RapidAPI-Host': 'airport-info.p.rapidapi.com',
//           },
//         });
//         // Combine the results from both APIs
//         const options = [
//           ...responseName.data.map((airport) => ({
//             value: airport.iataCode,
//             label: `${airport.iataCode} - ${airport.city}`,
//           })),
//           {
//             value: responseIata.data.iata,
//             label: `${responseIata.data.iata} - ${responseIata.data.city}`,
//           },
//         ];
//         setOriginOptions(options);
//         setError(null);
//       } catch (error) {
//         setOriginOptions([]);
//         setError(error.message);
//       }
//     };
//     if (searchTerm) {
//       fetchData();
//     }
//   }, [searchTerm]);

//   useEffect(() => {
//     const fetchDestinationData = async () => {
//       try {
//         // First API for searching by name
//         const responseName = await axios.get('https://airports15.p.rapidapi.com/airports', {
//           params: { name: searchTermTo },
//           headers: {
//             'X-RapidAPI-Key': 'baa5306601msh44eade617a3c3d1p188b0djsn9f7772669cc0',
//             'X-RapidAPI-Host': 'airports15.p.rapidapi.com',
//           },
//         });
//         // Second API for searching by IATA code
//         const responseIata = await axios.get('https://airport-info.p.rapidapi.com/airport', {
//           params: { iata: searchTermTo },
//           headers: {
//             'X-RapidAPI-Key': 'baa5306601msh44eade617a3c3d1p188b0djsn9f7772669cc0',
//             'X-RapidAPI-Host': 'airport-info.p.rapidapi.com',
//           },
//         });
//         // Combine the results from both APIs
//         const OptionsTo = [
//           ...responseName.data.map((airportTo) => ({
//             value: airportTo.iataCode,
//             label: `${airportTo.iataCode} - ${airportTo.city}`,
//           })),
//           {
//             value: responseIata.data.iata,
//             label: `${responseIata.data.iata} - ${responseIata.data.city}`,
//           },
//         ];
//         setDestinationOptions(OptionsTo);
//         setError(null);
//       } catch (error) {
//         setDestinationOptions([]);
//         setError(error.message);
//       }
//     };
//     if (searchTermTo) {
//       fetchDestinationData();
//     }
//   }, [searchTermTo]);

//   const handleSubmit = async () => {
//     try {
//       const res = await axios.post('http://localhost:3001/flight-availabilities', {
//         originLocationCode: originLocationCode.value,
//         destinationLocationCode: destinationLocationCode.value,
//         departureDate
//       });
//       setResponse(res.data);
//     } catch (error) {
//       console.error('Error:', error.message);
//     }
//   };

//   return (
//     <div>
//       <div className="dummy2"></div>
//       <div className='availablecontaineronly'>
//         <div className="availablecontainerrowonly">
//           <div className="flightcol">
//             <label className="form-label">Origin Location Code:</label>
//             <Select
//         options={originOptions}
//         value={originLocationCode}
//         onChange={(selectedOption) => setoriginLocationCode(selectedOption)}
//         onInputChange={(inputValue) => setSearchTerm(inputValue)}
//         placeholder="Enter airport"
//       />
//           </div>
//           <div className="flightcol">
//             <label className="form-label">Destination Location Code:</label>
//             <Select
//         options={destinationOptions}
//         value={destinationLocationCode}
//         onChange={(selectedOption) => setDestinationLocationCode(selectedOption)}
//         onInputChange={(inputValueTo) => setSearchTermTo(inputValueTo)}
//         placeholder="Enter airport"
//       />
//           </div>
//           <div className="flightcol">
//             <label className="form-label">Departure Date:</label>
//             <input className="form-control" type="date" value={departureDate} onChange={(e) => setDepartureDate(e.target.value)} />
//           </div>
//           <div className="flightcol">
//             <button className="btn btn-primary" onClick={handleSubmit}>Search</button>
//           </div>
//         </div>
//       </div>
//       {response && (
//         <div className="mt-4" style={{padding:'50px'}}>
//           <h2>Flight Availabilities:</h2>
//           <div style={{ display: 'flex', flexWrap: 'wrap' }}>
//             {response.data.map(flight => (
//               <div key={flight.id} style={{ border: '1px solid navy', borderRadius: '5px', padding: '10px', margin: '10px', width: '600px', display: 'flex', flexWrap: 'wrap', backgroundColor: 'white' }}>
//                 <div className="flexcol" style={{ flex: '1', margin: '5px' }}>
//                   <p>{flight.segments[0].departure.iataCode}</p>
//                   <p>{flight.segments[0].number}</p>
//                 </div>
//                 <div className="flexcol" style={{ flex: '1', margin: '5px' }}>
//                   <p>{flight.duration}</p>
//                   <p>{flight.segments[0].number}</p>
//                 </div>
//                 <div className="flexcol" style={{ flex: '1', margin: '5px' }}>
//                   <p>{flight.segments[0].departure.at}</p>
//                   <p>{flight.segments[0].arrival.at}</p>
//                 </div>
//                 <div className="flexcol" style={{ flex: '1', margin: '5px' }}>
//                   <p>{flight.segments[0].departure.at}</p>
//                   <p>{flight.segments[0].arrival.at}</p>
//                 </div>
//               </div>
//             ))}
//           </div>
//         </div>
//       )}
//       <div className="dummy2"></div>
//     </div>
//   );
// }

// export default FlightAvailable;



// FlightAvailable.jsx

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Select from 'react-select';

function FlightAvailable() {
  const [destinationLocationCode, setDestinationLocationCode] = useState('');
  const [departureDate, setDepartureDate] = useState('');
  const [searchTermTo, setSearchTermTo] = useState('');
  const [destinationOptions, setDestinationOptions] = useState([]);
  const [errorTo, setErrorTo] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [originOptions, setOriginOptions] = useState([]);
  const [originLocationCode, setOriginLocationCode] = useState(null);
  const [error, setError] = useState(null);
  const [travelerType, setTravelerType] = useState('ADULT');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // First API for searching by name for arrival airport location code
        const responseName = await axios.get('https://airports15.p.rapidapi.com/airports', {
          params: { name: searchTerm },
          headers: {
            'X-RapidAPI-Key': 'baa5306601msh44eade617a3c3d1p188b0djsn9f7772669cc0',
            'X-RapidAPI-Host': 'airports15.p.rapidapi.com',
          },
        });
        // Second API for searching by IATA code  for arrival airport location code
        const responseIata = await axios.get('https://airport-info.p.rapidapi.com/airport', {
          params: { iata: searchTerm },
          headers: {
            'X-RapidAPI-Key': 'baa5306601msh44eade617a3c3d1p188b0djsn9f7772669cc0',
            'X-RapidAPI-Host': 'airport-info.p.rapidapi.com',
          },
        });
        // Combine the results from both APIs in options formate it will show inside dropdown
        const options = [
          ...responseName.data.map((airport) => ({
            value: airport.iataCode,
            label: `${airport.iataCode} - ${airport.city}`,
          })),
          {
            value: responseIata.data.iata,
            label: `${responseIata.data.iata} - ${responseIata.data.city}`,
          },
        ];
        setOriginOptions(options);
        setError(null);
      } catch (error) {
        setOriginOptions([]);
        setError(error.message);
      }
    };
    if (searchTerm) {
      fetchData();
    }
  }, [searchTerm]);

  useEffect(() => {
    //useeffect for fetching the iata airport code by searching airportname and iata code
    const fetchDestinationData = async () => {
      try {
        // First API for searching by name for destination location code
        const responseName = await axios.get('https://airports15.p.rapidapi.com/airports', {
          params: { name: searchTermTo },
          headers: {
            'X-RapidAPI-Key': 'baa5306601msh44eade617a3c3d1p188b0djsn9f7772669cc0',
            'X-RapidAPI-Host': 'airports15.p.rapidapi.com',
          },
        });
        // Second API for searching by IATA code for destination location code
        const responseIata = await axios.get('https://airport-info.p.rapidapi.com/airport', {
          params: { iata: searchTermTo },
          headers: {
            'X-RapidAPI-Key': 'baa5306601msh44eade617a3c3d1p188b0djsn9f7772669cc0',
            'X-RapidAPI-Host': 'airport-info.p.rapidapi.com',
          },
        });
        // Combine the results from both APIs
        const OptionsTo = [
          ...responseName.data.map((airportTo) => ({
            value: airportTo.iataCode,
            label: `${airportTo.iataCode} - ${airportTo.city}`,
          })),
          {
            value: responseIata.data.iata,
            label: `${responseIata.data.iata} - ${responseIata.data.city}`,
          },
        ];
        setDestinationOptions(OptionsTo);
        setError(null);
      } catch (error) {
        setDestinationOptions([]);
        setError(error.message);
      }
    };
    if (searchTermTo) {
      fetchDestinationData();
    }
  }, [searchTermTo]);
  const handleSubmit = async () => {
    try {
      // Redirect to results page with query parameters inside the url
      navigate(`/flightavailable/result?origin=${originLocationCode.value}&destination=${destinationLocationCode.value}&date=${departureDate}&travelerType=${travelerType}`);
    } catch (error) {
      console.error('Error:', error.message);
    }
  };
  return (
    <div>
      <div className="dummy2"></div>
      <div className='availablecontainer'>
        <div className="availablecontainerrow">
          <div className="flightcol">
            <label className="form-label">Origin Location Code:</label>
            <Select
              options={originOptions}
              value={originLocationCode}
              onChange={(selectedOption) => setOriginLocationCode(selectedOption)}
              onInputChange={(inputValue) => setSearchTerm(inputValue)}
              placeholder="Enter airport"
            />
          </div>
          <div className="flightcol">
            <label className="form-label">Destination Location Code:</label>
            <Select
              options={destinationOptions}
              value={destinationLocationCode}
              onChange={(selectedOption) => setDestinationLocationCode(selectedOption)}
              onInputChange={(inputValueTo) => setSearchTermTo(inputValueTo)}
              placeholder="Enter airport"
            />
          </div>
          <div className="flightcol">
            <label className="form-label">Departure Date:</label>
            <input className="form-control" type="date" value={departureDate} onChange={(e) => setDepartureDate(e.target.value)} />
          </div>
          <div className="flightcol">
  <label className="form-label">Traveler Type:</label>
  <Select
    options={[
      { value: 'ADULT', label: 'Adult' },
    ]}
    value={{ value: travelerType, label: travelerType }} // Set the value and label
    onChange={(selectedOption) => setTravelerType(selectedOption.value)} // Set the selected value
    placeholder="Select traveler type"
  />
</div>
          <div className="flightcol">
            <button className="btn btn-primary" onClick={handleSubmit}>Search</button>
          </div>
        </div>
      </div>
      <div className="dummy2"></div>
    </div>
  );
}

export default FlightAvailable;


