// src/TrainFinder.js
import React, { useState } from 'react';
import axios from 'axios';

const TrainFinder = () => {
  const [fromStationCode, setFromStationCode] = useState('');
  const [toStationCode, setToStationCode] = useState('');
  const [trains, setTrains] = useState([]);
  const [error, setError] = useState('');

  const handleSearch = async () => {
    try {
      const response = await axios.get('https://irctc1.p.rapidapi.com/api/v3/trainBetweenStations', {
        params: {
          fromStationCode,
          toStationCode,
          dateOfJourney: '<REQUIRED>' // You need to set the date here
        },
        headers: {
          'X-RapidAPI-Key': '8a2ad9e348mshe973b086afb7089p1356afjsnffd752f96e57',
          'X-RapidAPI-Host': 'irctc1.p.rapidapi.com'
        }
      });

      setTrains(response.data.data);
    } catch (error) {
      setError('Error fetching data');
      console.error(error);
    }
  };

  return (
    <div style={{padding:'100px'}}>
      <h1>Train Finder</h1>
      <div>
        <label htmlFor="fromStation">From Station:</label>
        <input
          type="text"
          id="fromStation"
          value={fromStationCode}
          onChange={(e) => setFromStationCode(e.target.value)}
        />
      </div>
      <div>
        <label htmlFor="toStation">To Station:</label>
        <input
          type="text"
          id="toStation"
          value={toStationCode}
          onChange={(e) => setToStationCode(e.target.value)}
        />
      </div>
      <button onClick={handleSearch}>Search</button>

      {error && <p>{error}</p>}

      <ul>
        {trains.map((train) => (
          <li key={train.train_number}>{train.train_name}</li>
        ))}
      </ul>
    </div>
  );
};

export default TrainFinder;
