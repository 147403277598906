import React from 'react'
import './terms.css'

export default function PrivacyPolicy() {
  return (
 <div className="privacy-container">
   <div className="privacy-main">
    <h2>Privacy Policy</h2>
    <p>This Privacy Policy was last updated on 17th May 2021.</p>

    <p>tripinbudget.in, including all legal entities affiliated to the company (hereafter referred to as "Company" or "we"), respects your (customers - hereafter referred as "you" or "user") privacy and values our relationship with our business partners, clients, third parties having contractual relationship with Company (hereafter referred to as "third party" or "service provider"), and any party who purchases/intend to purchase/inquire about any product(s) and/or service(s) made available by Company through any of Company' s customer interface channels including Website, mobile site, mobile app & offline channels including call centers and offices (collectively referred herein as "Sales Channels"). We are committed to protecting your personally identifiable information (hereafter referred as "information" or "personal information" or "personal data" or "special category of personal data") by handling it responsibly and safeguarding it using appropriate technical and organizational measures.</p>
   <p>We want to assure you that we follow appropriate standards when it comes to protecting your privacy on our Sales Channel. This policy outlines the types of personal information collected on our Website, how this data is used/processed and safeguarded as well as how users may exercise their rights with respect to personal information.</p>
   <p>This Privacy Policy applies to https://tripinbudget.com, Company pages, communications and services ("Services"), including off-site Services, such as our email services, customer care and support services and the "Contact us"; "Apply with Company" and "Share on" plugins on our digital properties.</p>
   <p>By using or accessing the Website or other Sales Channels, the user hereby agrees with the terms of this Privacy Policy and the contents herein.</p>
   <p>This Privacy Policy does not apply to any website(s), mobile sites and mobile apps of third parties, even if their websites/products are linked to our Website. User should take note that the information and privacy practices of Company's business partners, advertisers, sponsors or other sites to which we provide hyperlink(s), may be materially different from this Privacy Policy. Accordingly, it is recommended that you review the privacy statements and policies of any such third parties.</p>
   <p>In case you book travel services on behalf of your family or a minor, you agree:
    <ul>
        <li>To inform the individual about the content of this privacy policy</li>
        <li>To obtain any legally-required consent, for the collection, use, disclosure, and transfer (including cross-border transfer) of individual's personal information, from the individual in accordance with the legal and regulatory requirements</li>
    </ul>
   </p>
   <p>In such a scenario, Company will be acting as data processors while your respective employer, contractor, business partner, affiliate shall be acting as the data controller. It will be the obligation of the data controller to seek consent of the end customer.</p>
   <p>1. What Personal Data shall we collect?
<p>a. We collect the following personal and sensitive information from you when you transact through us:
    <ul>
        <li>
        Name, gender, age and date of birth
        </li>
        <li>Contact information such as address, email, and mobile phone number</li>
        <li>Country of residence</li>
        <li>Payment Details like Credit Card/ Debit Card details along with their expiry date (billing address in case of international debit/credit card).</li>
        <li>Passport Number & frequent flyer number (wherever needed)</li>
        <li>Lifestyle and social circumstances (for example, your hobbies)</li>
        <li>Family details/information (for example, your marital status and dependents)</li>
        <li>Postings or messages on any blogs, forums, platforms, wikis or social media applications of Company and Company' s third parties</li>
        <li>IP address, browser type and language, your access time</li>
        <li>Information in any complaints you make</li>
        <li>Details of how you use our products and services</li>
        <li>Recording of telephone calls to our representatives and call centers</li>
        <li>Details of how you like to interact with us (through email, SMS or whatsapp) and other similar information relevant to our relationship</li>

    </ul>
    <p>The personal data we collect may also include 'sensitive' or 'special categories' of personal data such as details about your:</p>
    <ul>
        <li>Dietary requirements (for example, while providing your meal preference during flight/ hotel bookings)</li>
        <li>Health (so that we can make it easy for you to access our buildings, products and services, and/or any special assistance required by you)</li>
        <li>Sexual orientation (if you provide us with details of your spouse or partner)</li>
    </ul>
    <p>If you choose not to provide, or object to us processing the information we collect, we may not be able to process your instructions or continue to provide some or all of our services to you (including our clients)Personal information of other customers/users/travelers</p>
    <p>If you provide personal information to us regarding individuals other than yourself, you agree</p>
    <ul>
        <li>To inform the individual about the content of this privacy policy</li>
        <li>To obtain any legally-required consent, for the collection, use, disclosure, and transfer (including cross-border transfer) of individual's personal information, from the individual in accordance with the legal and regulatory requirements</li>
    </ul>
    <p>c. Personal information of child</p>
    <p>The Services are not intended for users under the age of 16 (Permissible Age), unless permitted under applicable local laws. We do not knowingly collect any personal information from you or the market to solicit information from anyone under the Permissible Age. If we become aware that a person submitting personal information is under the Permissible Age, we will delete the account and any related information immediately. If you believe we might have any information from or about you under the Permissible Age, please contact us at <a href="">service@tripinbudget.com</a>
    <p>d. We use third party services/bots to capture the following additional information:t</p>
    <ul>
        <li>Analytics</li>
        <li>Infrastructure monitoring Geographic position, unique device identifiers for advertising</li>
        <li>Location-based interactions: Geographical locations</li>
        <li>Unique device identification: This Application may track Users by storing a unique identifier of their device, for analytics purposes or for storing Users' preferences.</li>
    </ul>
</p>
</p>
<p>2. How do we collect Personal Data?</p>
<p>a.Directly: We obtain personal data directly from individuals in a variety of ways , including but not limited to obtaining personal data from individuals who provide their personal information via registrations or guest account for booking travel services, their business card, complete our online forms on our website/ mobile app, provide records relating to previous requests or reservations made, Individuals who subscribe to our newsletters and preference center, register for webinars, and attend meetings or events we host or visit our offices. We may also obtain personal data directly when we are establishing a business relationship, performing professional service through a contract, or through our hosted software applications.
<br />
b. Indirectly: We obtain personal data indirectly about individuals from a variety of sources, which includes but is not limited to travel agents, other travel websites, social media, authorized representative from whom you seek travel services on behalf of others and our clients. We may add personal data to our customer relationship management records to better understand and serve you, or to satisfy a legal obligation.
</p>
<p>
d. Right to Processing restrictions - You may ask us to temporarily restrict our processing of your personal data if you contest the accuracy of your personal data, prefer to restrict its use rather than having us erase it, or need us to preserve it for you to establish, exercise, or defend a legal claim. A temporary restriction may apply while verifying whether we have overriding legitimate grounds to process it. You can ask us to inform you before we lift that temporary processing restriction.
<br />
e. Right to Data portability - You may request portability of your personal data, which shall be limited to the transfer of information pertaining to the customer profile and transaction records, to another data controller.
<br />
f. Automated Individual Decision-making - You may ask us to review any decisions made about you which we made solely based on automated processing, including profiling, that produced legal effects concerning you or significantly affecting you.
<br />
g. Right to Object to Direct Marketing including Profiling - You may object to our use of your personal data for direct marketing purposes, including profiling. We may need to keep some minimal information to comply with your request to cease marketing to you.
<br />
h. Right to Withdraw Consent - You may withdraw your consent that you have previously given to one or more specified purposes to process your personal data. This will not affect the lawfulness of any processing carried out before you withdraw your consent. However, this may lead to Company not able to provide certain products or services to you.
<br />
We may need to request specific information from you to help us confirm your identity and ensure your right to access the information or to exercise any of your other rights. This helps us to ensure that personal data is not disclosed to any person who has no right to receive it. No fee is required to make a request unless your request is clearly unfounded or excessive. Depending on the circumstances, we may be unable to comply with your request based on other lawful grounds.
<br />
9. How long shall we retain/ dispose of your Personal Data?
<br />
We seek to ensure that we only keep your personal data for the longest of:
<br />
a. the period necessary for the relevant activity or services
<br />
b. any retention period that is required by law
<br />
c. the period in which litigation or investigations might arise in respect of the services
<br />
<b>Please note: </b>Even after your account is terminated, we retain your transactional data for as long as we have a legitimate purpose to do so (and in accordance with applicable law), including to assist with legal obligations, resolve disputes, and enforce our agreements. We may retain and disclose such data pursuant to this Privacy Policy after your account has been terminated.
</p>
<p>
10. Changes to this privacy statement:
<br />
From time to time we may update this statement. When we do, we will publish the changes on this Website. If you do not agree with these changes, you can discontinue your use of our Website.
<br />
11. How to contact us, request access to your personal data or contact us with questions or feedback?
<br />
If you have a question, comment, or complaint or wish to access a copy of your personal data or to correct it if you believe it is inaccurate, you may contact us at <a href="" style={{textDecoration:'none'}}>service@tripinbudget.com</a>
</p>
   </p>

   <p>
   Annexure A: Cookie Notice
<br />
<br />
This Website uses cookies to improve the user experience and ensure that it is functioning effectively, and on occasion also to provide marketing information or advertise online, either directly or through third party vendors.
<br />
In order to provide you with a more personalized and responsive service, Company needs to remember and store information about how you use this Website. This is done using small text files called cookies. Cookies contain small amounts of information and are downloaded to your computer or other device by a server for this Website. Your web browser then sends these cookies back to this Website on each subsequent visit so that it can recognize you and remember things like your user preferences. You can find below more detailed information about cookies.
<br />
By using this Website, you agree to Company's use of cookies as described in this Cookie Notice.
<br />
<br />
What cookies do Company use and why?
<br />
<br />
Some of the cookies Company uses are mandatory and cannot be disabled so as to enable you to move around the Website and use its features such as accessing secure areas that may contain content for registered users. Should you not wish to allow Company use the said cookies, you may at your wish stop using the Company Website.
<br />
Company also uses functional cookies to record information about the choices you have made and to allow it to tailor the site to its users; for example, to remember your language or region or that you have already completed a survey. This information is usually anonymized and is not used for any other purpose.
<br />
Company or its service providers also use analytic services to understand how effective Company content is, what interests Company users have, and to improve how this Website works. In addition, Company use web beacons or tracking pixels to count visitor numbers and performance cookies to track how many individual users access this Website and how often. This information is used for statistical purposes only and it is not Company's intention to use such information to personally identify any user. However, if you have registered and signed into this Website, Company may combine this information with information from its web analytic services and cookies to analyse how you use this Website in more detail.
<br />
<br />
How to control cookies?
<br />
<br />
By using this Website, you agree that Company can place cookies on your computer or device as explained above. Please bear in mind that removing or blocking cookies can impact user experience and parts of this website may no longer be fully accessible.
<br />
Browser controls : 
<br />
<br />
Most browsers will allow you to see what cookies you have, and delete them on an individual basis or block cookies from any particular or all websites. Be aware that any preference you have set will be lost if you delete all cookies, including your preference to opt-out from cookies, as this itself requires an opt-out cookie to have been set. Also, this may also lead to unavailability of some services.
<br />
Managing Analytics cookies : 
<br />
<br />
Company use the following service providers and you can learn more about their privacy policies by clicking on following link:
<br />
Google Analytics: <a href="https://www.google.com/analytics/learn/privacy.html">https://www.google.com/analytics/learn/privacy.html</a>
<br />
Facebook Pixel: <a href="https://developers.facebook.com/docs/privacy/">https://developers.facebook.com/docs/privacy/</a>

<br />
<br />
Managing Local Shared Objects : 
<br />
<br />
A local shared object is like most other browser cookies except that it can store additional types of information. These cookies cannot be controlled using the mechanisms listed above. Our Website uses these types of cookies to store user preferences for media player functionality and without them some video content may not play properly.
<br />
<br />
Social buttons
<br />
<br />
The Website contains 'social buttons' to enable users to share or bookmark web pages. These are buttons for third party social media sites and these sites may log information about your activities on the Internet including on this Website. Please review the respective terms of use and privacy policies of these sites to understand exactly how they use your information.
<br />
<br />
External web services
<br />
<br />
Company sometimes uses external web services on this Website to display content within the web pages of this Website, for example to display images, show videos or run polls. As with the social buttons, Company cannot prevent these sites or external domains from collecting information on your use of this embedded content.
<br />
<br />
Email Communications
<br />
<br />
Company may also use tracking technologies to determine whether you have read, clicked on, or forwarded certain email communications which Company sends to you, to make its communications more helpful and interesting. If you do not wish Company to confirm whether you have opened, clicked or forwarded its communications, you will need to unsubscribe, as it is not possible for Company to send these emails without tracking enabled. Registered subscribers can unsubscribe following the instructions in the individual email communications you receive from Company.
<br />
Company may modify or amend this Cookie Notice from time to time at its discretion. When Company makes a change to this notice, it will amend the revision date at the top of this page, and such modified or amended Cookie Notice shall be effective to you and your information as of that revised date. Company encourages you to periodically review this Cookie Notice to be informed about how it is using cookies.
<br />
Please contact Company through email: service@tripinbudget.com , if you would like more detailed information on the cookies that we use.
   </p>
   </div>
 </div>
  )
}
