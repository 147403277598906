// import '../Bus/busavailable.css'
// import React, { useState } from 'react';
// import Select from 'react-select';

// const BusAvailable = () => {
//   const [originCityName, setOriginCityName] = useState('');
//   const [destinationCityName, setDestinationCityName] = useState('');

//   const [travelDate, setTravelDate] = useState('');
  
//   const [travller, setTravller] = useState('');
//   const [seatType, setSeatType] = useState('');

//   const originOptions = [
//     { value: 'NYC', label: 'New York City' },
//     { value: 'LAX', label: 'Los Angeles' },
//     { value: 'SFO', label: 'San Francisco' },
//     // Add more city options as needed
//   ];

//   const seatTypeOptions = [
//     { value: 'Seater', label: 'Seater' },
//     { value: 'Sleeper', label: 'Sleeper' },
//     { value: 'Semi-Sleeper', label: 'Semi-Sleeper' },
//     { value: 'AC', label: 'AC' },
//     // Add more city options as needed
//   ];


//   const destinationOptions = [
//     { value: 'NYC', label: 'New York City' },
//     { value: 'LAX', label: 'Los Angeles' },
//     { value: 'SFO', label: 'San Francisco' },
//     // Add more city options as needed
//   ];

//   const handleSubmit = () => {
//     // Handle form submission here
//   };

//   return (
//     <div className="hotelcontainer">
//       <div className="dummy2"></div>
//       <div className="hotelcontainerrow">
//         <div className="flightcol">
//           <label className="form-label"> From City </label>
//           <Select
//             options={originOptions}
//             value={originCityName}
//             onChange={(selectedOption) => setOriginCityName(selectedOption)}

//             placeholder="Select City"
//           />
    
//         </div>

//         <div className="flightcol">
//           <label className="form-label"> To City </label>
//           <Select
//             options={destinationOptions}
//             value={destinationCityName}
//             onChange={(selectedOption) => setDestinationCityName(selectedOption)}
            
//             placeholder="Select City"
//           />
//         </div>

//         <div className="flightcol">
//           <label className="form-label">TravelDate</label>
//           <input
//             type="date"
//             className="form-control"
//             value={travelDate}
//             onChange={(e) => setTravelDate(e.target.value)}
//           />
//         </div>
        
//         <div className="flightcol">
//           <label className="form-label">No of Travller</label>
//           <input
//             type="number"
//             className="form-control"
//             value={travller}
//             onChange={(e) => setTravller(e.target.value)}
//           />
//         </div>
//         <div className="flightcol">
//           <label className="form-label">Seat Selection</label>
//           <Select
//             options={seatTypeOptions}
//             value={seatType}
//             onChange={(selectedOption) => setSeatType(selectedOption)}

//             placeholder="Seat Select"
//           />
//         </div>
//         <div className="flightcol">
//           <button onClick={handleSubmit} className="btn btn-primary">Fetch Hotels</button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default BusAvailable;









// import '../Bus/busavailable.css'
// import React, { useState, useEffect } from 'react';
// import Select from 'react-select';

// const BusAvailable = () => {
//   const [originCityName, setOriginCityName] = useState('');
//   const [destinationCityName, setDestinationCityName] = useState('');
//   const [travelDate, setTravelDate] = useState('');
//   const [traveller, setTraveller] = useState('');
//   const [seatType, setSeatType] = useState('');
//   const [originOptions, setOriginOptions] = useState([]);
//   const [destinationOptions, setDestinationOptions] = useState([]);

//   useEffect(() => {
//     // Fetch origin city names when the component mounts
//     fetchOriginCities();
//     fetchDestinationCities();
//   }, []);

//   const fetchOriginCities = () => {
//     fetch('http://localhost:3001/search-city', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json'
//       },
//       body: JSON.stringify({
//         cityName: originCityName,
//         countryCode: 'IN' // Assuming the country code is always 'IN' for this API call
//       })
//     })
//     .then(response => response.json())
//     .then(data => {
//       const cities = data.data.map(city => ({
//         value: city.name,
//         label: city.name
//       }));
//       setOriginOptions(cities);
//     })
//     .catch(error => {
//       console.error('Error fetching origin cities:', error);
//     });
//   };
  
//   const fetchDestinationCities = () => {
//     fetch('http://localhost:3001/search-city', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json'
//       },
//       body: JSON.stringify({
//         cityName: originCityName,
//         countryCode: 'IN' // Assuming the country code is always 'IN' for this API call
//       })
//     })
//     .then(response => response.json())
//     .then(data => {
//       const cities = data.data.map(city => ({
//         value: city.name,
//         label: city.name
//       }));
//       setDestinationOptions(cities);
//     })
//     .catch(error => {
//       console.error('Error fetching origin cities:', error);
//     });
//   };


//   const seatTypeOptions = [
//     { value: 'Seater', label: 'Seater' },
//     { value: 'Sleeper', label: 'Sleeper' },
//     { value: 'Semi-Sleeper', label: 'Semi-Sleeper' },
//     { value: 'AC', label: 'AC' },
//     // Add more seat options as needed
//   ];

//   const handleSubmit = () => {
//     // Handle form submission here
//   };
//   return (
//     <div className="hotelcontainer">
//       <div className="dummy2"></div>
//       <div className="hotelcontainerrow">
//         <div className="flightcol">
//           <label className="form-label"> From City </label>
//           <Select
//             options={originOptions}
//             value={originCityName}
//             onChange={(selectedOption) => setOriginCityName(selectedOption)}
//             placeholder="Select Origin City"
//           />
//         </div>

//         <div className="flightcol">
//           <label className="form-label"> To City </label>
//           <Select
//              options={destinationOptions}
//              value={destinationCityName}
//              onChange={(selectedOption) => setDestinationCityName(selectedOption)}
//              placeholder="Destination City"
//           />
//         </div>

//         <div className="flightcol">
//           <label className="form-label">Travel Date</label>
//           <input
//             type="date"
//             className="form-control"
//             value={travelDate}
//             onChange={(e) => setTravelDate(e.target.value)}
//           />
//         </div>
        
//         <div className="flightcol">
//           <label className="form-label">No of Travellers</label>
//           <input
//             type="number"
//             className="form-control"
//             value={traveller}
//             onChange={(e) => setTraveller(e.target.value)}
//           />
//         </div>

//         <div className="flightcol">
//           <label className="form-label">Seat Selection</label>
//           <Select
//             options={seatTypeOptions}
//             value={seatType}
//             onChange={(selectedOption) => setSeatType(selectedOption)}
//             placeholder="Seat Select"
//           />
//         </div>
        
//         <div className="flightcol">
//           <button onClick={handleSubmit} className="btn btn-primary">Fetch Hotels</button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default BusAvailable;



// using 2 api seperate for fetching citynames
// import '../Bus/busavailable.css'
// import React, { useState, useEffect } from 'react';
// import Select from 'react-select';

// const BusAvailable = () => {
//   const [originCityName, setOriginCityName] = useState('');
//   const [destinationCityName, setDestinationCityName] = useState('');
//   const [travelDate, setTravelDate] = useState('');
//   const [traveller, setTraveller] = useState('');
//   const [seatType, setSeatType] = useState('');
//   const [originOptions, setOriginOptions] = useState([]);
//   const [destinationOptions, setDestinationOptions] = useState([]);

//   useEffect(() => {
//     // Fetch origin city names when the component mounts
//     fetchOriginCities();
//     fetchDestinationCities();
//   }, []);

//   const fetchOriginCities = () => {
//     fetch('http://localhost:3001/search-city', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json'
//       },
//       body: JSON.stringify({
//         cityName: originCityName,
//         countryCode: 'IN' // Assuming the country code is always 'IN' for this API call
//       })
//     })
//     .then(response => response.json())
//     .then(data => {
//       const cities = data.data.map(city => ({
//         value: city.name,
//         label: city.name
//       }));
//       setOriginOptions(cities);
//     })
//     .catch(error => {
//       console.error('Error fetching origin cities:', error);
//     });
//   };
  
//   const fetchDestinationCities = () => {
//     fetch('http://localhost:3001/search-city', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json'
//       },
//       body: JSON.stringify({
//         cityName: originCityName,
//         countryCode: 'IN' // Assuming the country code is always 'IN' for this API call
//       })
//     })
//     .then(response => response.json())
//     .then(data => {
//       const cities = data.data.map(city => ({
//         value: city.name,
//         label: city.name
//       }));
//       setDestinationOptions(cities);
//     })
//     .catch(error => {
//       console.error('Error fetching origin cities:', error);
//     });
//   };


//   const seatTypeOptions = [
//     { value: 'Seater', label: 'Seater' },
//     { value: 'Sleeper', label: 'Sleeper' },
//     { value: 'Semi-Sleeper', label: 'Semi-Sleeper' },
//     { value: 'AC', label: 'AC' },
//     // Add more seat options as needed
//   ];

//   const handleSubmit = () => {
//     // Handle form submission here
//   };
//   return (
//     <div className="hotelcontainer">
//       <div className="dummy2"></div>
//       <div className="hotelcontainerrow">
//         <div className="flightcol">
//           <label className="form-label"> From City </label>
//           <Select
//             options={originOptions}
//             value={originCityName}
//             onChange={(selectedOption) => setOriginCityName(selectedOption)}
//             placeholder="Select Origin City"
//           />
//         </div>

//         <div className="flightcol">
//           <label className="form-label"> To City </label>
//           <Select
//              options={destinationOptions}
//              value={destinationCityName}
//              onChange={(selectedOption) => setDestinationCityName(selectedOption)}
//              placeholder="Destination City"
//           />
//         </div>

//         <div className="flightcol">
//           <label className="form-label">Travel Date</label>
//           <input
//             type="date"
//             className="form-control"
//             value={travelDate}
//             onChange={(e) => setTravelDate(e.target.value)}
//           />
//         </div>
        
//         <div className="flightcol">
//           <label className="form-label">No of Travellers</label>
//           <input
//             type="number"
//             className="form-control"
//             value={traveller}
//             onChange={(e) => setTraveller(e.target.value)}
//           />
//         </div>

//         <div className="flightcol">
//           <label className="form-label">Seat Selection</label>
//           <Select
//             options={seatTypeOptions}
//             value={seatType}
//             onChange={(selectedOption) => setSeatType(selectedOption)}
//             placeholder="Seat Select"
//           />
//         </div>
        
//         <div className="flightcol">
//           <button onClick={handleSubmit} className="btn btn-primary">Fetch Hotels</button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default BusAvailable;


// using single api for fetching both citynames
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import '../Bus/busavailable.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import BASE_URL from '../../Components/apiConfig';
import axios from 'axios';

const BusAvailable = () => {
  const [originCityName, setOriginCityName] = useState('');
  const [destinationCityName, setDestinationCityName] = useState('');
  const [travelDate, setTravelDate] = useState('');
  const [traveller, setTraveller] = useState('');
  const [seatType, setSeatType] = useState('');
  const [originOptions, setOriginOptions] = useState([]);
  const [destinationOptions, setDestinationOptions] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedData, setSelectedData] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [showLoginDialog,setShowLoginDialog]=useState(false);

  const navigate=useNavigate();
  const handleLoginClick=()=>{
    navigate('/login');
  }
  useEffect(() => {
    // Fetch origin and destination city names when the component mounts
    fetchOriginCities();
    fetchDestinationCities();
  }, []);

  const fetchOriginCities = () => {
    fetchCityNames(originCityName, setOriginOptions);
  };

  const fetchDestinationCities = () => {
    fetchCityNames(destinationCityName, setDestinationOptions);
  };

  const fetchCityNames = (cityName, setOptions) => {
    fetch(`${BASE_URL}/search-city`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        cityName: cityName,
        countryCode: 'IN'
      })
    })
      .then(response => response.json())
      .then(data => {
        const cities = data.data.map(city => ({
          value: city.name,
          label: city.name
        }));
        setOptions(cities);
      })
      .catch(error => {
        console.error('Error fetching cities:', error);
      });
  };

  const seatTypeOptions = [
    { value: 'Seater', label: 'Seater' },
    { value: 'Sleeper', label: 'Sleeper' },
    { value: 'Semi-Sleeper', label: 'Semi-Sleeper' },
    { value: 'AC', label: 'AC' },
  ];

  const handleOriginChange = (selectedOption) => {
    setOriginCityName(selectedOption);
    if (selectedOption.value === destinationCityName.value) {
      setErrorMessage('Origin and destination cities cannot be the same');
    } else {
      setErrorMessage('');
    }
  };

  const handleDestinationChange = (selectedOption) => {
    setDestinationCityName(selectedOption);
    if (selectedOption.value === originCityName.value) {
      setErrorMessage('The Departure City and Destination City cannot be same. Please re-type');
    } else {
      setErrorMessage('');
    }
  };

  const handleSubmit = () => {
    const isLoggedIn = localStorage.getItem('isLoggedIn');
    if (isLoggedIn) {
      const data = {
        originCityName: originCityName.value,
        destinationCityName: destinationCityName.value,
        travelDate,
        traveller,
        seatType: seatType.value,
      };
      // calling backend api for fetching name,email, mobile by passing input as email from localstorage
      axios.post(`${BASE_URL}/api/userdata`, { email: localStorage.getItem('email') })
        .then(response => {
          const userData = response.data.userData;
          // store response name,email,mobile in variable userdata array
          setSelectedData({ ...data, userData });
          setShowDialog(true);
        })
        .catch(error => {
          console.error('Error fetching user data:', error);
        });
    } else {
      setShowLoginDialog(true);
    }
  };
  

  const handleDialogClose = () => {
    setShowDialog(false);
  };


  
  
  const handleBusDataSend = () => {
    axios.post(`${BASE_URL}/send-bus-data`, {
      selectedData: selectedData,
      userData: selectedData.userData
    })
    .then(response => {
      console.log('Email sent successfully');
      toast.success('One of our members will contact you shortly', {
        onClose: handleDialogClose // Close the dialog box after the toast is closed
      });
      setTimeout(() => {
        setShowDialog(false);
      }, 2000);
      // Add any further logic here if needed
    })
    .catch(error => {
      console.error('Error sending email:', error);
    });
  };
  

  return (
    <div className="hotelcontainer">
      <div className="dummy2"></div>
      <div className="buscontainerrow">
        <div className="flightcol">
          <label className="form-label"> From City </label>
          <Select
            options={originOptions}
            value={originCityName}
            onChange={handleOriginChange}
            placeholder="Select Origin City"
          />
        </div>

        <div className="flightcol">
          <label className="form-label"> To City </label>
          <Select
            options={destinationOptions}
            value={destinationCityName}
            onChange={handleDestinationChange}
            placeholder="Destination City"
          />
          {errorMessage && <p className="error-message" style={{color:'red',fontSize:'0.8rem'}}>{errorMessage}</p>}
        </div>

        <div className="flightcol">
          <label className="form-label">Travel Date</label>
          <input
            type="date"
            className="form-control"
            value={travelDate}
            onChange={(e) => setTravelDate(e.target.value)}
          />
        </div>

        <div className="flightcol">
          <label className="form-label">No of Travellers</label>
          <input
            type="number"
            className="form-control"
            value={traveller}
            onChange={(e) => setTraveller(e.target.value)}
          />
        </div>

        <div className="flightcol">
          <label className="form-label">Seat Selection</label>
          <Select
            options={seatTypeOptions}
            value={seatType}
            onChange={(selectedOption) => setSeatType(selectedOption)}
            placeholder="Seat Select"
          />
        </div>

        <div className="flightcol">
          <button onClick={handleSubmit} className="btn btn-primary" disabled={!!errorMessage}>Fetch Buses</button>
        </div>
        {showDialog && (
  <div className="dialog-container">
    <div className="dialog">
      <h3>Selected Bus Data</h3>
      <table>
        <tbody>
        {selectedData.userData && (
            <>
            <tr>
              <th className='table-td'>Category</th>
              <th className='table-td'>Details</th>
            </tr>
              <tr>
                <td className='table-td'>Name</td>
                <td>{selectedData.userData.name}</td>
              </tr>
              <tr>
                <td className='table-td'>Email</td>
                <td>{selectedData.userData.email}</td>
              </tr>
              <tr>
                <td className='table-td'>Mobile</td>
                <td>{selectedData.userData.mobile}</td>
              </tr>
            </>
          )}
          <tr>
            <td className='table-td'>Origin City</td>
            <td>{selectedData.originCityName}</td> 
          </tr>
          <tr>
            <td className='table-td'>Destination City</td>
            <td>{selectedData.destinationCityName}</td>
          </tr>
          <tr>
            <td className='table-td'>Travel Date</td>
            <td>{selectedData.travelDate}</td>
          </tr>
          <tr>
            <td className='table-td'>No Of Traveller</td>
            <td>{selectedData.traveller}</td>
          </tr>
          <tr>
            <td className='table-td'>Seat Type</td>
            <td>{selectedData.seatType}</td>
          </tr>
         
        </tbody>
      </table>
      <div className="dialog-buttons">
        <button onClick={handleDialogClose} className="btn btn-secondary">Close</button>
        <button className="btn btn-secondary" onClick={handleBusDataSend}>Submit</button> 
      </div>
    </div>
  </div>
)}

       {showLoginDialog && (
        <div className="dialog-container">
          <div className="dialog">
           <p>please login/Register to complete the booking</p>
            <div className="dialog-buttons">
              <button className="btn btn-primary" onClick={handleLoginClick}>Login</button>
              <button className="btn btn-secondary" onClick={handleDialogClose}>Close</button>
            </div>
          </div>
        </div>
      )}
      </div>
      
    </div>
  );
};

export default BusAvailable;
