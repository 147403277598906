import React, { useState } from 'react';
import axios from 'axios';

const FlightConnectionPage = () => {
    const [formData, setFormData] = useState({
        departure: '',
        arrival: '',
        preferredDate: ''
    });
    const [response, setResponse] = useState(null);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const res = await axios.post('http://localhost:3001/flight-connections', formData);
            setResponse(res.data);
        } catch (error) {
            console.error('Failed to fetch flight connections:', error);
        }
    };

    return (
        <div>
            <h1>Flight Connection Page</h1>
            <form onSubmit={handleSubmit}>
                <input type="text" name="departure" placeholder="Departure" value={formData.departure} onChange={handleChange} />
                <input type="text" name="arrival" placeholder="Arrival" value={formData.arrival} onChange={handleChange} />
                <input type="date" name="preferredDate" value={formData.preferredDate} onChange={handleChange} />
                <button type="submit">Search</button>
            </form>
            {response && (
                <div className='result' style={{margin:'20px'}}>
                    <h2>Flight Connections</h2>
                    <table className="table table-bordered" id="customers">
                        <thead>
                            <tr>
                                <th>Airline Code</th>
                                <th>Flight Number</th>
                                <th>Departure Airport</th>
                                <th>Departure City</th>
                                <th>Arrival Airport</th>
                                <th>Arrival City</th>
                                <th>Departure Time</th>
                                <th>Arrival Time</th>
                                <th>Duration</th>
                            </tr>
                        </thead>
                        <tbody>
                            {response.routes.map((route, index) => (
                                <tr key={index}>
                                    <td>{route.flights[0].airlineCode}</td>
                                    <td>{route.flights[0].flightNumber}</td>
                                    <td>{route.flights[0].departure.name}</td>
                                    <td>{route.flights[0].departure.city}</td>
                                    <td>{route.flights[0].arrival.name}</td>
                                    <td>{route.flights[0].arrival.city}</td>
                                    <td>{route.flights[0].scheduleDeparture}</td>
                                    <td>{route.flights[0].scheduleArrival}</td>
                                    <td>{route.totalJourneyTimeMinutes} mins</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default FlightConnectionPage;
