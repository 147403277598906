import * as React from 'react';
import slider1 from '../Images/hoteloffer1.avif'
import slider2 from '../Images/hoteloffer1.png'
import slider3 from '../Images/hoteloffer4.jpg'
import slider4 from '../Images/hoteloffer1.avif'
import slider5 from '../Images/hoteloffer1.png'
import slider6 from '../Images/hoteloffer4.jpg'
import Slider from "react-slick";
import PauseOnHover from './Carousel';

import '../Components/offers.css'
export default function Offers() {
  
  return (
    <div className="offercontainer">
      <div className="offercontainerfirstrow">
     

      </div>
      <div class="carousl">
                {/* carousel have 3 sub div for divide screen  */}
                    <div class="carodiv1"></div>
                    <div class="carodiv2">
                    <PauseOnHover/>
                    </div>
                    <div class="carodiv3"></div>
              </div>
    <div className="offercontainercards">
    <div className="offercard2"><img src={slider2} alt="" className='offerimg' /></div>
          <div className="offercard2"><img src={slider3} alt="" className='offerimg' /></div>
          <div className="offercard2"><img src={slider1} alt="" className='offerimg' /></div>
          <div className="offercard2"><img src={slider3} alt="" className='offerimg' /></div>
          <div className="offercard2"><img src={slider2} alt="" className='offerimg' /></div>
          <div className="offercard2"><img src={slider1} alt="" className='offerimg' /></div>
          <div className="offercard2"><img src={slider2} alt="" className='offerimg' /></div>
          <div className="offercard2"><img src={slider3} alt="" className='offerimg' /></div>
    </div>
    </div>
  );
}