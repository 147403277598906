import React, { useState } from 'react';
import axios from 'axios';
import BASE_URL from '../../Components/apiConfig';
const HotelOffers = () => {
    const [formData, setFormData] = useState({
        hotelIds: '',
        adults: '',
        checkInDate: '',
        checkOutDate: '',
        roomQuantity: ''
    });
    const [hotelOffers, setHotelOffers] = useState([]);
    const [error, setError] = useState(null);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${BASE_URL}/hotel-offers`, formData);
            setHotelOffers(response.data.data);
            setError(null);
        } catch (error) {
            console.error('Failed to fetch hotel offers:', error);
            setError('Failed to fetch hotel offers. Please try again later.');
        }
    };

    return (
        <div style={{padding:'100px'}}>
            <form onSubmit={handleSubmit}>
                <label>
                    Hotel IDs:
                    <input type="text" name="hotelIds" value={formData.hotelIds} onChange={handleChange} />
                </label>
                <label>
                    Adults:
                    <input type="number" name="adults" value={formData.adults} onChange={handleChange} />
                </label>
                <label>
                    Check-in Date:
                    <input type="date" name="checkInDate" value={formData.checkInDate} onChange={handleChange} />
                </label>
                <label>
                    Check-out Date:
                    <input type="date" name="checkOutDate" value={formData.checkOutDate} onChange={handleChange} />
                </label>
                <label>
                    Room Quantity:
                    <input type="number" name="roomQuantity" value={formData.roomQuantity} onChange={handleChange} />
                </label>
                <button type="submit">Fetch Hotel Offers</button>
            </form>
            {error && <p>{error}</p>}
            <table className="table table-bordered" id="customers">
                <thead>
                    <tr>

                        <th>Hotel Name</th>
                        <th>City Code</th>
                        <th>Latitude</th>
                        <th>Longitude</th>
                        <th>Offer id</th>
                  
                        <th>CheckIn Date</th>
                        <th>CheckOut Date</th>
                        <th>Description</th>
                        <th>Adults</th>
                        <th>Price/night</th>
                        <th>Total Price</th>
                    </tr>
                </thead>
                <tbody>
                    {hotelOffers.map((offer, index) => (
                        <tr key={index}>
                            <td>{offer.hotel.name}</td>
                            <td>{offer.hotel.cityCode}</td>
                            <td>{offer.hotel.latitude}</td>
                            <td>{offer.hotel.longitude}</td>
                            <td>{offer.offers[0].id}</td>
                            <td>{offer.offers[0].checkInDate}</td>
                <td>{offer.offers[0].checkOutDate}</td>
                {/* Assuming room description is the same for all offers */}
                <td>{offer.offers[0].room.description.text}</td>
                {/* Assuming number of adults is the same for all offers */}
                <td>{offer.offers[0].guests.adults}</td>
                {/* Assuming base price and total price are the same for all offers */}
                <td>{offer.offers[0].price.base}</td>
                <td>{offer.offers[0].price.total}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default HotelOffers;
