import React, { useState } from 'react';
import '../../Components/Hotel/hotels.css'; // Update the import path for the CSS file
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import slider1 from '../../Images/hoteloffer1.avif'
import slider2 from '../../Images/hoteloffer1.png'
import slider3 from '../../Images/hoteloffer4.jpg'
import FocusOnSelect from '../Carousel2';

import backgroundslider3 from '../../Images/flightbackground1.png'
import backgroundslider2 from '../../Images/flightbackground2.png'
import backgroundslider1 from '../../Images/plane3.png'
import backgroundslider4 from '../../Images/flightbacground3.png'
import backgroundslider5 from '../../Images/flightbackground4.png'


import trainboximg1 from '../../assets/1-circle.svg'
import trainboximg2 from '../../assets/2-circle.svg'
import trainboximg3 from '../../assets/3-circle.svg'
import trainboximg4 from '../../assets/4-circle.svg'
import trainboximg5 from '../../assets/5-circle.svg'
import trainboximg6 from '../../assets/6-circle.svg'
import BASE_URL from '../../Components/apiConfig';

import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useEffect } from 'react';
import  Select from 'react-select';




export default function Hotels() {
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [originOptions, setOriginOptions] = useState([]);
  const [cityCode, setCityCode] = useState('');
  const [checkInDate, setCheckInDate] = useState('');
  const [checkOutDate, setCheckOutDate] = useState('');
  const [adults, setAdults] = useState(1);
  const [numberOfRooms, setNumberOfRooms] = useState(1);
  const navigate=useNavigate();





  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch IATA code and city name based on user input (searchTerm)
        const response = await axios.post(`${BASE_URL}/fetch-iata-city`, {
          query: searchTerm
        });
        
  
        // Update options based on the response
        const iataCityOptions = response.data.map((airport) => ({
          value: airport.iata_code,
          label: `${airport.iata_code} - ${airport.city_name}`
        }));
  
        // Fetch options from the earlier APIs
        const nameOptionsResponse = await axios.get('https://airports15.p.rapidapi.com/airports', {
          params: { name: searchTerm },
          headers: {
            'X-RapidAPI-Key': 'baa5306601msh44eade617a3c3d1p188b0djsn9f7772669cc0',
            'X-RapidAPI-Host': 'airports15.p.rapidapi.com',
          },
        });
  
        const iataOptionsResponse = await axios.get('https://airport-info.p.rapidapi.com/airport', {
          params: { iata: searchTerm },
          headers: {
            'X-RapidAPI-Key': 'baa5306601msh44eade617a3c3d1p188b0djsn9f7772669cc0',
            'X-RapidAPI-Host': 'airport-info.p.rapidapi.com',
          },
        });
  
        const nameOptions = nameOptionsResponse.data.map((airport) => ({
          value: airport.iataCode,
          label: `${airport.iataCode} - ${airport.city}`,
        }));
  
        const iataOption = {
          value: iataOptionsResponse.data.iata,
          label: `${iataOptionsResponse.data.iata} - ${iataOptionsResponse.data.city}`,
        };
  
        // Combine all options
        const options = [...nameOptions, iataOption, ...iataCityOptions];
        
        setOriginOptions(options);
        setError(null);
      } catch (error) {
        setOriginOptions([]);
        setError('Error fetching data');
      }
    };
  
    if (searchTerm) {
      fetchData();
    }
  }, [searchTerm]);
  const handleSubmit = async () => {
    try {
      // Perform any actions necessary before navigating, like validation
  
      // Redirect to results page with query parameters
      navigate(`/hotels/available/result?cityCode=${cityCode.value}&checkInDate=${checkInDate}&checkOutDate=${checkOutDate}&adults=${adults}&numberOfRooms=${numberOfRooms}`);
    } catch (error) {
      console.error('Error:', error.message);
    }
  };

 

  
  
  return (

    // main container
    <div className="hotelcontainer">
      {/* fisrtrow background image section */}
      <div className="hotelcontainerfirstrow">
      </div>
     <div className="hotel-secondrow">
      <div className="hotel-second-containerrow">
        <div className="flightcol">
            <label className="form-label">City Code</label>
            <Select
              options={originOptions}
              value={cityCode}
              onChange={(selectedOption) => setCityCode(selectedOption)}
              onInputChange={(inputValue) => setSearchTerm(inputValue)}
              placeholder="Enter airport"
            />
          </div>
          <div className="flightcol">
          <label className="form-label">Check-In Date</label>
          <input type="date" className="form-control" value={checkInDate} onChange={(e) => setCheckInDate(e.target.value)} />
        </div>
        <div className="flightcol">
          <label className="form-label">Check-Out Date</label>
          <input type="date" className="form-control" value={checkOutDate} onChange={(e) => setCheckOutDate(e.target.value)} />
        </div>
        <div className="flightcol">
          <label className="form-label">Adults</label>
          <input type="number" className="form-control" value={adults} onChange={(e) => setAdults(e.target.value)} />
        </div>
        <div className="flightcol">
          <label className="form-label">Number of Rooms</label>
          <input type="number" className="form-control" value={numberOfRooms} onChange={(e) => setNumberOfRooms(e.target.value)} />
        </div>
        <div className="flightcol">
          <button onClick={handleSubmit} className="btn btn-primary">Fetch Hotels</button>
        </div>
      </div>
      
      </div>
      <div className="hotelcontainerthirdrow">
<FocusOnSelect/>
      </div>


 {/* about us section start */}
 <div className="FourthContainer">
      <div className="FourthContainerRow1">
       <h2>Why book hotels online with TripInBudget?</h2>
      </div>
      <div className="FourthContainerRow2">
     <div className="FourthContainerRow2box">
          <div className="subbox1"> <i class="bi bi-1-circle"></i><img src={trainboximg1} alt="" className='trainboximg'/> </div>
          <div className="subbox2">
              <h1 className='subbox2text1'> Largest selection of hotels</h1>
              <p className='subbox2text2'>Choose from a wide range of properties to suit your preferences. Be it for family vacations, business trips, or leisure getaways – we've got it all covered.</p>
          </div>
          <div className="subbox3"></div>
     </div>
     <div className="FourthContainerRow2box">
     <div className="subbox1"><i class="bi bi-2-circle"></i><img src={trainboximg2} alt="" className='trainboximg'/></div>
          <div className="subbox2">
              <h1 className='subbox2text1'> Best deals and prices on hotels</h1>
              <p className='subbox2text2'>Get the lowest prices on your bookings with the best hotel offers and use our advanced filter options for perfect selection.</p>
          </div>
          <div className="subbox3"></div>
     </div>
     <div className="FourthContainerRow2box">
     <div className="subbox1"><i class="bi bi-3-circle"></i><img src={trainboximg3} alt="" className='trainboximg'/></div>
          <div className="subbox2">
              <h1 className='subbox2text1'> Pay at Hotel option available</h1>
              <p className='subbox2text2'>Make your online hotel booking with tripinbudget and get the flexibility to pay at the property directly.</p>
          </div>
          <div className="subbox3"></div>
     </div>
     <div className="FourthContainerRow2box">
     <div className="subbox1"><i class="bi bi-4-circle"></i><img src={trainboximg4} alt="" className='trainboximg'/></div>
          <div className="subbox2">
              <h1 className='subbox2text1'> Collaborative wishlists</h1>
              <p className='subbox2text2'>Plan your dream vacation together - With our collaborative wishlist feature, share your favourite properties with your travel mates and also let them add their own.</p>
          </div>
          <div className="subbox3"></div>
     </div>
     <div className="FourthContainerRow2box">
     <div className="subbox1"><i class="bi bi-5-circle"></i><img src={trainboximg5} alt="" className='trainboximg'/></div>
          <div className="subbox2">
              <h1 className='subbox2text1'>24x7 customer support</h1>
              <p className='subbox2text2'>Need help? Get assistance from our Customer Service team for all your hotel reservation-related queries.</p>
          </div>
          <div className="subbox3"></div>
     </div>
     <div className="FourthContainerRow2box">
     <div className="subbox1"><i class="bi bi-6-circle"></i><img src={trainboximg6} alt="" className='trainboximg'/></div>
          <div className="subbox2">
              <h1 className='subbox2text1'> Transparent reviews and ratings:</h1>
              <p className='subbox2text2'>Make informed decisions with access to genuine guest reviews and ratings for each property, ensuring you choose accommodations that meet your standards and expectations.</p>
          </div>
          <div className="subbox3"></div>
     </div>
      </div>
    </div>
      {/* advertise section end */}


      {/* popular flight route sextion start */}
<div className="populerflightroutecontainer"> 
{/* popular flight route sextion 2 rows row1 start */}
 <div className="populerflightrouteheading">
 Popular Destinations

</div> 
{/* popular flight route sextion 2 rows row1 end */}
{/* popular flight route sextion 2 rows row2 start */}
 <div className="populerflightroute">
  <div className="populerflightroutebox">
 <div className="populerflightrouteboxcol1"><img src={backgroundslider1} alt="" className='populerflightimage'/></div>
 <div className="populerflightrouteboxcol2"> <strong>Mumbai </strong> <p style={{fontSize:'0.8rem'}}>Maharashtra</p>  </div>
    </div>
  <div className="populerflightroutebox">
    <div className="populerflightrouteboxcol1"><img src={backgroundslider1} alt="" className='populerflightimage'/></div>
    <div className="populerflightrouteboxcol2"><strong> New Delhi </strong>  <p style={{fontSize:'0.8rem'}}>To: Goa, Delhi, Bangalore, Ahmedabad</p>   </div>
  </div>
  <div className="populerflightroutebox">
    <div className="populerflightrouteboxcol1"><img src={backgroundslider2} alt="" className='populerflightimage'/></div>
    <div className="populerflightrouteboxcol2"><strong>Goa</strong>   <p style={{fontSize:'0.8rem'}}>To: Goa, Delhi, Bangalore, Ahmedabad</p>   </div>
  </div>
  <div className="populerflightroutebox">
    <div className="populerflightrouteboxcol1"><img src={backgroundslider3} alt="" className='populerflightimage'/></div>
    <div className="populerflightrouteboxcol2"><strong>Chennai </strong>   <p style={{fontSize:'0.8rem'}}>To: Goa, Delhi, Bangalore, Ahmedabad</p>   </div>
  </div>
  <div className="populerflightroutebox">
    <div className="populerflightrouteboxcol1"><img src={backgroundslider4} alt="" className='populerflightimage'/></div>
    <div className="populerflightrouteboxcol2"><strong>Hyderabad </strong> <p style={{fontSize:'0.8rem'}}>To: Goa, Delhi, Bangalore, Ahmedabad</p>    </div>
  </div>
  <div className="populerflightroutebox">
    <div className="populerflightrouteboxcol1"><img src={backgroundslider5} alt="" className='populerflightimage'/></div>
    <div className="populerflightrouteboxcol2"><strong>Ahemdabad </strong> <p style={{fontSize:'0.8rem'}}>To: Goa, Delhi, Bangalore, Ahmedabad</p>    </div>
  </div>
  <div className="populerflightroutebox">
    <div className="populerflightrouteboxcol1"><img src={backgroundslider1} alt="" className='populerflightimage'/></div>
    <div className="populerflightrouteboxcol2"><strong>Bangalore </strong> <p style={{fontSize:'0.8rem'}}>To: Goa, Delhi, Bangalore, Ahmedabad</p>       </div>
  </div>
  <div className="populerflightroutebox">
    <div className="populerflightrouteboxcol1"><img src={backgroundslider2} alt="" className='populerflightimage'/></div>
    <div className="populerflightrouteboxcol2"><strong>Pune </strong>      <p style={{fontSize:'0.8rem'}}>To: Goa, Delhi, Bangalore, Ahmedabad</p>    </div>
  </div>
  <div className="populerflightroutebox">
    <div className="populerflightrouteboxcol1"><img src={backgroundslider3} alt="" className='populerflightimage'/></div>
    <div className="populerflightrouteboxcol2"><strong>Kolkata</strong>       <p style={{fontSize:'0.8rem'}}>To: Goa, Delhi, Bangalore, Ahmedabad</p></div>
  </div>
</div>
{/* popular flight route sextion 2 rows row2 end */}
</div> 
{/* popular flight route sextion end */}


{/* Popular Hotel start */}

{/* <div className="container">
  <div className="card">
    <img src={popularhotel1} alt="mumbai" />
    <div className="intro">
      <h1>Mumbai</h1>
      <p>Maharashtra</p>
    </div>
  </div>

  <div className="card">
    <img src={popularhotel1} alt="mumbai" />
    <div className="intro">
      <h1>Mumbai</h1>
      <p>Maharashtra</p>
    </div>
  </div>

  <div className="card">
    <img src={popularhotel1} alt="mumbai" />
    <div className="intro">
      <h1>Mumbai</h1>
      <p>Maharashtra</p>
    </div>
  </div>
</div> */}
{/* popular hotel end */}

      <div className="aboutuscontainer">
        <div className="aboutuscontainerrow1">
          <h2>About Us</h2><br />

          <p className='aboutustitle1'>Explore all corners of the world with us.</p><br />
          <p className='aboutussubtitle1'>Lorem Ipsum is available, but the majority have suffered alteration in some form, by injected humor or randomized words, which don't look even slightly believable.</p>
          <ul>

            <li>
              <strong>Tour Guide</strong><br /> Lorem Ipsum is available, but the majority have suffered alteration in some. Our experienced tour guides ensure an enriching and informative journey.
            </li><br />
            <li>
              <strong>Friendly Price</strong><br /> Lorem Ipsum is available, but the majority have suffered alteration in some. We believe in providing affordable and budget-friendly travel options.
            </li><br />
            <li>
              <strong>Reliable Tour</strong><br /> Lorem Ipsum is available, but the majority have suffered alteration in some. Count on us for reliable and well-organized tours that meet your expectations.
            </li><br />
          </ul>

          <p>Embark on unforgettable adventures with our dedicated team. Your journey starts here!</p>


        </div>
        <div className="aboutuscontainerrow2">


        </div>
      </div>

      

      {/* all offers sorting button section */}
      {/* <div className="hotelcontainerfourthrow"> */}

        {/* all sorted offers images section */}
        {/* <div className="hotelcontainerfourthrowrow2">
          <div className="offercard"><img src={slider2} alt="" className='offerimg' /></div>
          <div className="offercard"><img src={slider3} alt="" className='offerimg' /></div>
          <div className="offercard"><img src={slider1} alt="" className='offerimg' /></div>
          <div className="offercard"><img src={slider3} alt="" className='offerimg' /></div>
          <div className="offercard"><img src={slider2} alt="" className='offerimg' /></div>
          <div className="offercard"><img src={slider1} alt="" className='offerimg' /></div>
          <div className="offercard"><img src={slider2} alt="" className='offerimg' /></div>
          <div className="offercard"><img src={slider3} alt="" className='offerimg' /></div>
        </div> */}
      {/* </div> */}
       {/* faq section  */}
      <div className="hotelcontainerfifthrow">
        <div className="faqcontainer">
          <h1>Frequently Asked Questions</h1>
          <details>
            <summary>How do I create an accordion?</summary>
            <div>
              The tags <em>details</em> and <em>summary</em> have you covered.
            </div>
          </details>
          <details>
            <summary>How do I create an accordion?</summary>
            <div>
              The tags <em>details</em> and <em>summary</em> have you covered.
            </div>
          </details>
          <details>
            <summary>How do I create an accordion?</summary>
            <div>
              The tags <em>details</em> and <em>summary</em> have you covered.
            </div>
          </details>
          <details >
            <summary>FAQ 1</summary>
            <div class="faq__content">
              <p>Answer 1</p>
            </div>
          </details>
          <details>
            <summary>FAQ 2</summary>
            <div class="faq__content">
              <p>Answer 2</p>
            </div>
          </details>
          <details>
            <summary>FAQ 3</summary>
            <div class="faq__content">
              <p>Answer 3</p>
            </div>
          </details>
        </div>
      </div>
      {/* faq section end */}
    </div>
    // main container end
  );
}