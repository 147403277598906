import React, { useState,useEffect } from 'react';
import '../Bus/buses.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import slider2 from '../../Images/slider2.png'
import slider3 from '../../Images/slider3.png'
import image1 from '../../Images/train.jpg'
import image2 from '../../Images/train2.png'
import image3 from '../../Images/trainoffer4.jpeg'
import image4 from '../../Images/trainoffer1.jpeg'
import image5 from '../../Images/trainoffer2.jpg'
import image6 from '../../Images/trainoffer3.jpg'
import backgroundslider3 from '../../Images/flightbackground1.png'
import backgroundslider2 from '../../Images/flightbackground2.png'
import backgroundslider1 from '../../Images/plane3.png'
import backgroundslider4 from '../../Images/flightbacground3.png'
import backgroundslider5 from '../../Images/flightbackground4.png'
import Select from 'react-select';
import '../Bus/busavailable.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import BASE_URL from '../../Components/apiConfig';
import axios from 'axios';
import trainboximg1 from '../../Images/route-start-svgrepo-com.svg'
import trainboximg2 from '../../Images/bus-svgrepo-com.svg'
import trainboximg3 from '../../Images/money-svgrepo-com.svg'
import trainboximg4 from '../../Images/information-service-support-svgrepo-com.svg'
import trainboximg5 from '../../Images/handshake-deal-svgrepo-com.svg'
import trainboximg6 from '../../Images/refund-svgrepo-com.svg'
export default function Buses() {
  const isMobile = window.innerWidth <= 760;

  const settingsDesktop = {
    focusOnSelect: true,
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "100px",
    slidesToShow: 3,
    speed: 800,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2400,
    pauseOnHover: true
  };

  const settingsMobile = {
    focusOnSelect: true,
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 1,
    speed: 600,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2400,
    pauseOnHover: true
  };

  const settings = isMobile ? settingsMobile : settingsDesktop;
  const [originCityName, setOriginCityName] = useState('');
  const [destinationCityName, setDestinationCityName] = useState('');
  const [travelDate, setTravelDate] = useState('');
  const [traveller, setTraveller] = useState(1);
  const [seatType, setSeatType] = useState('');
  const [originOptions, setOriginOptions] = useState([]);
  const [destinationOptions, setDestinationOptions] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedData, setSelectedData] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [showLoginDialog,setShowLoginDialog]=useState(false);

  const navigate=useNavigate();
  const handleLoginClick=()=>{
    navigate('/login');
  }
  useEffect(() => {
    // Fetch origin and destination city names when the component mounts
    fetchOriginCities();
    fetchDestinationCities();
  }, []);

  const fetchOriginCities = () => {
    fetchCityNames(originCityName, setOriginOptions);
  };

  const fetchDestinationCities = () => {
    fetchCityNames(destinationCityName, setDestinationOptions);
  };

  const fetchCityNames = (cityName, setOptions) => {
    fetch(`${BASE_URL}/search-city`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        cityName: cityName,
        countryCode: 'IN'
      })
    })
      .then(response => response.json())
      .then(data => {
        const cities = data.data.map(city => ({
          value: city.name,
          label: city.name
        }));
        setOptions(cities);
      })
      .catch(error => {
        console.error('Error fetching cities:', error);
      });
  };

  const seatTypeOptions = [
    { value: 'Seater', label: 'Seater' },
    { value: 'Sleeper', label: 'Sleeper' },
    { value: 'Semi-Sleeper', label: 'Semi-Sleeper' },
    { value: 'AC', label: 'AC' },
  ];

  const handleOriginChange = (selectedOption) => {
    setOriginCityName(selectedOption);
    if (selectedOption.value === destinationCityName.value) {
      setErrorMessage('Origin and destination cities cannot be the same');
    } else {
      setErrorMessage('');
    }
  };

  const handleDestinationChange = (selectedOption) => {
    setDestinationCityName(selectedOption);
    if (selectedOption.value === originCityName.value) {
      setErrorMessage('The Departure City and Destination City cannot be same. Please re-type');
    } else {
      setErrorMessage('');
    }
  };

  const handleSubmit = () => {
    const isLoggedIn = localStorage.getItem('isLoggedIn');
    if (isLoggedIn) {
      const data = {
        originCityName: originCityName.value,
        destinationCityName: destinationCityName.value,
        travelDate,
        traveller,
        seatType: seatType.value,
      };
      if (!originCityName || !destinationCityName || !travelDate || !traveller || !seatType) {
        // setErrorMessage('All fields are mandatory');
        toast.error('All fields are mandatory');
        return;
      }
      // calling backend api for fetching name,email, mobile by passing input as email from localstorage
      // axios.post('https://tripinbudgetserver.onrender.com/api/userdata', { email: localStorage.getItem('email') })
      axios.post(`${BASE_URL}/api/userdata`, { email: localStorage.getItem('email') })
     
        .then(response => {
          const userData = response.data.userData;
          // store response name,email,mobile in variable userdata array
          setSelectedData({ ...data, userData });
          setShowDialog(true);
        })
        .catch(error => {
          console.error('Error fetching user data:', error);
        });
    } else {
      setShowLoginDialog(true);
    }
  };
  

  const handleDialogClose = () => {
    setShowDialog(false);
  };

  const handleBusDataSend = () => {
    axios.post(`${BASE_URL}/send-bus-data`, {
      selectedData: selectedData,
      userData: selectedData.userData
    })
    .then(response => {
      console.log('Email sent successfully');
      toast.success('One of our members will contact you shortly', {
        onClose: handleDialogClose // Close the dialog box after the toast is closed
      });
      setTimeout(() => {
        setShowDialog(false);
      }, 2000);
      // Add any further logic here if needed
    })
    .catch(error => {
      console.error('Error sending email:', error);
    });
  };
  
  return (
// main container
<div className="buscontainer">
   {/* fisrtrow background image section */}
  <div className="buscontainerfirstrow">
  </div>
<div className="bus-second-row">
<div className="buscontainerrow">
        <div className="bus-flightcol">
          <label className="form-label"> From City </label>
          <Select
            options={originOptions}
            value={originCityName}
            onChange={handleOriginChange}
            placeholder="Origin City"
            required
          />
        </div>

        <div className="bus-flightcol">
          <label className="form-label"> To City </label>
          <Select
            options={destinationOptions}
            value={destinationCityName}
            onChange={handleDestinationChange}
            placeholder="Destination City"
          />
          {errorMessage && <p className="error-message" style={{color:'red',fontSize:'0.8rem'}}>{errorMessage}</p>}
        </div>

        <div className="bus-flightcol">
          <label className="form-label">Travel Date</label>
          <input
            type="date"
            className="form-control"
            value={travelDate}
            onChange={(e) => setTravelDate(e.target.value)}
            required
          />
        </div>

        <div className="bus-flightcol">
          <label className="form-label">No of Travellers</label>
          <input
            type="number"
            className="form-control"
            value={traveller}
            onChange={(e) => setTraveller(e.target.value)}
            required
          />
        </div>

        <div className="bus-flightcol">
          <label className="form-label">Seat Selection</label>
          <Select
            options={seatTypeOptions}
            value={seatType}
            onChange={(selectedOption) => setSeatType(selectedOption)}
            placeholder="Seat Select"
            required
          />
        </div>

        <div className="bus-flightcol">
          <button onClick={handleSubmit} className="btn btn-primary" disabled={!!errorMessage}>Fetch Buses</button>
        </div>
        {showDialog && (
  <div className="dialog-container">
    <div className="dialog">
      <h3>Selected Bus Data</h3>
      <table>
        <tbody>
        {selectedData.userData && (
            <>
            <tr>
              <th className='table-td'>Category</th>
              <th className='table-td'>Details</th>
            </tr>
              <tr>
                <td className='table-td'>Name</td>
                <td>{selectedData.userData.name}</td>
              </tr>
              <tr>
                <td className='table-td'>Email</td>
                <td>{selectedData.userData.email}</td>
              </tr>
              <tr>
                <td className='table-td'>Mobile</td>
                <td>{selectedData.userData.mobile}</td>
              </tr>
            </>
          )}
          <tr>
            <td className='table-td'>Origin City</td>
            <td>{selectedData.originCityName}</td> 
          </tr>
          <tr>
            <td className='table-td'>Destination City</td>
            <td>{selectedData.destinationCityName}</td>
          </tr>
          <tr>
            <td className='table-td'>Travel Date</td>
            <td>{selectedData.travelDate}</td>
          </tr>
          <tr>
            <td className='table-td'>No Of Traveller</td>
            <td>{selectedData.traveller}</td>
          </tr>
          <tr>
            <td className='table-td'>Seat Type</td>
            <td>{selectedData.seatType}</td>
          </tr>
        </tbody>
      </table>
      <div className="dialog-buttons">
        <button onClick={handleDialogClose} className="btn btn-secondary">Close</button>
        <button className="btn btn-secondary" onClick={handleBusDataSend}>Submit</button> 
      </div>
    </div>
  </div>
)}
       {showLoginDialog && (
        <div className="dialog-container">
          <div className="dialog">
           <p>please login/Register to complete the booking</p>
            <div className="dialog-buttons">
              <button className="btn btn-primary" onClick={handleLoginClick}>Login</button>
              <button className="btn btn-secondary" onClick={handleDialogClose}>Close</button>
            </div>
          </div>
        </div>
      )}
      </div>
</div>
<div className="FourthContainer">
      <div className="FourthContainerRow1">
       <h2>Why Book Bus Tickets from TripInBudget</h2>
      </div>
      <div className="FourthContainerRow2">
     <div className="FourthContainerRow2box">
          <div className="subbox1"><img src={trainboximg1} alt="" className='trainboximg'/></div>
          <div className="subbox2">
              <h1 className='subbox2text1'> 1,00,00+ Bus Routes</h1>
              <p className='subbox2text2'>TripInBudget is Providing unmatched options to fulfill your travel requirements.</p>
          </div>
          <div className="subbox3"></div>
     </div>
     <div className="FourthContainerRow2box">
     <div className="subbox1"><img src={trainboximg2} alt="" className='trainboximg'/></div>
          <div className="subbox2">
              <h1 className='subbox2text1'> 2000+ Bus Partners</h1>
              <p className='subbox2text2'>Offering a diverse selection, spanning from State RTCs to private collaborators.</p>
          </div>
          <div className="subbox3"></div>
     </div>
     <div className="FourthContainerRow2box">
     <div className="subbox1"><img src={trainboximg3} alt="" className='trainboximg'/></div>
          <div className="subbox2">
              <h1 className='subbox2text1'> Fastest Bus Booking</h1>
              <p className='subbox2text2'>Ensuring a smooth and efficient process for booking bus tickets, with rapidity and seamlessness.</p>
          </div>
          <div className="subbox3"></div>
     </div>
     <div className="FourthContainerRow2box">
     <div className="subbox1"><img src={trainboximg4} alt="" className='trainboximg'/></div>
          <div className="subbox2">
              <h1 className='subbox2text1'> 24/7 Customer Support</h1>
              <p className='subbox2text2'>
              Accessible for all your bus reservation requirements.</p>
          </div>
          <div className="subbox3"></div>
     </div>
     <div className="FourthContainerRow2box">
     <div className="subbox1"><img src={trainboximg5} alt="" className='trainboximg'/></div>
          <div className="subbox2">
              <h1 className='subbox2text1'>Instant Refund And Concellation</h1>
              <p className='subbox2text2'>Offering complimentary cancellation for adjustments or alterations to your booking.
</p>
          </div>
          <div className="subbox3"></div>
     </div>
     <div className="FourthContainerRow2box">
     <div className="subbox1"><img src={trainboximg6} alt="" className='trainboximg'/></div>
          <div className="subbox2">
              <h1 className='subbox2text1'> Best Deals & Discounts</h1>
              <p className='subbox2text2'>Unlock optimal savings through premier offers & exclusive markdowns.

</p>
          </div>
          <div className="subbox3"></div>
     </div>
      </div>
    </div>
<div className="buscontainerthirdrow">
<div style={{color:'purple',fontWeight:'700',fontSize:'24px'}}>Todays Exclusive Offers</div>
        <Slider {...settings}>
          <div className="carousel2">
            <img src={image1} alt="" className="flightimage"/>
          </div>
          <div className="carousel2">
            <img src={image2} alt="" className="flightimage" />
          </div>
          <div className="carousel2">
            <img src={image3} alt="" className="flightimage" />
          </div>
          <div className="carousel2">
            <img src={image4} alt="" className="flightimage" />
          </div>
          <div className="carousel2">
            <img src={image5} alt="" className="flightimage" />
          </div>
          <div className="carousel2">
            <img src={image6} alt="" className="flightimage" />
          </div>
        </Slider>

</div>
{/* about us section start */}
<div className="aboutuscontainer">
  {/* about us section column 1 start */}
  <div className="aboutuscontainerrow1">
  <h2>About Us</h2><br />
<p className='aboutustitle1'>Explore all corners of the world with us.</p><br />
<p className='aboutussubtitle1'>Embark on a journey with us to explore all corners of the world. From iconic landmarks to hidden gems, our global destinations, comprehensive routes, and personalized travel experiences ensure that your adventure knows no bounds. Join us in discovering the diverse wonders our planet has to offer!</p>
<ul>
    <li className='aboutussubtitle2'>
        <strong style={{color:'#007BFF',fontSize:'1.2rem'}}><span className="icon">🌍</span>Global Destinations</strong><br /> Highlight the extensive range of destinations your airline or travel service covers. Emphasize that customers can choose from a wide array of locations across continents, providing them with the opportunity to explore diverse cultures and landscapes.
    </li>
    <li className='aboutussubtitle2'>
        <strong style={{color:'#007BFF',fontSize:'1.2rem'}}><span className="icon">🌐</span>Comprehensive Routes</strong><br /> Lorem Ipsum is available, but the majority have suffered alteration in some. We believe in providing affordable and budget-friendly travel options.
    </li>
    <li className='aboutussubtitle2'>
        <strong style={{color:'#007BFF',fontSize:'1.2rem'}}><span className="icon">🏞️</span>Adventure and Exploration</strong><br /> Lorem Ipsum is available, but the majority have suffered alteration in some. Count on us for reliable and well-organized tours that meet your expectations.
    </li>
    <li className='aboutussubtitle2'>
        <strong style={{color:'#007BFF',fontSize:'1.2rem'}}><span className="icon">✈️</span>Personalized Travel Experiences</strong><br /> Lorem Ipsum is available, but the majority have suffered alteration in some. Count on us for reliable and well-organized tours that meet your expectations.
    </li>
</ul>
<p>Embark on unforgettable adventures with our dedicated team. Your journey starts here!</p>
  </div>
  <div className="aboutuscontainerrow2">
  </div>
</div>
{/* popular flight route sextion start */}
<div className="populerbusroutecontainer">
{/* popular flight route sextion 2 rows row1 start */}
<div className="populerbusrouteheading">
  Popular Bus Routes
</div>
{/* popular flight route sextion 2 rows row1 end */}
{/* popular flight route sextion 2 rows row2 start */}
<div className="populerbusroute">
  <div className="populerbusroutebox">
 <div className="populerbusrouteboxcol1"><img src={backgroundslider1} alt="" className='populerflightimage'/></div>
 <div className="populerbusrouteboxcol2"> <strong>Mumbai Flights</strong> <p style={{fontSize:'0.8rem'}}>To: Goa, Delhi, Bangalore, Ahmedabad</p>  </div>
    </div>
  <div className="populerbusroutebox">
    <div className="populerbusrouteboxcol1"><img src={backgroundslider1} alt="" className='populerflightimage'/></div>
    <div className="populerbusrouteboxcol2"><strong>Delhi Flights</strong>  <p style={{fontSize:'0.8rem'}}>To: Goa, Delhi, Bangalore, Ahmedabad</p>   </div>
  </div>
  <div className="populerbusroutebox">
    <div className="populerbusrouteboxcol1"><img src={backgroundslider2} alt="" className='populerflightimage'/></div>
    <div className="populerbusrouteboxcol2"><strong>Kolkata Flights</strong>   <p style={{fontSize:'0.8rem'}}>To: Goa, Delhi, Bangalore, Ahmedabad</p>   </div>
  </div>
  <div className="populerbusroutebox">
    <div className="populerbusrouteboxcol1"><img src={backgroundslider3} alt="" className='populerflightimage'/></div>
    <div className="populerbusrouteboxcol2"><strong>Chennai Flights</strong>   <p style={{fontSize:'0.8rem'}}>To: Goa, Delhi, Bangalore, Ahmedabad</p>   </div>
  </div>
  <div className="populerbusroutebox">
    <div className="populerbusrouteboxcol1"><img src={backgroundslider4} alt="" className='populerflightimage'/></div>
    <div className="populerbusrouteboxcol2"><strong>Hyderabad Flights</strong> <p style={{fontSize:'0.8rem'}}>To: Goa, Delhi, Bangalore, Ahmedabad</p>    </div>
  </div>
  <div className="populerbusroutebox">
    <div className="populerbusrouteboxcol1"><img src={backgroundslider5} alt="" className='populerflightimage'/></div>
    <div className="populerbusrouteboxcol2"><strong>Ahemdabad Flights</strong> <p style={{fontSize:'0.8rem'}}>To: Goa, Delhi, Bangalore, Ahmedabad</p>    </div>
  </div>
  <div className="populerbusroutebox">
    <div className="populerbusrouteboxcol1"><img src={backgroundslider1} alt="" className='populerflightimage'/></div>
    <div className="populerbusrouteboxcol2"><strong>Bangalore Flights</strong> <p style={{fontSize:'0.8rem'}}>To: Goa, Delhi, Bangalore, Ahmedabad</p>       </div>
  </div>
  <div className="populerbusroutebox">
    <div className="populerbusrouteboxcol1"><img src={backgroundslider2} alt="" className='populerflightimage'/></div>
    <div className="populerbusrouteboxcol2"><strong>Pune Flights</strong>      <p style={{fontSize:'0.8rem'}}>To: Goa, Delhi, Bangalore, Ahmedabad</p>    </div>
  </div>
  <div className="populerbusroutebox">
    <div className="populerbusrouteboxcol1"><img src={backgroundslider3} alt="" className='populerflightimage'/></div>
    <div className="populerbusrouteboxcol2"><strong>Goa Flights</strong>       <p style={{fontSize:'0.8rem'}}>To: Goa, Delhi, Bangalore, Ahmedabad</p></div>
  </div>
</div>
{/* popular flight route sextion 2 rows row2 end */}
</div>
{/* popular flight route sextion end */}
{/* faq section */}
<div className="buscontainerfifthrow">
<div className="faqcontainer">
    <h1>Frequently Asked Questions</h1>
    <details>
    <summary>How do I create accordion?</summary>
    <div>
   The tags <em>details</em> and <em>summary</em> have you covered.
    </div>
  </details>
  <details>
      <summary>How do I create accordion?</summary>
      <div>
     The tags <em>details</em> and <em>summary</em> have you covered.
      </div>
    </details>
    <details>
    <summary>How do I create accordion?</summary>
    <div>
   The tags <em>details</em> and <em>summary</em> have you covered.
    </div>
  </details>
  <details >
    <summary>FAQ 1</summary>
    <div class="faq__content">
      <p>Answer 1</p>
    </div>
  </details>
  <details>
    <summary>FAQ 2</summary>
    <div class="faq__content">
      <p>Answer 2</p>
    </div>
  </details>
  <details>
    <summary>FAQ 3</summary>
    <div class="faq__content">
      <p>Answer 3</p>
    </div>
  </details>
  </div>
</div>
{/* faq section end */}
{/* faq section end */}
<div className="faqdetails">
<h2 className='faqdetailsh2'> Bus Ticket Booking</h2>
  <ul className='faqdetailsul'>
    <li className='faqdetailsli'>Tripinbudget offers a user-friendly interface designed to simplify the process of searching and booking flights. Our platform boasts a comprehensive database comprising both domestic and international flights, encompassing a wide range of carriers worldwide. The seamless experience provided by Tripinbudget ensures efficient and convenient flight bookings for users.</li>
    <li className='faqdetailsli'>Benefit from each airline reservation made through Tripinbudget, and maximize your savings by leveraging your Tripinbudget funds for subsequent bookings. Our platform is committed to delivering cost-effective solutions, allowing you to secure cheaper airline tickets and enhance your overall travel experience.</li>
  </ul>

  <h2 className='faqdetailsh2'>Cheap Bus Ticket</h2>
  <ul className='faqdetailsul'>
    <li className='faqdetailsli'>Tripinbudget offers cost-effective airfares for both domestic and international destinations.</li>
    <li className='faqdetailsli'>To secure the best savings on flights, exclusive offers, and discounts, Tripinbudget meticulously compiles and compares travel data, including rates, availability, and future travel dates.</li>
    <li className='faqdetailsli'>Discover more affordable airline tickets with ease compared to other websites.</li>
    <li className='faqdetailsli'>Tripinbudget facilitates swift savings on airline tickets through strategic partnerships with various banks, airlines, and businesses. With Tripinbudget's innovative fare alert tool, you can conveniently book both local and international airline tickets online, enjoying savings of up to 40%.</li>
    <li className='faqdetailsli'>For those seeking budget-friendly options, Tripinbudget provides Special Fares tailored for specific categories such as Students, Senior Citizens, and Armed Forces personnel. Opting for these Special Fares allows you to capitalize on substantial savings while selecting from a range of available flight options. Make your travel experience efficient, economical, and enjoyable with Tripinbudget.</li>
  </ul>

  <h2 className='faqdetailsh2'>Domestic Bus Booking</h2>
  <blockquote>
    As a premier platform in India, Tripinbudget stands out for providing economical flight options for both domestic and international travel. Our platform ensures that you can effortlessly discover a cost-effective flight that aligns with your vacation requirements, thanks to our extensive array of flight alternatives offered by various airlines.
    Moreover, Tripinbudget facilitates the acquisition of affordable airline tickets, allowing you to efficiently compare flight costs. For a seamless and hassle-free travel experience, we recommend booking flights with leading domestic airlines such as Air India, Vistara, Indigo Airlines, Spicejet, AirAsia, Go First Flights, and Air-India Express.
  </blockquote>
</div>
</div>

// main container end
  );
}