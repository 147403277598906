
// import logo from '../Images/TripInBudget Logo New.png';
// import React from 'react'
// import '../Components/navbar.css'
// import FlightIcon from '@mui/icons-material/Flight';
// import TrainIcon from '@mui/icons-material/Train';
// import DirectionsBusFilledIcon from '@mui/icons-material/DirectionsBusFilled';
// export default function Navbar() {
//     const handleButtonClick = () => {
//         window.open('https://calendly.com/insecsys', '_blank');
//       };
    
 
//   return (
//     <div>
//         <header>
//             <div className="topheader"> <div class="topdiv">
//         <div class="topdiv1"></div>
//         <div class="topdiv2">
//             <div class="marquee-content">
//                <marquee>Jet Set Go: Unlock Your Journey with Exclusive Deals! 🌍✈️🚆🏨 Book Flights, Trains, Buses, and Hotels with Unbeatable Offers! #TravelGoals #AdventureAwaits</marquee>
//             </div>
//         </div>
//         <div class="topdiv3">
     
//         </div>
//     </div>
//  </div>
//         <div class="newheadercontainer">
//         <div class="top-bar"></div>
//             <input type="checkbox" name="" id="check"/>

//             <div class="newheaderlogo-container">
//             <a href="/">
//               <img src={logo} alt="" className="navlogo-img" />
//             </a>
//             </div>
//             <div class="nav-btn">
//                 <div class="nav-links">
//                     <ul>
//                     <li class="nav-link" style={{ "--i": "1.35s" }}>
//                             <a href="#"> <FlightIcon style={{fontSize:'2rem'}}/>Flights</a>
//                         </li> <li class="nav-link" style={{ "--i": "1.35s" }}>
//                             <a href="#"><TrainIcon style={{fontSize:'2rem'}}/>Trains</a>
//                         </li> 
                       
//                         <li class="nav-link" style={{ "--i": "1.35s" }}>
//                             <a href="#"> <DirectionsBusFilledIcon style={{fontSize:'2rem'}}/>Buses</a>
//                         </li> <li class="nav-link" style={{ "--i": "1.35s" }}>
//                             <a href="#">Hotels</a>
//                         </li> 
                      
//                         <li class="nav-link" style={{ "--i": "1.35s" }}>
//                             <a href="/contactus">Offers</a>
//                         </li>
                        
//                     </ul>
//                 </div>
//             </div>
//             <div class="hamburger-menu-container">
//                 <div class="hamburger-menu">
//                     <div></div>
//                 </div>
//             </div>
//         </div>
//     </header>
//     </div>
//   )
// }

import logo from '../Images/TripInBudget Logo New.png';
import React, { useState, useEffect } from 'react';
import '../Components/navbar.css';
import '@fortawesome/fontawesome-free/css/all.css';

export default function Navbar() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // Check local storage on component mount to set initial login status
  useEffect(() => {
    const storedLoggedInStatus = localStorage.getItem('isLoggedIn');
    if (storedLoggedInStatus) {
      setIsLoggedIn(true);
    }
  }, []);

  const handleLoginLogout = () => {
    // Toggle login status
    setIsLoggedIn((prev) => !prev);

    // Update local storage with login status
    localStorage.setItem('isLoggedIn', !isLoggedIn);
  };

  const handleLogout = () => {
    // Clear local storage and set login status to false
    localStorage.removeItem('isLoggedIn');
    setIsLoggedIn(false);
  };
    
 
  return (
    <div>
        <header>
            <div className="topheader"> <div class="topdiv">
        <div class="topdiv1"></div>
        <div class="topdiv2">
            <div class="marquee-content">
               <marquee>Jet Set Go: Unlock Your Journey with Exclusive Deals! 🌍✈️🚆🏨 Book Flights, Trains, Buses, and Hotels with Unbeatable Offers! #TravelGoals #AdventureAwaits</marquee>
            </div>
        </div>
        {/* dub div 3 for space and login button */}
        <div class="topdiv3">
     
        </div>
    </div>
 </div>
        <div class="newheadercontainer">
        <div class="top-bar"></div>
            <input type="checkbox" name="" id="check"/>

            <div class="newheaderlogo-container">
            <a href="/">
              <img src={logo} alt="" className="navlogo-img" />
            </a>
            </div>
           

<div className="nav-btn">
          <div className="nav-links">
            <ul className="left-links">
              <li className="nav-link" style={{ "--i": "1.35s" }}>
              <a href="/"><i className="fas fa-plane" style={{color:'white',fontSize:'1.5rem'}}></i>
                 Flights</a>
              </li>
              <li className="nav-link" style={{ "--i": "1.35s" }}>
              <a href="/trains"> <i className="fas fa-train" style={{colo:'white',fontSize:'1.5rem'}}></i>
                Trains</a>
              </li>
              <li className="nav-link" style={{ "--i": "1.35s" }}>
              <a href="/buses"> <i className="fas fa-bus" style={{color:'white',fontSize:'1.5rem'}}></i>
                Buses</a>
              </li>
              <li className="nav-link" style={{ "--i": "1.35s" }}>
              <a href="/hotels"> <i className="fas fa-hotel" style={{color:'white',fontSize:'1.5rem'}}></i>
                Hotels</a>
              </li>
            </ul>
            <ul className="right-links">
              <li className="nav-link" style={{ "--i": "1.35s" }}>
                <a href="/offers"><i className="fas fa-percent" style={{color:'white',fontSize:'1.5rem'}}></i>
Offers</a>
              </li>
              <li className="nav-link" style={{ "--i": "1.35s" }}>
                <a href="/customer-service"><i className="fa-solid fa-phone-volume" style={{color:'white',fontSize:'1.5rem'}}></i>Customer Service</a>
              </li>
              
              <li className="nav-link" style={{ "--i": "1.35s" }}>
              {isLoggedIn?(
                
                <a href="/" onClick={handleLogout}>
                <i className="fa-solid fa-user" style={{ color: 'white', fontSize: '1.5rem' }}></i>
                Logout
              </a> 
                ):(
                  <a href="/login"><i className="fa-solid fa-user" style={{color:'white',fontSize:'1.5rem'}}></i>Login/SignUp</a>
                )}
              </li>
            </ul>
          </div>
        </div>


            <div class="hamburger-menu-container">
                <div class="hamburger-menu">
                    <div></div>
                </div>
            </div>
        </div>
    </header>
    </div>
  )
}
