// import '../Bus/busavailable.css'
// import React, { useState } from 'react';
// import Select from 'react-select';

// const TrainAvailable = () => {
//   const [originCityCode, setOriginCityCode] = useState('');
//   const [destinationCityCode, setDestinationCityCode] = useState('');

//   const [travelDate, setTravelDate] = useState('');
  
//   const [travller, setTravller] = useState('');
//   const [seatType, setSeatType] = useState('');

//   const originOptions = [
//     { value: 'NYC', label: 'New York City' },
//     { value: 'LAX', label: 'Los Angeles' },
//     { value: 'SFO', label: 'San Francisco' },
//     // Add more city options as needed
//   ];

//   const seatTypeOptions = [
//     { value: 'Seater', label: 'Seater' },
//     { value: 'Sleeper', label: 'Sleeper' },
//     { value: 'Semi-Sleeper', label: 'Semi-Sleeper' },
//     { value: 'AC', label: 'AC' },
//     // Add more city options as needed
//   ];


//   const destinationOptions = [
//     { value: 'NYC', label: 'New York City' },
//     { value: 'LAX', label: 'Los Angeles' },
//     { value: 'SFO', label: 'San Francisco' },
//     // Add more city options as needed
//   ];

//   const handleSubmit = () => {
//     // Handle form submission here
//   };

//   return (
//     <div className="hotelcontainer">
//       <div className="dummy2"></div>
//       <div className="hotelcontainerrow">
//         <div className="flightcol">
//           <label className="form-label"> From City </label>
//           <Select
//             options={originOptions}
//             value={originCityCode}
//             onChange={(selectedOption) => setOriginCityCode(selectedOption)}

//             placeholder="Select City"
//           />
    
//         </div>

//         <div className="flightcol">
//           <label className="form-label"> To City </label>
//           <Select
//             options={destinationOptions}
//             value={destinationCityCode}
//             onChange={(selectedOption) => setDestinationCityCode(selectedOption)}
            
//             placeholder="Select City"
//           />
//         </div>

//         <div className="flightcol">
//           <label className="form-label">TravelDate</label>
//           <input
//             type="date"
//             className="form-control"
//             value={travelDate}
//             onChange={(e) => setTravelDate(e.target.value)}
//           />
//         </div>
        
//         <div className="flightcol">
//           <label className="form-label">No of Travller</label>
//           <input
//             type="number"
//             className="form-control"
//             value={travller}
//             onChange={(e) => setTravller(e.target.value)}
//           />
//         </div>
//         <div className="flightcol">
//           <label className="form-label">Seat Selection</label>
//           <Select
//             options={seatTypeOptions}
//             value={seatType}
//             onChange={(selectedOption) => setSeatType(selectedOption)}

//             placeholder="Seat Select"
//           />
//         </div>
//         <div className="flightcol">
//           <button onClick={handleSubmit} className="btn btn-primary">Fetch Hotels</button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default TrainAvailable;
// import React, { useState, useEffect } from 'react';
// import axios from 'axios';

// function TrainAvailable() {
//   const [searchTerm, setSearchTerm] = useState('');
//   const [stations, setStations] = useState([]);
//   const [error, setError] = useState(null);
//   const [originCitycode, setOriginCitycode] = useState('');
//   const handleInputChange = (e) => {
//     setOriginCitycode(e.target.value);
//   };
// useEffect(() => {
//     const fetchData = async () => {
//       if (!originCitycode) {
//         return;
//       }
  
//       const options = {
//         method: 'POST',
//         url: 'http://localhost:3001/stations',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         data: { search: originCitycode },
//       };
  
//       try {
//         const response = await axios.request(options);
//         const { station_code, station_name } = response.data;
//         const station = { code: station_code, name: station_name };
//         setStations([station]); // Ensure stations is an array with one element
//         setError(null);
//       } catch (error) {
//         setError(error);
//         setStations([]);
//       }
//     };
  
//     fetchData();
//   }, [originCitycode]);
  

//   return (
//     <div>
//       <div className="dummy2"></div>
//       <div className='availablecontaineronly'>
//         <div className="availablecontainerrowonly">
        
//             <div className="flightcol">
//             <input
//             className='form-control'
//             list="cityOptions" 
//             placeholder="Select City or Type"
//             onChange={handleInputChange}
//             />
//             <datalist id="cityOptions"> 
//            {Array.isArray(stations) && stations.map(({ code, name }) => (
//             <option key={code} value={name} />
//             ))}
//             </datalist>
                                                                                                    
//             </div>
//           <div className="flightcol">
//             <button className="btn btn-primary" >Search</button>
//           </div>
//         </div>
//       </div>

//       <div className="dummy2"></div>
//     </div>
//   );
// }

// export default TrainAvailable;



// // working with datalist



// // simple input feild and added api on oninputchange
// import '../Bus/busavailable.css';
// import React, { useState, useEffect } from 'react';
// import axios from 'axios';

// const TrainAvailable = () => {
//   const [search, setSearch] = useState('');
//   const [stationData, setStationData] = useState(null);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.post('http://localhost:3001/stations', {
//           search: search
//         });
//         setStationData(response.data);
//       } catch (error) {
//         console.error('Error:', error);
//       }
//     };

//     // Fetch data only if search is not empty
//     if (search !== '') {
//       fetchData();
//     }
//   }, [search]); // This effect will re-run whenever the `search` state changes

//   const handleInputChange = (e) => {
//     setSearch(e.target.value);
//   };

//   return (
//     <div className="hotelcontainer">
//       <div className="dummy2"></div>
//       <div className="hotelcontainerrow">
//         <div className="flightcol">
//           <label className="form-label"> From City </label>
//           <input type="text" className="form-control" value={search} onChange={handleInputChange} />
//           {stationData && <p>{stationData.station_code}-{stationData.station_name}</p>}
//         </div>
//        <div className="flightcol">
//         <button className="btn btn-primary">feftch</button>
//        </div>
//       </div>
//     </div>
//   );
// };

// export default TrainAvailable;


// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import Select from 'react-select';

// function TrainAvailable() {
//   const [originStations, setOriginStations] = useState([]);
//   const [destinationStations, setDestinationStations] = useState([]);
//   const [error, setError] = useState(null);
//   const [originCitycode, setOriginCitycode] = useState('');
//   const [destinationCityCode, setDestinationCityCode] = useState('');
//   const [travelDate, setTravelDate] = useState('');
//   const [traveller, setTraveller] = useState('');
//   const [seatType, setSeatType] = useState('');
  

//   const handleOriginInputChange = async (e) => {
//     const originCitycode = e.target.value;
//     setOriginCitycode(originCitycode);
//     if (!originCitycode) {
//       setOriginStations([]);
//       return;
//     }
//     try {
//       const response = await axios.post('http://localhost:3001/stations', { search: originCitycode });
//       const { station_code, station_name } = response.data;
//       const station = { code: station_code, name: station_name };
//       setOriginStations([station]);
//       setError(null);
//     } catch (error) {
//       setError(error);
//       setOriginStations([]);
//     }
//   };

//   const handleDestinationInputChange = async (e) => {
//     const destinationCityCode = e.target.value;
//     setDestinationCityCode(destinationCityCode);
//     if (!destinationCityCode) {
//       setDestinationStations([]);
//       return;
//     }
//     try {
//       const response = await axios.post('http://localhost:3001/stations', { search: destinationCityCode });
//       const { station_code, station_name } = response.data;
//       const station = { code: station_code, name: station_name };
//       setDestinationStations([station]);
//       setError(null);
//     } catch (error) {
//       setError(error);
//       setDestinationStations([]);
//     }
//   };

//   const seatTypeOptions = [
//     { value: 'First Class', label: 'First Class' },
//     { value: 'Second Class', label: 'Second Class' },
//     { value: 'Third Class', label: 'Third Class' },
//     { value: 'Sleeper Class', label: 'Sleeper Class' },
//     { value: 'Executive Class', label: 'Executive Class' },
//     { value: 'Business Class', label: 'Business Class' },
//     { value: 'Economy Class', label: 'Economy Class' },
//     { value: 'Premium Economy Class', label: 'Premium Economy Class' }
//   ];
  

//   return (
//     <div className="hotelcontainer">
//       <div className="dummy2"></div>
//       <div className="hotelcontainerrow">
//         <div className="flightcol">
//           <label className="form-label"> From City </label>
//           <input
//             className='form-control'
//             list="originCityOptions"
//             placeholder="Select City or Type"
//             value={originCitycode}
//             onChange={handleOriginInputChange}
//           />
//           <datalist id="originCityOptions" className='form-control'>
//             {Array.isArray(originStations) && originStations.map(({ code, name }) => (
//               <option key={code} value={`${code}-${name}`} />
//             ))}
//           </datalist>
//         </div>

//         <div className="flightcol">
//           <label className="form-label"> To City </label>
//           <input
//             className='form-control'
//             list="destinationCityOptions"
//             placeholder="Select City or Type"
//             value={destinationCityCode}
//             onChange={handleDestinationInputChange}
//           />
//           <datalist id="destinationCityOptions" className='form-control'>
//             {Array.isArray(destinationStations) && destinationStations.map(({ code, name }) => (
//               <option key={code} value={`${code}-${name}`} />
//             ))}
//           </datalist>
//         </div>

//         <div className="flightcol">
//           <label className="form-label">Travel Date</label>
//           <input
//             type="date"
//             className="form-control"
//             value={travelDate}
//             onChange={(e) => setTravelDate(e.target.value)}
//           />
//         </div>
//         <div className="flightcol">
//           <label className="form-label">No of Traveller</label>
//           <input
//             type="number"
//             className="form-control"
//             value={traveller}
//             onChange={(e) => setTraveller(e.target.value)}
//           />
//         </div>
//         <div className="flightcol">
//           <label className="form-label">Class</label>
//           <Select
//             options={seatTypeOptions}
//             value={seatType}
//             onChange={(selectedOption) => setSeatType(selectedOption)}
//             placeholder="Seat Select"
//           />
//         </div>

//         <div className="flightcol">
//           <button className="btn btn-primary">Search</button>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default TrainAvailable;



// // all code working with datalist and backend offlile station codes
import React, { useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';


function TrainAvailable() {
  const [originStations, setOriginStations] = useState([]);
  const [destinationStations, setDestinationStations] = useState([]);
  const [error, setError] = useState(null);
  const [originCitycode, setOriginCitycode] = useState('');
  const [destinationCityCode, setDestinationCityCode] = useState('');
  const [travelDate, setTravelDate] = useState('');
  const [traveller, setTraveller] = useState(1);
  const [seatType, setSeatType] = useState('');
  const [showDialog, setShowDialog] = useState(false); 
  const [showLoginDialog, setShowLoginDialog] = useState(false); 
  const [selectedData, setSelectedData] = useState(null); 
  const navigate=useNavigate();
  const handleLoginClick=()=>{
    navigate('/login');
  }
  const handleOriginInputChange = async (e) => {
    const originCitycode = e.target.value;
    setOriginCitycode(originCitycode);
    if (!originCitycode) {
      setOriginStations([]);
      return;
    }
    try {
      // Make requests to both APIs
      const [response1, response2] = await Promise.all([
        axios.post('http://localhost:3001/stations', { search: originCitycode }),
        axios.post('http://localhost:3001/fetch-stations', { search: originCitycode })
      ]);
      
      // Extract data from responses
      const station1 = { code: response1.data.station_code, name: response1.data.station_name };
      const station2 = { code: response2.data.station_code, name: response2.data.station_name };
  
      // Update origin stations with data from both APIs
      setOriginStations([station1, station2]);
      setError(null);
    } catch (error) {
      setError(error);
      setOriginStations([]);
    }
  };
  
  const handleDestinationInputChange = async (e) => {
    const destinationCityCode = e.target.value;
    setDestinationCityCode(destinationCityCode);
    if (!destinationCityCode) {
      setDestinationStations([]);
      return;
    }
    try {
      const [response1, response2] = await Promise.all([
        axios.post('http://localhost:3001/stations', { search: destinationCityCode }),
        axios.post('http://localhost:3001/fetch-stations', { search: destinationCityCode })
      ]);
      
      // Extract data from responses
      const station1 = { code: response1.data.station_code, name: response1.data.station_name };
      const station2 = { code: response2.data.station_code, name: response2.data.station_name };
  
      // Update origin stations with data from both APIs
      setDestinationStations([station1, station2]);
      setError(null);
    } catch (error) {
      setError(error);
      setDestinationStations([]);
    }
  };

  const seatTypeOptions = [
    { value: 'First Class', label: 'First Class' },
    { value: 'Second Class', label: 'Second Class' },
    { value: 'Third Class', label: 'Third Class' },
    { value: 'Sleeper Class', label: 'Sleeper Class' },
    { value: 'Executive Class', label: 'Executive Class' },
    { value: 'Business Class', label: 'Business Class' },
    { value: 'Economy Class', label: 'Economy Class' },
    { value: 'Premium Economy Class', label: 'Premium Economy Class' }
  ];

  const handleSearchClick = () => {
    if (!originCitycode || !destinationCityCode || !travelDate || !traveller || !seatType) {
      // setErrorMessage('All fields are mandatory');
      toast.error('All fields are mandatory');
      return;
    }
    const isLoggedIn=localStorage.getItem('isLoggedIn');
    if(isLoggedIn){
    const selectedData = {
      originCitycode,
      destinationCityCode,
      travelDate,
      traveller,
      seatType: seatType.value 
    };
    axios.post('http://localhost:3001/api/userdata', { email: localStorage.getItem('email') })
    .then(response => {
      const userData = response.data.userData;
      // store response name,email,mobile in variable userdata array
      setSelectedData({ ...selectedData, userData });
      setShowDialog(true);
    })
    .catch(error => {
      console.error('Error fetching user data:', error);
    });
  }else{
    setShowLoginDialog(true);
  }
  };
  const handleDialogClose = () => {
    setShowDialog(false);
  };



  
  const handleTrainDataSend = () => {
    axios.post('http://localhost:3001/send-train-data', {
      selectedData: selectedData,
      userData: selectedData.userData
    })
    .then(response => {
      console.log('Email sent successfully');
      toast.success('One of our members will contact you shortly', {
        onClose: handleDialogClose // Close the dialog box after the toast is closed
      });
      setTimeout(() => {
        setShowDialog(false);
      }, 2000);
      // Add any further logic here if needed
    })
    .catch(error => {
      console.error('Error sending email:', error);
    });
  };
  

  return (
    <div className="hotelcontainer">
      <div className="dummy2"></div>
      <div className="traincontainerrow">
        <div className="flightcol">
          <label className="form-label"> From City </label>
          <input
            className='form-control'
            list="originCityOptions"
            placeholder="Select City or Type"
            value={originCitycode}
            onChange={handleOriginInputChange}
          />
          <datalist id="originCityOptions" className='form-control'>
            {Array.isArray(originStations) && originStations.map(({ code, name }) => (
              <option key={code} value={`${code}-${name}`} />
            ))}
          </datalist>
        </div>

        <div className="flightcol">
          <label className="form-label"> To City </label>
          <input
            className='form-control'
            list="destinationCityOptions"
            placeholder="Select City or Type"
            value={destinationCityCode}
            onChange={handleDestinationInputChange}
          />
          <datalist id="destinationCityOptions" className='form-control'>
            {Array.isArray(destinationStations) && destinationStations.map(({ code, name }) => (
              <option key={code} value={`${code}-${name}`} />
            ))}
          </datalist>
        </div>

        <div className="flightcol">
          <label className="form-label">Travel Date</label>
          <input
            type="date"
            className="form-control"
            value={travelDate}
            onChange={(e) => setTravelDate(e.target.value)}
          />
        </div>
        <div className="flightcol">
          <label className="form-label">No of Traveller</label>
          <input
            type="number"
            className="form-control"
            value={traveller}
            onChange={(e) => setTraveller(e.target.value)}
          />
        </div>
        <div className="flightcol">
          <label className="form-label">Class</label>
          <Select
            options={seatTypeOptions}
            value={seatType}
            onChange={(selectedOption) => setSeatType(selectedOption)}
            placeholder="Seat Select"
          />
        </div>

        <div className="flightcol">
          <button className="btn btn-primary" onClick={handleSearchClick}>Search</button>
        </div>
      </div>
      {showLoginDialog && (
        <div className="dialog-container">
          <div className="dialog">
           <p>please login/Register to complete the booking</p>
            <div className="dialog-buttons">
              <button className="btn btn-primary" onClick={handleLoginClick}>Login</button>
              <button className="btn btn-secondary" onClick={handleDialogClose}>Close</button>
            </div>
          </div>
        </div>
      )}
      {showDialog && (
        <div className="dialog-container">
           <div className="dialog">
           <table>
              <tbody>
              <tr>
              <th className='table-td'>Category</th>
              <th className='table-td'>Details</th>
            </tr>
            {selectedData.userData && (
            <>
            
              <tr>
                <td className='table-td'>Name</td>
                <td>{selectedData.userData.name}</td>
              </tr>
              <tr>
                <td className='table-td'>Email</td>
                <td>{selectedData.userData.email}</td>
              </tr>
              <tr>
                <td className='table-td'>Mobile</td>
                <td>{selectedData.userData.mobile}</td>
              </tr>
            </>
          )}
                <tr>
                  <td className='table-td'>Origin City</td>
                  <td>{selectedData.originCitycode}</td>
                </tr>
                <tr>
                  <td className='table-td'>Destination City</td>
                  <td>{selectedData.destinationCityCode}</td>
                </tr>
                <tr>
                  <td className='table-td'>Travel Date</td>
                  <td>{selectedData.travelDate}</td>
                </tr>
                <tr>
                  <td className='table-td'>No Of Traveller</td>
                  <td>{selectedData.traveller}</td>
                </tr>
                <tr>
                  <td className='table-td'>Seat Type</td>
                  <td>{selectedData.seatType}</td>
                </tr>
              </tbody>
            </table>
            <div className="dialog-buttons">
              <button onClick={handleDialogClose} className="btn btn-secondary">Close</button>
              <button  className="btn btn-secondary" onClick={handleTrainDataSend}>Submit</button>
            </div>
        </div>
        </div>
      )}
    </div>
  );
}

export default TrainAvailable;
