import React from 'react'

export default function TermsAndCondition() {
  return (
    <div className="privacy-container">
      <div className="privacy-main">
        <h2>Terms of Service</h2>
        <p>
This web site <a href="https://tripinbudget.in"> https://tripinbudget.in</a> (the “Site”) including the mobile site, Smartphone App Platforms such as iOS, Android, Windows is published and maintained by tripinbudget.com (“the Company”), a company incorporated and existing in accordance with the laws of the country of incorporation (together with its subsidiaries and other affiliates, “us”, “We”, “our” or “the Company”). By accessing or using the Site or by using the Service on the Site you agree to be bound by these terms and conditions set forth below including any additional guidelines and future modifications. When you access any sub-site, webpage, hyperlink (whether belonging to an ‘associate’ of the Company or otherwise) through this site, then such sub-site may have its own terms and conditions of use which is specific to such sub-site, webpage, hyperlink. The sub-sites, webpage, hyperlink may contain such additional terms and conditions of use as may be set out in such sub-sites, webpage, hyperlink.
<br /><br />
The Company may add to, change or remove any part of these Terms and Conditions of Use at any time, without notice. Any changes to these Terms and Conditions of Use or any terms posted on this Site apply as soon as they are posted. You shall re-visit the “Terms & Conditions” link from time to time to stay abreast of any changes that the “Site” may introduce.
<br /><br />
Your Agreement to these Terms and Conditions for availing Service
<br /><br />
The Terms and Conditions is a legal contract between you being, an individual customer, user, or beneficiary of this service, and the Company having its registered office at A-1003, Gagan Arena, NIBM Road, Undri, Pune - 411060.
<br /><br />
The Company acts as an “Intermediary” solely to assist customers in gathering travel information, determining the availability of travel-related products and services, making legitimate reservations or otherwise transacting business with travel suppliers, and for facilitating travel requirements. You acknowledge that the Company merely provides intermediary services in order to facilitate services to you. The Company is not the last mile service provider to you and therefore the Company shall not be deemed to be responsible for any lack or deficiency of services provided by any person or entity including airline, hotel, activity provider or similar agency), you shall engage, hire from the content available on the Site.
<br /><br />
<h4>Use of the Website</h4>
<br />
<ul>
As a condition of your use of this Website, you warrant that:
  <li>you possess the legal authority to create a binding legal obligation and enter into these Terms of Use.
</li>
<li>you will use this Website in accordance with the Terms of Use,
</li>
<li>you will use this Website to make only legitimate reservations for you or for another person for whom you are legally authorized to act,
</li>
<li>you will inform such other persons about the Terms of Use that apply to the reservations you have made on their behalf, including all rules and restrictions applicable thereto,
</li>
<li>
you will provide your proper and accurate Name (as per Passport or any other official ID), Address, Email ID, Cell Number . You will be solely responsible for the Information provided and in case of any error or mistake in provision of information, the Company will not be liable for the same.
</li>
<li>all information supplied by you on this Website is true, accurate, current and complete,
</li>
<li>if you have an online account with this Website, you will safeguard your login details – login ID and password and will supervise the same. You will be completely responsible for any use or misuse of your account by you and anyone other person other than you.
</li>
</ul>
<br />
The Company retains the right in its sole discretion to deny access to anyone to this Website and the services we offer, at any time without notice and for any reason, including, but not limited to, for violation of these Terms of Use.


<br />
<br />
<h4>Communication Policy of the site</h4>
<br />
<ul>
  <li>Upon transacting on the Site, you will receive an e-mail from the Company informing the status of your transaction. The e-mail will be sent to e-mail address provided by you and the Company is not responsible towards the receipt of the said e-mail in your inbox. You will have to take due care and caution. You are responsible for entering the proper and accurate contact details including your name (as per Passport), email ID to ensure that we can effectively communicate with you.
</li>
<li>The customer acknowledges that the SMS (Short Messaging Service) provided by the Company is an additional facility provided for the customer’s convenience. It is not mandatory under the law in force to provide SMS service alerts to the customers. In case you do not receive any SMS for whatsoever reason, the Company is not liable for the same.
</li>
<li>The Company is not responsible to provide information on any change in flight schedules, cancellation, status of airline/hotel etc.
</li>
<li>Any grievance regarding the service should be communicated as per the grievance policy laid out herein.
</li>
</ul>
<br />
<h4>Site and its Content</h4>
<br />
This Site is only for your personal use. You shall not copy, license, adapt, distribute, exchange, modify, sell or transmit any content or material from this Site, including but not limited to any text, images, audio, video or links for any business, commercial or public purpose.
<br />
The Company grants you a non-exclusive, non-transferable, limited right to enter, view, use and transact on this Site. You agree not to interrupt or attempt to interrupt the operation of this Site in any way. Access to certain areas of the Site may only be available to registered members. To become a registered member, you may be required to answer certain questions and provide certain details. Answers to such questions and details is mandatory. You represent and warrant that all information you supply to us, about yourself, and others, is true and accurate.
<br />
You understand that except for information, products or services clearly indicated as being supplied by the Company, we do not operate, control, or endorse any information, products or services on the Internet in anyway. You also understand that the Company cannot and does not guarantee or warrant that files available for downloading through the Site will be free of viruses, worms or other code that may be damaging. You are responsible for implementing procedures to satisfy your particular requirements and for accuracy of data input and output.
<br />
The Company may add, change, discontinue, remove or suspend any other Content or services posted on this Site, including features and specifications of products described or depicted on the Site, temporarily or permanently, at any time, without notice and without liability.
<br />
<br />
<h4>Ownership</h4>
<br />
All materials on this Site, including but not limited to audio, images, software, text, icons and such like (the “Content”), are protected by copyright under international conventions and intellectual property laws. You cannot use or license the Content, except as specified herein. You agree to follow all instructions on this Site limiting the way you may use the Content.
<br />
There are a number of proprietary logos, service marks and trademarks found on this Site whether owned/used by the Company or otherwise. By displaying them on this Site, the Company is not granting you any license to utilize those proprietary logos, service marks, or trademarks. Any unauthorized use of the Content may violate copyright laws, trademark laws, the laws of privacy and publicity, civil and criminal statutes.
<br />
The content and information on this Website (including, but not limited to, price and availability of travel services), as well as the infrastructure used to provide such content and information, is proprietary to us or our suppliers and providers.
<br />
While you may make limited copies of your travel itinerary (and related documents) for travel or service reservations booked through this Website, you agree not to otherwise modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell or re-sell any information, software, products, or services obtained from or through this Website.
<br />
<br />
<h4>Availability, Rules of Service</h4>
<br />
The products and services displayed on the Site may not be available for purchase in your particular country or locality. The reference to such products and services on the Site does not imply or warrant that these products or services will be available at any time in your particular geographical location. The products and services also are subject to availability, the Company shall not be responsible if at the time of completing the transaction on the Site, the product is not available or is sold out. You should check with customer support for the availability of specific products and services in your area.
<br />
All the products and services available on the Website are governed by the rules of service and regulations including the terms of contract of carriage, of the respective service provider. You are requested to refer to, acquaint and abide by those rules of service and regulations of the respective service provider, prior to booking with us.
<br />
<br />
<h4>Visa Guidelines</h4>
<br />
Please carry a valid visa for the country you will be visiting or transiting through. Please check with the concerned airline and embassy for concerned Visa requirements
<br />
<br />
<h4>The Company’s Rights</h4>
<br />
If you send any communications or materials to the Company by electronic mail or otherwise, including any comments, data, questions, suggestions or the like, all such communications are, and will be treated by the Company, as non-confidential.
<br />
You hereby give up any and all claim that any use of such material violates any of your rights including moral rights, privacy rights, proprietary or other property rights, publicity rights, rights to credit for material or ideas, or any other right, including the right to approve the way the Company uses such material.
<br />
Any material submitted to this Site may be adapted, broadcasted, changed, copied, disclosed, licensed, performed, posted, published, sold, transmitted or used by the Company anywhere in the world, in any medium, forever.
<br />
<br />
<h4>Others’ rights</h4>
<br />
If this Site contains bulletin boards, chat rooms, blogs, access to mailing lists or other message or communication facilities, you agree to use the same only to send and receive messages and materials that are proper and related thereto. By way of example and not as a limitation, you agree that when using the Site or any facility available herefrom, you shall not do any of the following:
<br />
<ul>
  <li>Defame, abuse, harass, stalk, threaten or otherwise violate the legal rights (such as rights of privacy and publicity) of others
</li>
<li>Publish, post, distribute or disseminate any defamatory, infringing, obscene, indecent or unlawful material or information
</li>
<li>Upload or attach files that contain software or other material protected by intellectual property laws (or by rights of privacy and publicity) unless the User owns or controls the rights thereto or has received all consents thereof as may be required by law
</li>
<li>Upload or attach files that contain viruses, corrupted files or any other similar software or programs that may damage the operation of another’s computer
</li>
<li>Delete any author attributions, legal notices or proprietary designations or labels in any file that is uploaded
</li>
<li>Falsify the origin or source of software or other material contained in a file that is uploaded
</li>
<li>Advertise or offer to sell any goods or services, or conduct or forward surveys, contests or chain letters, or download any file posted by another user of a Forum that the User knows, or reasonably should know, cannot be legally distributed in such manner.
</li>
<li>use this Website or its contents for any commercial purpose
</li>
<li>make any speculative, false, or fraudulent reservation or any reservation in anticipation of demand;
</li>
<li>access, monitor or copy any content or information of this Website using any robot, spider, scraper or other automated means or any manual process for any purpose without our express written permission;
</li>
<li>violate the restrictions in any robot exclusion headers on this Website or bypass or circumvent other measures employed to prevent or limit access to this Website;
</li>
<li>take any action that imposes, or may impose, in our discretion, an unreasonable or disproportionately large load on our infrastructure;
</li>
<li>deep-link to any portion of this Website (including, without limitation, the purchase path for any travel services) for any purpose without our express written permission; or
</li>
<li>‘frame’, ‘mirror’ or otherwise incorporate any part of this Website into any other website without our prior written authorization.
</li>
<li>Not to host, display, upload, modify, publish, transmit, update or share the following:
</li>
<ol>
  <li>Information belonging to another person for which the user does not have any right.
</li>
<li>Information that is hateful, grossly harmful including harmful to minors, pedophilic, invasive of another privacy, racially, ethnically objectionable, disparaging, encouraging money laundering or gambling.
</li>
<li>Information that infringes any Patent, Trademark, Copyright or other Proprietary rights.
</li>
<li>Information that deceives or misleads the addressee about the origin of such message or communicates any information which is grossly offensive or menacing in nature.
</li>
<li>Information that threatens the unity, integrity, defense, Security or Sovereignty of the country, friendly relation with foreign states or Public order or cause incitement to the commission of any cognizable offence or prevents investigation of any offence or insulting any other nation.
</li>
<li>Information that impersonates another person.
</li>
</ol>
</ul>
<br />
If your booking or account shows signs of fraud, abuse, or suspicious activity, the Company may cancel any travel or service reservations associated with your name, email address, or account, and close any associated accounts. In addition, the Company may verify (i.e. preauthorize) your credit card. If you have conducted any fraudulent activity, the Company reserves the right to take any necessary legal action and you may be liable for monetary losses to the Company, including litigation costs and damages. To contest the cancellation of a booking or freezing or closure of an account, please contact the Company Customer Service.
<br />
The Company reserves the right to undertake all necessary steps to ensure that the security, safety and integrity of the Company’s systems as well as its client’s interests are and remain, well-protected. Towards this end, the Company may take various steps to verify and confirm the authenticity, enforceability and validity of orders placed by you.
<br />
If the Company, in its sole and exclusive discretion, concludes that the said transactions are not or do not reasonably appear to be, authentic, enforceable or valid, then the Company may cancel the said orders at any time up before the scheduled time of departure of the relevant flight or before the expected date of visit to any property booked through the Company.
<br />
<br />
<h4>Transmitted Material</h4>
<br />
Internet transmissions are never completely private or secure. You understand that any message or information you send to this Site may be read or intercepted by others unless there is a special notice that a particular message (for example, credit card information) is encrypted (sent in code). Sending a message to the Company does not cause the Company to have any special responsibility to you.
<br />
The copyright in the contents of this website belong to the Company. Accordingly the Company reserves all rights. Copying of part or all the contents of this website without permission of the Company is prohibited except to the extent that such copying/printing is necessary for the purposes of availing of the paid services provided.
<br />
<br />
<h4>Offers, Contests and interactions</h4>
<br />
This Site may contain contests that require you to send in material or information about yourself or offer prizes. Each offers, contest and interactions has its own rules, which you must read and agree to before you participate.
<br />
<br />
<h4>Disclaimer and No Warranties</h4>
<br />
You acknowledge that the Company is an intermediary and is not liable for any 3rd party (suppliers) obligations due to rates, quality, and all other instances, whether to any such subscribers or otherwise. You expressly agree that use of the services and the site is at your sole risk. It is your responsibility to evaluate the accuracy, completeness and usefulness of all opinions, advice, services, merchandise and other information provided through the site or on the internet generally. We do not warrant that the service will be uninterrupted or error-free or that defects in the site will be corrected.
<br />
The materials on this site are provided and “as available,” “with all faults” basis and without warranties or representations of any kind either expressed or implied. Information, software, products, and services contained on this website, and the inclusion or offering of any products or services on this website does not constitute any endorsement or recommendation of such products or services by the Company, the Company Affiliates and their respective suppliers. The material in this Site could include technical inaccuracies or typographical errors. the Company may make changes or improvements at any time. To the fullest extent permissible pursuant to applicable law, the Company disclaims all warranties of merchantability, relating to the information and description of the hotel , air or any other travel products displayed on this website (including, without limitation, the pricing, photographs, list of amenities, general product descriptions, etc.), much of which information is provided by the respective suppliers. Hotel or any other ratings displayed on this website are intended as only general guidelines, and the Company, the Company Affiliates and their respective suppliers do not guarantee the accuracy of the ratings. the Company, the Company Affiliates and their respective suppliers make no guarantees about the availability of specific products and services. the Company may make improvements and/or changes on this website at any time. the Company and its third party suppliers, licensors, and partners do not warrant or make any representations regarding the use or the results of the use of the services, the site or any reference sites in terms of correctness, accuracy, reliability, or otherwise.
<br />
The Company does not warrant that the functions contained in this site will be uninterrupted or error free, that defects will be corrected, or that this site or the servers that make it available are free of viruses or other harmful components, but shall endeavor to ensure your fullest satisfaction
<br />
The Company does not warrant or make any representations regarding the use of or the result of the use of the material on the site in terms of their correctness, accuracy, reliability, or otherwise, insofar as such material is derived from other service providers such as airlines, hotel owners and tour operators.
<br />
You acknowledge that this Website is provided only on the basis set out in these terms and conditions. Your uninterrupted access or use of this Website on this basis may be prevented by certain factors outside our reasonable control including, without limitation, the unavailability, inoperability or interruption of the Internet or other telecommunications services or as a result of any maintenance or other service work carried out on this Website. the Company does not accept any responsibility and will not be liable for any loss or damage whatsoever arising out of or in connection with any ability/inability to access or to use the Site.
<br />
<br />
<h4>General Provisions</h4>
<br />
<h4>Indemnification and Limitation of Liability</h4>
<br />
You agree to indemnify, save, and hold the Company, its affiliates, contractors, employees, officers, directors and agents harmless from any and all claims, losses, damages, and liabilities, costs and expenses, including without limitation legal fees and expenses, arising out of or related to your use or misuse of the Services or of the Site, any violation by you of this Agreement, or any breach of the representations, warranties, and covenants made by you herein.
<br />
In no event will the Company be liable to you for any special, indirect, incidental, consequential, punitive, reliance, or exemplary damages (including without limitation lost business opportunities, lost revenues, or loss of anticipated profits or any other pecuniary or non-pecuniary loss or damage of any nature whatsoever) arising out of or relating to (i) this agreement, (ii) the services, the site or any reference site, or (iii) your use or inability to use the services, the site (including any and all materials) or any reference sites. In no event will the Company or any of its contractors, directors, employees, agents, third party partners, licensors or suppliers’ total liability to you for all damages, liabilities, losses, and causes of action arising out of or relating to (i) this Agreement, (ii) the Services, (iii) your use or inability to use the Services or the Site (including any and all Materials) or any Reference Sites, or (iv) any other interactions with the Company, however caused and whether arising in contract, tort including negligence, warranty or otherwise, exceed the amount paid by you, if any, for using the portion of the Services or the Site giving rise to the cause of action or One Thousand Rupees (Rs. 1000), whichever is less.
<br />
<br />
<h4>Notice</h4>
<br />
The Company may provide you with notices and communications by email, regular mail or postings on the Site or by any other reasonable means. Except as otherwise set forth herein, notice to the Company must be sent by courier or registered mail to and the Company Private Limited (the Company) having its registered office at A-1003, Gagan Arena, NIBM Road, Undri, Pune - 411060
<br />
<br />
<h4>Waiver</h4>
<br />
The failure of the Company to exercise or enforce any right or provision of this Agreement will not constitute a waiver of such right or provision. Any waiver of any provision of this Agreement will be effective only if in writing and signed by the Company.
<br />
<br />
<h4>Arbitration</h4>
<br />
As the acceptance terms of Use constitute a valid and binding agreement, the Company may elect to resolve any dispute, controversy or claim arising out of or relating to this Agreement or Service provided in connection with this Agreement by binding arbitration in accordance with the provisions of the relevant laws of the land in force at such point in time. Any such dispute, controversy or claim referred to and decided by a Sole Arbitrator appointed and nominated by the Company and you by agreeing to the terms and conditions herein consent to the Arbitration. The arbitration shall be conducted in a city of the Company’s choice in IN and judgment on the arbitration award may be entered in any court having jurisdiction thereof.
<br />
Either you or We may seek any interim or preliminary relief from a court of competent jurisdiction in a city of the Company’s choice in IN, necessary to protect the rights or the property of the Company (or its agents, suppliers, and subcontractors), pending the completion of arbitration.
<br />
<br />
<h4>Governing Law and Forum for Disputes</h4>
<br />
Except as otherwise agreed by the parties or as described in the Arbitration section above, you agree that any claim or dispute you may have against the Company must be resolved by a court located in a city of the Company’s choice in IN. You agree to submit to the personal jurisdiction of the courts located within a city of the Company’s choice in IN for the purpose of litigating all such claims or disputes. This Agreement shall be governed by the laws of IN, without giving effect to any principles of conflicts of law.
<br />
<br />
<h4>Grievance Policy</h4>
<br />
The Company strongly believes in a sincere and transparent approach to its users. You trust and love us and we would never put growth before trust. This policy document aims at minimizing instances of customer complaints, grievances and disappointments via channelized approach, review and redressal.
<br />
You can file a grievance/share feedback towards the services rendered by the Company or any other issues.
<br />
For all complaints sent to service@tripinbudget.com or A-1003, Gagan Arena, NIBM Road, Undri, Pune - 411060, you will receive an acknowledgment for your complaint within 48 (forty eight) hours of the complaint being shared. For the purposes of this clause, Shitanshu Kumar is the grievance redressal officer. Furthermore, all complaints shall be redressed within 1 month of the complaint being made. You may use your Ticket Confirmation Number/ Voucher Number to follow up on the complaint being made.
<br />
In order to make the Company redressal channels more meaningful and effective, a structured system has been put in place. This system will ensure that the complaints are redressed seamlessly and well within the stipulated time frame.
<br />
<br />
<h4>How to reach the Company</h4>
<br />
You may contact us on service@tripinbudget.com, or +91-7447447990. Please provide your Trip/ Booking ID when you log a request with us.
<br />
<br />
<h4>Booking Terms & Conditions</h4>
<br />
Any booking made shall be deemed to be an offer by us to purchase the relevant accommodation and/or travel arrangements subject to these booking conditions. No contract between the booker and us shall come into existence until we Issue a booking ID Issue a booking confirmation by email or fax, and Receive payment in full.
<br />
<br />
<h4>Age Restriction
</h4>
<br />
You must be the greater of 18 or the age of majority in the laws of your jurisdiction or residence to register for, use or Book the Products or Services available on the Site.
<br />
<br />
<h4>Prices
</h4>
<br />
We reserve the right to change prices offered on our web site at any time but this will not affect the price of any bookings that have already been confirmed. The prices on the web site are shown in the currency selected and displayed with rates of exchange calculated on a daily basis. Prices may therefore differ on a daily basis to reflect any movement in exchange rate levels. When a booking is made the exchange rate will be fixed at the time the booking is created and will apply to any amendments or cancellations to items within the booking or to any items subsequently added. Exchange rates are determined by us
<br />
<br />
<h4>Hotel Room Type Information</h4>
<br />
For a description of room types please refer to the search page on the web site. There are certain types of room that are unique in selected destinations as stated here. This list is not necessarily exhaustive. German Twin rooms (sometimes also known as German Double rooms) will consist of two separate single beds attached side by side and are more similar to a Double room. Some hotels, especially in Scandinavia, may provide Twin rooms consisting of one Single bed and one Sofa bed, or Triple rooms with two single beds or double beds with one sofa bed. In North America Double or Twin rooms may contain one bed. Customers may request separate beds when reserving however separate beds are not guaranteed. Triple and Quad rooms usually contain two double beds. For hotels in Australia, North America and Asia the child will normally use existing bedding. Rollaway beds may be available for an additional charge. Breakfast is not included for a child under 12 years of age when staying free of charge. Services included in the rates are detailed on the web site and on the booking confirmation. Personal expenses such as porter services, drinks from the mini bar, meals at restaurants, tips, dry cleaning, laundry, telephone calls are not included in the prices and shall be paid directly at the accommodation or to suppliers by the customers.
<br />
<br />
<h4>Other Accommodation Information</h4>
<br />
For a description of room types please refers to the search page on the web site. The following points should be noted regarding apartment/villa accommodation. The members of the party are responsible for any damage caused by the party. The party must comply with any rules and regulations set by the accommodation provider. The party is responsible for payment of any charges made, in applicable properties, for use of facilities such as electricity, gas, water, telephone, linen and security, and for any other fees levied by the accommodation provider for the use of amenities. The total number of people (including infants and children) must not exceed the maximum occupancy shown on the web site or on our voucher confirmation, otherwise access to the accommodation may be reused or additional amounts charged on arrival. A deposit may be payable on arrival, which will normally be applied on a credit card, in order to cover any supplementary charges incurred. The website may contain a plan of the layout of the accommodation. If so, this is based on specifications provided to us by the accommodation provider. The plan will only be a general representation of the accommodation. Actual unit size, design, fixtures, furnishings and facilities may vary from those shown on the plan.
<br />
<br />
<h4>Product Information</h4>
<br />
Product information posted on our web site is based on information collected from accommodation providers and suppliers worldwide. We do not endorse or recommend any particular accommodation or travel product. Reasonable care has been taken that the content of our web site is correct but it is subject to amendment at any time without notice. All content on this web site is published in good faith but you acknowledge that we, as booking agent, cannot check the accuracy of all information provided by its suppliers. Reference should be made to our 'Site Terms'.
<br />
<br />
<h4>Star Ratings/ Gradings</h4>
<br />
<b>Hotels:</b> Star ratings are used to symbolize the overall quality, level of service, food standard and range of facilities available in any given property. The criteria applied within each country will vary depending on the specific requirements established by the relevant issuing body. Descriptions of hotels on the web site shall be referred to for information purposes only. We cannot guarantee or warrant any kind of services or level of quality offered by any accommodation provider. Some countries do not use star ratings for official categorization of accommodation.
<br />
<br />
<b>Apartments and villas: </b>Where this information is available, apartments and villas are graded according to the general standard of the accommodation. An official body does not always carry out this grading, and it is not equivalent to standard star ratings used for hotels. Grading (where available) should be regarded as a general guide only. The grading's are for information purposes only and are not a guarantee or warranty of any kind by us.
<br />
<b>Grade 1 :</b> acceptable overall level of quality. Adequate provision of furniture, furnishings and fittings.
<br />
<b>Grade 2 :</b> good overall level of quality. Good overall standard of furnishings, service and guest care.
<br />
<b>Grade 3 :</b> good overall level of quality. Good overall standard of furnishings, service and guest care.
<br />
<b>Grade 4 :</b> good to very good level of quality. Good standard of maintenance and decoration. Ample space and good quality furniture.
<br />
<b>Grade 5 :</b> excellent overall level of quality. Very good care and attention to detail will be obvious throughout.
<br />
 <b> Grade 6 :</b> exceptional overall level of quality. High levels of décor, fixtures and fittings, together with excellent standards of management efficiency and guest services. Excellent range of accessories and personal touches.
<br />
<br />
<h4>Bookings, Payments and Confirmations</h4>
<br />
All the bookings are confirmed once the same are paid and the at times there are special conditions for times when trade fairs, exhibitions, major conferences and other major events are being held at the relevant locations. These are referred to as 'Special Events' in these terms.
<br />
<br />
<h4>Special Events</h4>
<br />
Bookings made for Special Event periods may incur special booking conditions. You will be notified if these conditions apply, at the time of booking. “Special Conditions” will be advised when you view the applicable items in your basket, and clicking on this link will detail the event and specific conditions that apply including that 100% cancellation charges apply and that no refunds are available for those specific dates. Therefore, full payment must always be made by credit or debit card, on line at the time of booking for Special Event periods.
<br />
<br />
<h4>Cancellations and Amendments</h4>
<br />
Cancellations and amendments to confirmed bookings are subject to the following conditions. Cancellations notified directly to the accommodation or service provider will not be effective. Requests for cancellations and amendments must be made on the website. The notice period is calculated from 12 noon on the day of arrival at the destination.
<br />
<br />
<h4>For accommodation:</h4>
<br />
<ol>
  <li>A cooling off period of 24 hours will apply to those bookings made more than 72 hours in advance of the arrival date at the accommodation. This will allow cancellation or amendment of an item within the 24-hour period after the first confirmation of that item without any charges being applied. However, this does not apply to booking hotel accommodation arrangement during Special Event periods .
</li>
<li>For Bookings of accommodation (other than apartments and villas) during non Special Event periods
</li>
</ol>
<br />
<h4>Cancellations</h4>
<br />
Cancellation charges will be applied in relation to the notice period between notification of cancellation and the date of stay at each place of accommodation within a booking. Every booking have their own Cancellation policy and the same are shared at the time of creation of Booking.
<br />
<br />
<h4>Amendments</h4>
<br />
Amendments where either the number of rooms (irrespective of room type) or the number of nights decreases will be charged as below Customer name changes, where accepted by the accommodation provider, and reductions in child ages will incur no charges. All amendments are subject to availability. If the customer does not arrive at the accommodation on the date on which they are booked to arrive or they leave the accommodation earlier than the date on which they are booked to leave, they will only be entitled to receive any refund due calculated from the time when we receive notification. All such cancellations and amendments are subject to incurring the charge for the next twenty-four hours accommodation after our receipt of notification.  Refunds for unutilized nights or services are only on the discretion of the supplier.
<br />
<br />
<h2>For Apartment and Villa Bookings</h2>
<br />
<h4>Notice period Charges –</h4>
<br />
<ol>
  <li>Applicable cancellation or amendment rules will be shared at the time of creation of booking but it can be broadly be summarised as follows 11 days or more before day of arrival No charge  unless specified 10 days or less from day of arrival 100% cancellation charges will apply to any such booking.
</li>
<li>Any changes or modifications to such bookings within 10 days from the date of arrival will be treated as cancellations and will incur cancellation charges. The only exception to this will be where additional rooms/beds/cots/nights are added to existing confirmed accommodation or nights.
</li>
<li>For accommodation (other than apartments and villas) during Special Event periods. Customers will be notified at the time of the booking if Special Event conditions will apply. 100% cancellation charges will apply to dates of stay falling within a Special Event period. For dates of stay within the same booking, not falling within the Special Event period, our standard cancellation and amendment conditions will apply. Any amendments for dates of stay falling within a Special Event period will be treated as cancellations and will incur charges as above. The only exceptions to this will be where additional rooms/beds/cots/nights are added to existing confirmed rooms or nights. Name changes during these periods are not permitted.
</li>
<li>For apartment or villa accommodation during Special Event periods - 100% cancellation charges will apply to any apartment or villa booking. Any changes or modifications to apartment/villa bookings will be treated as cancellations and will incur cancellation charges. The only exceptions to this will be where additional rooms/beds/cots/nights are added to existing confirmed rooms or nights.
</li>
<li>For other travel arrangements:  Special conditions Where special conditions are attached to any tour, transfer or car hire you will be informed at the time of booking of the relevant cancellation conditions which will apply. For tours without special conditions cancellation charges will be applied in relation to the period between notification of cancellation and the date the service is due to commence.
</li>
</ol>
<br />
<h4>Cancellations and Amendments</h4>
<br />
Applicable cancellation or amendment rules will be shared at the time of creation of booking but it can be broadly be summarised as follows
<br />
<br />
<h4>Notice period Charges</h4>
<br />
0-72 hours 100% charges More than 72 hours No charge or unless specified Amendments will only be charged at 100% where the number of clients decreases.
<br />
<br />
<h4>Refunds</h4>
<br />
The Company will make a refund for those bookings which have been paid for in full provided those bookings are eligible for refund in accordance with the cancellation and amendment rules specified in clause 7. For those bookings originally paid for by credit or debit card via this web site, any refund will automatically process to the original account, credit or debit card account that paid for the booking and no other methods of refund payment will be possible. If the commission has already been paid to your agency, the net amount will be refunded to the credit card. Any refund on unutilized nights or services will only be on the discretion of the supplier. Refunds will take between 15 - 20 working days to be credited to the account, credit or debit card Bookings not settled by credit card or debit will be refunded using the same method as the payment.
<br />
<br />
<h4>Important Information for Sightseeing Tours</h4>
<br />
<ol>
  <li>Tips are not included. These are at the discretion of the customer.
</li>
<li>Children travelling free may not be entitled to meals and must be carried on the lap of accompanying adults if no seats are available.
</li>
<li>Suppliers of sightseeing tours reserve the right to ask any person to withdraw from any tour if they deem their acts or conduct offensive or a nuisance to other customers and there shall be no further liability.
</li>
<li>Suppliers of sightseeing tours decline any responsibility for articles forgotten or lost in their vehicles.
</li>
<li>Pick up services from accommodation are provided on some tours only, however customers are asked to reconfirm these services 24 hours before the service date directly with the supplier.
</li>
<li>Pick up times from accommodation and duration of all tours are approximate and may be subject to traffic conditions.
</li>
<li>Where accommodation pick-up is offered, suppliers will pick up from the majority of major hotels accommodation providers in the city, but not necessarily from all of them.
</li>
<li>If the supplier is unable to pick up from the requested accommodation, an alternative pick-up point will be suggested.
</li>
<li>Suppliers of sightseeing tours reserve the right to alter itineraries or to cancel the tours at short or no notice for any reason.
</li>
<li>Where it is necessary to cancel the tour, customers will be offered one of the following options: To join the requested tour at an alternative time or at an alternative date To join an alternative tour. To receive a full refund from us for the cancelled tour
</li>
</ol>

<br />
<h4>Complaints</h4>
<br />
Any complaint regarding the supply of a service should be brought to the attention of the hotel accommodation or service provider as early as possible during your customer's stay for in order for the complaint to be dealt with quickly. However if not resolved to your satisfaction it should be notified to us in writing by email within 7 days of the date of service. Any complaint received after 7 days of the date of service will not be investigated.
        </p>
      </div>
    </div>
  )
}
