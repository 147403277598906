import React,{useState} from 'react'

export default function TripInBudgetAssured() {
  const [selectedOption,setSelectedOption]=useState('flight');
  const handleOptionClick = (options) => {
setSelectedOption(options);
window.scrollTo(0, 0);
  }
  return (
    <div className="tripinbudget-assured-container">
        <div className="dummy-section"></div>

<div className="account-setting-content">
<div className="header-text"> <a style={{color:'white'}} href="/customer-service">Customer-Service </a> &nbsp;&gt;&gt; &nbsp;Account-FAQ</div>
  <div className="account-setting-content-faq">
  <div style={{ padding: '10px', textAlign: 'start' }} className="assured-options">
            <span 
              className={selectedOption === 'flight' ? 'selected' : ''} 
              onClick={() => handleOptionClick('flight')}
            >
              <i className="fas fa-plane" style={{color:'black',fontSize:'1.5rem'}}></i>&nbsp;Flight
            </span>
            | &nbsp;
            <span 
              className={selectedOption === 'train' ? 'selected' : ''} 
              onClick={() => handleOptionClick('train')}
            >
              <i className="fas fa-train" style={{colo:'black',fontSize:'1.5rem'}}></i>&nbsp;Train
            </span>
            <br />
            <hr className='assured-hr'/>
          </div>
    {selectedOption === 'flight' && (
      <>
          <details style={{marginTop:'5px'}}>
          <summary>Features of flight TripInBudget assured </summary>
          <div className='faqopen'>
          <blockquote className='faqopen-blockquote'>
            <p>TripInBudget is offering free cancellation on all new domestic flight bookings through its 'TripInBudget Assured' fares. This will benefit travellers who are unsure of their future travel and seek the flexibility of cancelling their domestic travel plans, due to any unforeseen reasons.</p>
          </blockquote>
          </div>

          </details>

          <details style={{marginTop:'5px'}}>
          <summary>Benefits of TripInBudget assured</summary>
          <div className='faqopen'>
          <blockquote className='faqopen-blockquote'>
            <p>TripInBudget assured offers free cancellation with full refund of flight fares on all new domestic flight bookings. Some key benefits include:
</p>
<ul>
  <li>- Full Refunds On Cancellation - No Limits</li>
  <li>- Premium Customer Support</li>
  <li>- Free Vouchers</li>
  <li>- Instant Refund within minutes</li>
  <li>- No Documentation Required
</li>
</ul>
          </blockquote>
          </div>
          </details>
          <details style={{marginTop:'5px'}}>
          <summary>Terms and Conditions of TripInBudget assured </summary>
          <div className='faqopen'>
          <blockquote className='faqopen-blockquote'>
            <p>Please visit the TripInBudget assured page for detailed information on the Terms and Conditions. To know more: https://www.TripInBudget.com/TripInBudget-assured
</p>
          </blockquote>
          </div>

          </details>


          <details style={{marginTop:'5px'}}>
          <summary>Claiming a refund for TripInBudget assured booking</summary>
          <div className='faqopen'>
          <blockquote className='faqopen-blockquote'>
            <p>If you have opted for Free Cancellation, you are eligible for a full refund if you cancel your booking within the following timelines:

</p>
<ul>
  <li>1. Booking was made between 10 hours - 26 hours, cancel before 8 hours</li>
  <li>2. Booking was made between 26 hours - 91 days, cancel before 24 hours</li>
 
</ul>
<p>All timelines are calculated with respect to "before the flight departure time". For further details, please refer to this link: https://www.TripInBudget.com/TripInBudget-assured</p>
          </blockquote>
          </div>
          </details>
          
          </>
    )}
    {selectedOption === 'train' && (

<>
<details style={{marginTop:'5px'}}>
<summary>Benefits of TripInBudget assured (free cancellation)</summary>
<div className='faqopen'>
<blockquote className='faqopen-blockquote'>
  <p>TripInBudget assured comes with various benefits. These benefits include:</p>
  <ul>
    <li>1. Zero cancellation fee.</li>
    <li>2. Instant refunds, no questions asked.</li>
    <li>3. 24*7 premium customer support.</li>
    <li>4. No documentation required.</li>
  </ul>
</blockquote>
</div>

</details>

<details style={{marginTop:'5px'}}>
<summary>Eligibility of Refunds on tickets cancelled post chart preparation for TripInBudget assured bookings
 </summary>
<div className='faqopen'>
<blockquote className='faqopen-blockquote'>
  <p>No refunds are applicable if the ticket is cancelled once the chart has been prepared. It is advised to cancel your booking at least 4 hours prior to the scheduled train departure or before the chart preparation (whichever is earlier), for a full refund.</p>
</blockquote>
</div>

</details>
<details style={{marginTop:'5px'}}>
    <summary>Eligibility of TripInBudget assured on tatkal tickets</summary>
    <div className='faqopen'>
      <blockquote className='faqopen-blockquote'>
        <p>TripInBudget assured (Free Cancellation) is applicable on Tatkal tickets and the benefits can be availed on such bookings.</p>
      </blockquote>
    </div>
  </details>

  <details style={{marginTop:'5px'}}>
    <summary>Partial tickets cancellation for TripInBudget assured (free cancellation) bookings</summary>
    <div className='faqopen'>
      <blockquote className='faqopen-blockquote'>
        <p>Yes, even when you opt for TripInBudget assured you can cancel your tickets partially. Please refer to the below mentioned link for IRCTC cancellation policy:</p>
        <p><a href="https://contents.irctc.co.in/en/eticketCancel.html">IRCTC Cancellation Policy</a></p>
      </blockquote>
    </div>
  </details>

  <details style={{marginTop:'5px'}}>
    <summary>Refund timeline for TripInBudget assured booking</summary>
    <div className='faqopen'>
      <blockquote className='faqopen-blockquote'>
        <p>TripInBudget will initiate a refund as soon as the booking is cancelled. However, refund timelines also depend on the original mode of payment.</p>
      </blockquote>
    </div>
  </details>

  <details style={{marginTop:'5px'}}>
    <summary>Refund amount for automatically cancelled Waitlisted TripInBudget assured booking</summary>
    <div className='faqopen'>
      <blockquote className='faqopen-blockquote'>
        <p>You are eligible for a 'base/ticket fare' refund on waitlisted auto-cancelled tickets.</p>
        <p><strong>Please Note:</strong></p>
        <ol>
          <li>This excludes the IRCTC convenience fee, agent charges, free cancellation charges and payment gateway charges.</li>
          <li>Please do not cancel a completely waitlisted ticket post chart preparation to avail assured benefits.</li>
        </ol>
      </blockquote>
    </div>
  </details>

  <details style={{marginTop:'5px'}}>
    <summary>Refund amount for automatically cancelled Tatkal Waitlisted TripInBudget assured booking</summary>
    <div className='faqopen'>
      <blockquote className='faqopen-blockquote'>
        <p>You are eligible for a 'base fare' refund on waitlisted Tatkal auto-cancelled tickets.</p>
        <p><strong>Please Note:</strong> This excludes the IRCTC convenience fee, agent charges, free cancellation charges and payment gateway charges.</p>
      </blockquote>
    </div>
  </details>

  <details style={{marginTop:'5px'}}>
    <summary>Features of train TripInBudget assured</summary>
    <div className='faqopen'>
      <blockquote className='faqopen-blockquote'>
        <p>TripInBudget is offering free cancellation on all train bookings through TripInBudget-assured fares. This will benefit travelers who are unsure of their future travel and seek the flexibility of canceling their travel plans, due to any unforeseen reasons.</p>
      </blockquote>
    </div>
  </details>
</>
    )}
        
     
       
       
        </div>
</div>
    </div>
  )
}
