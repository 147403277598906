import React, { useState } from 'react';
import axios from 'axios';
import BASE_URL from '../apiConfig';
import './phonepay.css'

function TripPayment() {
  const [amount, setAmount] = useState('');
  const [loading, setLoading] = useState(false);

  const handlePayment = () => {
    if (!amount || isNaN(amount)) {
      alert('Please enter a valid amount');
      return;
    }
    setLoading(true);
    axios.post(`${BASE_URL}/get-amount`, { userId: 123, amount })
      .then(response => {
        setLoading(false);
        // Open the payment URL in a new tab
        window.open(response.data.paymentUrl, '_blank');
      })
      .catch(error => {
        setLoading(false);
        console.error('Error:', error);
        alert('Failed to initiate payment');
      });
  };

  return (
    <div style={{padding:'100px'}} className='payment-container'>
        <h1>Tripinbudget Pvt Ltd</h1>
      <input
        type="number"
        value={amount}
        onChange={e => setAmount(e.target.value)}
        placeholder="Enter amount"
      />
      <button onClick={handlePayment} disabled={loading}>
        {loading ? 'Processing...' : 'Make Payment'}
      </button>
    </div>
  );
}

export default TripPayment;
