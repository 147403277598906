// import React, { useState } from 'react';
// import axios from 'axios';
// import '../Components/TrainSearchsecond.css'; // Import your external CSS file

// function TrainSearchsecond() {
//   const [fromStationCode, setFromStationCode] = useState('');
//   const [toStationCode, setToStationCode] = useState('');
//   const [dateOfJourney, setDateOfJourney] = useState('');
//   const [trains, setTrains] = useState([]);
//   const [error, setError] = useState(null);

//   const searchTrains = async () => {
//     try {
//       const response = await axios.get('https://irctc1.p.rapidapi.com/api/v3/trainBetweenStations', {
//         params: {
//           fromStationCode,
//           toStationCode,
//           dateOfJourney
//         },
//         headers: {
//           'X-RapidAPI-Key': 'e81bcfe9edmshcf1bc06dc8e36f1p19462cjsn695e54658e92',
//           'X-RapidAPI-Host': 'irctc1.p.rapidapi.com'
//         }
//       });
//       setTrains(response.data.data);
//       setError(null);
//     } catch (error) {
//       setTrains([]);
//       setError('Error fetching train data');
//       console.error(error);
//     }
//   };

//   return (
//     <div className="train-container" >
//       <h1 className="mb-4">Trains Availability</h1>
//       <div className="mb-3">
//         <label htmlFor="fromStation" className="form-label">From Station Code:</label>
//         <input
//           type="text"
//           className="form-control"
//           id="fromStation"
//           value={fromStationCode}
//           onChange={(e) => setFromStationCode(e.target.value)}
//         />
//       </div>
//       <div className="mb-3">
//         <label htmlFor="toStation" className="form-label">To Station Code:</label>
//         <input
//           type="text"
//           className="form-control"
//           id="toStation"
//           value={toStationCode}
//           onChange={(e) => setToStationCode(e.target.value)}
//         />
//       </div>
//       <div className="mb-3">
//         <label htmlFor="dateOfJourney" className="form-label">Date of Journey:</label>
//         <input
//           type="date"
//           className="form-control"
//           id="dateOfJourney"
//           value={dateOfJourney}
//           onChange={(e) => setDateOfJourney(e.target.value)}
//         />
//       </div>
//       <button className="btn btn-primary" onClick={searchTrains}>Search Trains</button>

//       {error && <p className="mt-3" style={{ color: 'red' }}>{error}</p>}

//       <div id="results" className="mt-4">
//         <h2>Results:</h2>
//         <table className="table table-bordered" id="customers">
//           <thead>
//             <tr>
//               <th>Train Name</th>
//               <th>Train Number</th>
//               <th>From Station</th>
//               <th>To Station</th>
//               <th>Train Date</th>
//               <th>Departure Time</th>
//               <th>Destination Time</th>
//               <th>Total Duration</th>
//             </tr>
//           </thead>
//           <tbody>
//             {trains.map((train) => (
//               <tr key={train.train_number}>
//                 <td>{train.train_name}</td>
//                 <td>{train.train_number}</td>
//                 <td>{train.from_station_name}</td>
//                 <td>{train.to_station_name}</td>
//                 <td>{train.train_date}</td>
//                 <td>{train.from_std}</td>
//                 <td>{train.to_std}</td>
//                 <td>{train.duration}</td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// }

// export default TrainSearchsecond;


//running this with search and select also
import React, { useState } from 'react';
import axios from 'axios';
import '../Components/TrainSearchsecond.css'; // Import your external CSS file
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import Select from 'react-select';
function TrainSearchsecond() {
  const [fromStationCode, setFromStationCode] = useState('');
  const [toStationCode, setToStationCode] = useState('');
  const [dateOfJourney, setDateOfJourney] = useState('');
  const [trains, setTrains] = useState([]);
  const [error, setError] = useState(null);
  // const options = [
  //   { value: 'PUNE', label: 'PUNE' },
  //   { value: 'SUR', label: 'SUR' },
  //   { value: 'PUNE SUR', label: 'PUNE SUR' },
  // ];
  const searchTrains = async () => {
    try {
      const response = await axios.get('https://irctc1.p.rapidapi.com/api/v3/trainBetweenStations', {
        params: {
          fromStationCode,
          toStationCode,
          dateOfJourney
        },
        headers: {
          'X-RapidAPI-Key': 'baa5306601msh44eade617a3c3d1p188b0djsn9f7772669cc0',
          'X-RapidAPI-Host': 'irctc1.p.rapidapi.com'
        }
      });
      setTrains(response.data.data);
      setError(null);
    } catch (error) {
      setTrains([]);
      setError('Error fetching train data');
      console.error(error);
    }
  };

  return (
    <div className="train-container" >
      <h1 className="mb-4">Trains Availability</h1>
      <div className="row">
      <div className="col">
      <label htmlFor="fromStation" className="form-label">From:</label>
      {/* <Select
        id="fromStation"
        value={options.find(option => option.value === fromStationCode)}
        options={options}
        onChange={(selectedOption) => setFromStationCode(selectedOption.value)}
      /> */}
       <input
            type="text"
            className="form-control"
            id="fromStation"
            value={fromStationCode}
            onChange={(e) => setFromStationCode(e.target.value)}
          />
    </div>

        <div className="swapcol">
          <SwapHorizIcon/>
        </div>
        <div className="col">
          <label htmlFor="toStation" className="form-label">To:</label>
          <input
            type="text"
            className="form-control"
            id="toStation"
            value={toStationCode}
            onChange={(e) => setToStationCode(e.target.value)}
          />
        </div>
        <div className="col">          
          <label htmlFor="dateOfJourney" className="form-label">Date of Journey:</label>
          <input
            type="date"
            className="form-control"
            id="dateOfJourney"
            value={dateOfJourney}
            onChange={(e) => setDateOfJourney(e.target.value)}
          />
        </div>

        <div className="col">
          <button className="btn btn-primary" onClick={searchTrains}>Search Trains</button>
        </div>
      </div>

      {error && <p className="mt-3" style={{ color: 'red' }}>{error}</p>}

      <div id="results" className="mt-4">
        <h2>Results:</h2>
        <table className="table table-bordered" id="customers">
          <thead>
            <tr>
              <th>Train Name</th>
              <th>Train Number</th>
              <th>From Station</th>
              <th>To Station</th>
              <th>Train Date</th>
              <th>Departure Time</th>
              <th>Destination Time</th>
              <th>Total Duration</th>
            </tr>
          </thead>
          <tbody>
            {trains.map((train) => (
              <tr key={train.train_number}>
                <td>{train.train_name}</td>
                <td>{train.train_number}</td>
                <td>{train.from_station_name}</td>
                <td>{train.to_station_name}</td>
                <td>{train.train_date}</td>
                <td>{train.from_std}</td>
                <td>{train.to_std}</td>
                <td>{train.duration}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
export default TrainSearchsecond;

// //running this with search and select also
// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import '../Components/TrainSearchsecond.css'; // Import your external CSS file
// import SwapHorizIcon from '@mui/icons-material/SwapHoriz';

// function TrainSearchsecond() {
//   const [fromStationCode, setFromStationCode] = useState('');
//   const [toStationCode, setToStationCode] = useState('');
//   const [dateOfJourney, setDateOfJourney] = useState('');
//   const [trains, setTrains] = useState([]);
//   const [error, setError] = useState(null);
//   const [stationSuggestions, setStationSuggestions] = useState([]);
//   const [stationSuggestionstwo, setStationSuggestionstwo] = useState([]);

//   useEffect(() => {
//     // Fetch station suggestions when fromStationCode changes
//     const fetchStationSuggestions = async () => {
//       try {
//         const response = await axios.get('https://irctc1.p.rapidapi.com/api/v1/searchStation', {
//           params: {
//             query: fromStationCode,
//           },
//           headers: {
//             'X-RapidAPI-Key': 'baa5306601msh44eade617a3c3d1p188b0djsn9f7772669cc0',
//             'X-RapidAPI-Host': 'irctc1.p.rapidapi.com'
//           },
//         });
//         setStationSuggestions(response.data.data);
//       } catch (error) {
//         console.error(error);
//       }
//     };

//     // Fetch suggestions only if fromStationCode is not empty
//     if (fromStationCode.trim() !== '') {
//       fetchStationSuggestions();
//     } else {
//       setStationSuggestions([]); // Clear suggestions when fromStationCode is empty
//     }
//   }, [fromStationCode]);

//   useEffect(() => {
//     // Fetch station suggestions when fromStationCode changes
//     const fetchStationSuggestionstwo = async () => {
//       try {
//         const response = await axios.get('https://irctc1.p.rapidapi.com/api/v1/searchStation', {
//           params: {
//             query: toStationCode,
//           },
//           headers: {
//             'X-RapidAPI-Key': 'baa5306601msh44eade617a3c3d1p188b0djsn9f7772669cc0',
//             'X-RapidAPI-Host': 'irctc1.p.rapidapi.com'
//           },
//         });
//         setStationSuggestionstwo(response.data.data);
//       } catch (error) {
//         console.error(error);
//       }
//     };

//     // Fetch suggestions only if fromStationCode is not empty
//     if (toStationCode.trim() !== '') {
//       fetchStationSuggestionstwo();
//     } else {
//       setStationSuggestionstwo([]); // Clear suggestions when fromStationCode is empty
//     }
//   }, [toStationCode]);

  

//   const searchTrains = async () => {
//     try {
//       const response = await axios.get('https://irctc1.p.rapidapi.com/api/v3/trainBetweenStations', {
//         params: {
//           fromStationCode,
//           toStationCode,
//           dateOfJourney
//         },
//         headers: {
//           'X-RapidAPI-Key': 'baa5306601msh44eade617a3c3d1p188b0djsn9f7772669cc0',
//           'X-RapidAPI-Host': 'irctc1.p.rapidapi.com'
//         }
//       });
//       setTrains(response.data.data);
//       setError(null);
//     } catch (error) {
//       setTrains([]);
//       setError('Error fetching train data');
//       console.error(error);
//     }
//   };

//   return (
//     <div className="train-container">
//       <h1 className="mb-4">Trains Availability</h1>
//       <div className="row">
//         <div className="col">
//           <label htmlFor="fromStation" className="form-label">
//             From:
//           </label>
//           <input
//             type="text"
//             className="form-control"
//             id="fromStation"
//             value={fromStationCode}
//             onChange={(e) => setFromStationCode(e.target.value)}
//             list="stationSuggestions"
//           />
//          <datalist id="stationSuggestions">
//   {stationSuggestions.map((station) => (
//     <option key={station.code} value={`${station.code}-${station.name}`}>
//       {station.code}-{station.name}
//     </option>
//   ))}
// </datalist>

//         </div>

//         <div className="swapcol">
//           <SwapHorizIcon/>
//         </div>
//         <div className="col">
//           <label htmlFor="toStation" className="form-label">To:</label>
//           <input
//             type="text"
//             className="form-control"
//             id="toStation"
//             value={toStationCode}
//             onChange={(e) => setToStationCode(e.target.value)}
//             list="stationSuggestionstwo"
//           />
//            <datalist id="stationSuggestionstwo">
//   {stationSuggestions.map((station) => (
//     <option key={station.code} value={`${station.code}-${station.name}`}>
//       {station.code}-{station.name}
//     </option>
//   ))}
// </datalist>

          
//         </div>
//         <div className="col">          
//           <label htmlFor="dateOfJourney" className="form-label">Date of Journey:</label>
//           <input
//             type="date"
//             className="form-control"
//             id="dateOfJourney"
//             value={dateOfJourney}
//             onChange={(e) => setDateOfJourney(e.target.value)}
//           />
//         </div>

//         <div className="col">
//           <button className="btn btn-primary" onClick={searchTrains}>Search Trains</button>
//         </div>
//       </div>

//       {error && <p className="mt-3" style={{ color: 'red' }}>{error}</p>}

//       <div id="results" className="mt-4">
//         <h2>Results:</h2>
//         <table className="table table-bordered" id="customers">
//           <thead>
//             <tr>
//               <th>Train Name</th>
//               <th>Train Number</th>
//               <th>From Station</th>
//               <th>To Station</th>
//               <th>Train Date</th>
//               <th>Departure Time</th>
//               <th>Destination Time</th>
//               <th>Total Duration</th>
//             </tr>
//           </thead>
//           <tbody>
//             {trains.map((train) => (
//               <tr key={train.train_number}>
//                 <td>{train.train_name}</td>
//                 <td>{train.train_number}</td>
//                 <td>{train.from_station_name}</td>
//                 <td>{train.to_station_name}</td>
//                 <td>{train.train_date}</td>
//                 <td>{train.from_std}</td>
//                 <td>{train.to_std}</td>
//                 <td>{train.duration}</td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// }

// export default TrainSearchsecond;
