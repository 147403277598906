import React from 'react'
import { useNavigate } from 'react-router-dom';
import '../Customer-Service/customerService.css'
import { RiErrorWarningFill } from "react-icons/ri";
import { IoSettingsSharp } from "react-icons/io5";
import { TbBrandGoogleBigQuery } from "react-icons/tb";
  import { FaMoneyBillTransfer } from "react-icons/fa6";
  import { MdOutlineSearch } from "react-icons/md";
 import { MdManageAccounts } from "react-icons/md";
 import { MdAssuredWorkload } from "react-icons/md";
 import { HiCurrencyRupee } from "react-icons/hi";

export default function CustomerService() {
  const navigate = useNavigate(); // Initialize useNavigate

  const handleClick = (route) => {
    navigate(route); // Navigate to the specified route
  };

  return (
    <div className="customer-service-container">
        <div className="dummy-section"></div>
        <div className="customer-service-content">
          <div className="customer-service-content-tagline">
            <p>Embark on your adventure worry-free,
With support that's always here to be,
From booking to exploring, we're your guide,
In every moment, by your side.</p>
          </div>
           <div className="customer-service-content-first-row">
            <div className="customer-service-content-first-row-subrow1"><span className='customer-icon'><RiErrorWarningFill style={{fontSize:'30px'}}/></span><h1 className='note-text'>Please Note:</h1>
             </div>
            <div className="customer-service-content-first-row-subrow2"><p className='note-subtext'>Our representatives at Tripinbudget adhere to strict protocols, ensuring that they never request sensitive personal information such as credit/debit card details, CVV codes, OTPs, or passwords. Additionally, we do not require the installation of third-party applications like AnyDesk or TeamViewer, which could compromise your device's security. It's important to remain vigilant and cautious of anyone claiming affiliation with Tripinbudget. Responding to such requests could potentially expose you to fraudulent activities, leading to the loss of valuable information or funds. We prioritize the security and trust of our customers and are committed to upholding the highest standards of integrity in all our interactions. Thank you for your cooperation and understanding.</p>
          </div>
              </div>
           <div className="customer-service-content-second-row">
            <div className="customer-service-content-second-row-subrow1">
            <h1 className='customer-text'>Customer Service</h1>
            <p className='customer-subtext'>Welcome to the TripInBudget customer service. We're here to answer all your travel related queries.</p>
            </div>
            <div className="customer-service-content-second-row-subrow2">
           <div className="cscol" onClick={() => handleClick('/customer-service/account-settings')}>
                <div className="cscol-row1">
                <div className='icon-cscol'>
                <MdManageAccounts style={{fontSize:'60px'}} className='icon-main'/>
                </div>
                </div>
                <div className="cscol-row2">
                <p>Account Setting</p>
                </div>
          </div>

          <div className="cscol" onClick={() => handleClick('/login')}>
                <div className="cscol-row1">
                <div className='icon-cscol'>
                <TbBrandGoogleBigQuery style={{fontSize:'60px'}} className='icon-main'/>
                </div>
                </div>
                <div className="cscol-row2">
                <p>Issue With Banking</p>
                </div>
          </div>

          

          <div className="cscol" onClick={() => handleClick('/customer-service/prebooking-queries')}>
                <div className="cscol-row1">
                <div className='icon-cscol'>
                <MdOutlineSearch style={{fontSize:'60px'}} className='icon-main'/>
                </div>
                </div>
                <div className="cscol-row2">
                <p>Prebooking Queries</p>
                </div>
          </div>

          <div className="cscol" onClick={() => handleClick('/customer-service/assured')}>
                <div className="cscol-row1">
                <div className='icon-cscol'>
                <MdAssuredWorkload style={{fontSize:'60px'}} className='icon-main'/>
                </div>
                </div>
                <div className="cscol-row2">
                <p>TripInBudget Assured</p>
                </div>
          </div>

          <div className="cscol" onClick={() => handleClick('/customer-service/manage-payments')}>
                <div className="cscol-row1">
                <div className='icon-cscol'>
                <HiCurrencyRupee style={{fontSize:'60px'}} className='icon-main'/>
                </div>
                </div>
                <div className="cscol-row2">
                <p>Manage Payment Methods</p>
                </div>
          </div>

          </div>
           </div>
           <div className="customer-service-content-third-row">
         <h2>About TripInBudget</h2>
         <p>We've assembled a comprehensive compilation of travel queries to assist you efficiently. We're confident you'll find the answer to your question within this guide. However, should you require further assistance, please don't hesitate to utilize the "Contact Us" option provided below, and we'll promptly address your inquiry for resolution.</p>
           </div>
           <div className="customer-service-faq-container"> 
              <details>
              <summary> Flight Ticket Booking</summary>
              <div className='faqopen'>
              <blockquote className='faqopen-blockquote'>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Distinctio a, repudiandae voluptates repellat exercitationem ex, explicabo eaque ea, qui commodi minus reiciendis sequi amet. Iusto, id non? Quisquam, itaque veniam.</p>
              </blockquote>
              </div>
            </details>
            <details>
              <summary> Flight Ticket Booking</summary>
              <div className='faqopen'>
              <blockquote className='faqopen-blockquote'>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Distinctio a, repudiandae voluptates repellat exercitationem ex, explicabo eaque ea, qui commodi minus reiciendis sequi amet. Iusto, id non? Quisquam, itaque veniam.</p>
              </blockquote>
              </div>
            </details>
            <details>
              <summary> Flight Ticket Booking</summary>
              <div className='faqopen'>
              <blockquote className='faqopen-blockquote'>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Distinctio a, repudiandae voluptates repellat exercitationem ex, explicabo eaque ea, qui commodi minus reiciendis sequi amet. Iusto, id non? Quisquam, itaque veniam.</p>
              </blockquote>
              </div>
            </details>
            <details>
              <summary> Flight Ticket Booking</summary>
              <div className='faqopen'>
              <blockquote className='faqopen-blockquote'>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Distinctio a, repudiandae voluptates repellat exercitationem ex, explicabo eaque ea, qui commodi minus reiciendis sequi amet. Iusto, id non? Quisquam, itaque veniam.</p>
              </blockquote>
              </div>
            </details>
            <details>
              <summary> Flight Ticket Booking</summary>
              <div className='faqopen'>
              <blockquote className='faqopen-blockquote'>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Distinctio a, repudiandae voluptates repellat exercitationem ex, explicabo eaque ea, qui commodi minus reiciendis sequi amet. Iusto, id non? Quisquam, itaque veniam.</p>
              </blockquote>
              </div>
            </details>
  
       </div>
     </div> 
  </div>

  )
}
