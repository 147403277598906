import React, { useState } from 'react';
import axios from 'axios';

const AirportInfo = () => {
  const [airportCode, setAirportCode] = useState('');
  const [airportData, setAirportData] = useState(null);
  const [error, setError] = useState(null);

  const fetchAirportInfo = async () => {
    try {
      const response = await axios.get('https://airport-info.p.rapidapi.com/airport', {
        params: { iata: airportCode },
        headers: {
          'X-RapidAPI-Key': 'e81bcfe9edmshcf1bc06dc8e36f1p19462cjsn695e54658e92',
          'X-RapidAPI-Host': 'airport-info.p.rapidapi.com'
        }
      });

      setAirportData(response.data);
      setError(null);
    } catch (error) {
      console.error(error);
      setError('Error fetching airport information');
    }
  };

  return (
    <div style={{padding:'100px'}}>
      <h1>Airport Information</h1>
      <label>
        Enter Airport Code:
        <input
          type="text"
          value={airportCode}
          onChange={(e) => setAirportCode(e.target.value.toUpperCase())}
        />
      </label>
      <button onClick={fetchAirportInfo}>Fetch Airport Info</button>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {airportData && (
        <div>
          <h2>{airportData.name}</h2>
          <p>{airportData.location}</p>
          <p>Phone: {airportData.phone}</p>
          <p>Website: <a href={airportData.website} target="_blank" rel="noopener noreferrer">{airportData.website}</a></p>
        </div>
      )}
    </div>
  );
};

export default AirportInfo;
