// import React, { useState, useEffect } from 'react';
// import { useLocation } from 'react-router-dom';
// import axios from 'axios';
// import '../Customer-Service/customerService.css'
// function HotelResultPage() {
//   const location = useLocation();
//   const [hotels, setHotels] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState('');

//   useEffect(() => {
//     const queryParams = new URLSearchParams(location.search);
//     const cityCode = queryParams.get('cityCode');
//     const checkInDate = queryParams.get('checkInDate');
//     const checkOutDate = queryParams.get('checkOutDate');
//     const adults = queryParams.get('adults');
//     const numberOfRooms = queryParams.get('numberOfRooms');

//     const fetchHotels = async () => {
//       try {
//         setLoading(true);
//         const response = await axios.post(`${BASE_URL}/hotels-by-city', {
//           cityCode: cityCode
      
//         });
//         setHotels(response.data.data);
//         setLoading(false);
//       } catch (error) {
//         setError('Failed to fetch hotels');
//         setLoading(false);
//       }
//     };

//     fetchHotels();
//   }, [location.search]);

//   return (
//     <div className="resultpage-container">
//         <div className="hotel-result-dummy-section"></div>
//         {loading && <p>Loading...</p>}
//       {error && <p>{error}</p>}
//         <div className="result-card">
//       {hotels.length > 0 && (
//         hotels.map((hotel, index) => (
//           <div key={index} className="resultpage-col">
//             <div className="card-row1"><h2>{hotel.name}</h2></div>
//             <div className="card-row2">
//                 <div className="flexcol">
//                     <p>Hotel Id</p>
//                     <p>{hotel.hotelId}</p></div>
//                 <div className="flexcol">
//                     <p>Latitude</p>
//                     <p>{hotel.geoCode.latitude}</p></div>
//                 <div className="flexcol">
//                     <p>Longitude</p>
//                     <p>{hotel.geoCode.longitude}</p>
//                     </div>
//                 <div className="flexcol">
//                     <p>Country Code</p>
//                     <p>{hotel.address.countryCode}</p>
//                     </div>
//                 <div className="flexcol"><button className='btn btn-primary'>More Info</button></div>
//             </div>
//           </div>
//         ))
//       )}
//     </div>
//     </div>
//   );
// }

// export default HotelResultPage;
// import React, { useState, useEffect } from 'react';
// import { useLocation } from 'react-router-dom';
// import axios from 'axios';
// import '../Customer-Service/customerService.css';

// function HotelResultPage() {
//   const location = useLocation();
//   const [hotels, setHotels] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState('');
//   const [hotelOffers, setHotelOffers] = useState({});

//   useEffect(() => {
//     const queryParams = new URLSearchParams(location.search);
//     const cityCode = queryParams.get('cityCode');

//     const fetchHotels = async () => {
//       try {
//         setLoading(true);
//         const response = await axios.post(`${BASE_URL}/hotels-by-city', {
//           cityCode: cityCode
//         });
//         setHotels(response.data.data);
//         setLoading(false);
//       } catch (error) {
//         setError('Failed to fetch hotels');
//         setLoading(false);
//       }
//     };

//     fetchHotels();
//   }, [location.search]);

//   const fetchHotelOffers = async (hotelId, checkInDate, checkOutDate, adults, numberOfRooms) => {
//     try {
//       const requestData = {
//         hotelIds: hotelId, // Send hotelId value directly
//         adults: parseInt(adults), // Convert to integer if necessary
//         checkInDate,
//         checkOutDate,
//         roomQuantity: parseInt(numberOfRooms) // Convert to integer if necessary
//       };
  
//       console.log('Sending data:', requestData); // Log the request data to the console
  
//       const response = await axios.post(`${BASE_URL}/hotel-offers', requestData);
//       return response.data;
//     } catch (error) {
//       throw new Error('Failed to fetch hotel offers');
//     }
//   };
  
  
  
  
  

//   const handleMoreInfoClick = async (hotelId) => {
//     try {
//       const queryParams = new URLSearchParams(location.search);
//       const checkInDate = queryParams.get('checkInDate');
//       const checkOutDate = queryParams.get('checkOutDate');
//       const adults = queryParams.get('adults');
//       const numberOfRooms = queryParams.get('numberOfRooms');

//       const hotelOffers = await fetchHotelOffers(hotelId, checkInDate, checkOutDate, adults, numberOfRooms);
//       setHotelOffers(hotelOffers);
//     } catch (error) {
//       setError('Failed to fetch hotel offers');
//     }
//   };

//   return (
//     <div className="resultpage-container">
//       <div className="hotel-result-dummy-section"></div>
//       {loading && <p>Loading...</p>}
//       {error && <p>{error}</p>}
//       <div className="result-card">
//         {hotels.length > 0 && hotels.map((hotel, index) => (
//           <div key={index} className="resultpage-col">
//             <div className="card-row1"><h2>{hotel.name}</h2></div>
//             <div className="card-row2">
//               <div className="flexcol">
//                 <p>Hotel Id</p>
//                 <p>{hotel.hotelId}</p>
//               </div>
//               <div className="flexcol">
//                 <p>Latitude</p>
//                 <p>{hotel.geoCode.latitude}</p>
//               </div>
//               <div className="flexcol">
//                 <p>Longitude</p>
//                 <p>{hotel.geoCode.longitude}</p>
//               </div>
//               <div className="flexcol">
//                 <p>Country Code</p>
//                 <p>{hotel.address.countryCode}</p>
//               </div>
//               <div className="flexcol">
//                 <button className='btn btn-primary' onClick={() => handleMoreInfoClick(hotel.hotelId)}>More Info</button>
//               </div>
//             </div>
//             {hotelOffers[hotel.hotelId] && (
//               <div className="offer-details">
//                 <p>Offer ID: {hotelOffers[hotel.hotelId].offerId}</p>
//                 <p>Description: {hotelOffers[hotel.hotelId].description}</p>
//                 <p>Amount: {hotelOffers[hotel.hotelId].amount}</p>
//               </div>
//             )}
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// }

// export default HotelResultPage;



import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../Customer-Service/customerService.css';
import BASE_URL from '../../Components/apiConfig';

function HotelResultPage() {
  const location = useLocation();
  const [hotels, setHotels] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [hotelOffers, setHotelOffers] = useState({});
  const [showDialog, setShowDialog]= useState(false);
  const [showLoginDialog, setShowLoginDialog]= useState(false);
  const [selectedHotelData, setSelectedHotelData]=useState(null);
  const navigate =useNavigate();
  const handleLoginRedirect=()=>{
    navigate('/login');
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const cityCode = queryParams.get('cityCode');

    const fetchHotels = async () => {
      try {
        setLoading(true);
        const response = await axios.post(`${BASE_URL}/hotels-by-city`, {
          cityCode: cityCode
        });
        setHotels(response.data.data);
        setLoading(false);
      } catch (error) {
        setError('Failed to fetch hotels');
        setLoading(false);
      }
    };

    fetchHotels();
  }, [location.search]);

  const fetchHotelOffers = async (hotelId, checkInDate, checkOutDate, adults, numberOfRooms) => {
    try {
      const requestData = {
        hotelIds: hotelId,
        adults: parseInt(adults),
        checkInDate,
        checkOutDate,
        roomQuantity: parseInt(numberOfRooms)
      };
  
      const response = await axios.post(`${BASE_URL}/hotel-offers`, requestData);

      return response.data;
    } catch (error) {
      throw new Error('Failed to fetch hotel offers');
    }
  };

  const handleMoreInfoClick = async (hotelId) => {
    try {
      const queryParams = new URLSearchParams(location.search);
      const checkInDate = queryParams.get('checkInDate');
      const checkOutDate = queryParams.get('checkOutDate');
      const adults = queryParams.get('adults');
      const numberOfRooms = queryParams.get('numberOfRooms');
  
      const hotelOfferResponse = await fetchHotelOffers(hotelId, checkInDate, checkOutDate, adults, numberOfRooms);
      
      // Check if there is data in the response
      if (hotelOfferResponse.data && hotelOfferResponse.data.length > 0) {
        const hotelOffer = hotelOfferResponse.data[0];
        // Check if offers array exists and has elements
        if (hotelOffer.offers && hotelOffer.offers.length > 0) {
          // Assuming there's only one offer per hotel for simplicity
          setHotelOffers({ ...hotelOffers, [hotelId]: hotelOffer.offers[0] });
        } else {
          throw new Error('No offers found for the hotel');
        }
      } else {
        throw new Error('No data found in the response');
      }
    } catch (error) {
      console.error('Error fetching hotel offers:', error); // Log any errors
      setError('Failed to fetch hotel offers');
    }
  };
  const handleDialogClose = () => {
    setShowDialog(false);
    setShowLoginDialog(false);
  };

  const handleDialogSubmit = async () => {
    try {
      // Make a POST request to the server endpoint
      const response = await axios.post(`${BASE_URL}/send-hotel-data`, {
        // Pass the selected hotel data to the server
        hotelData: {
          name:selectedHotelData.name,
          hotelId: selectedHotelData.hotelId,
          longitude: selectedHotelData.longitude,
          latitude: selectedHotelData.latitude,
          countryCode: selectedHotelData.countryCode,
          checkInDate: selectedHotelData.checkInDate,
          checkOutDate: selectedHotelData.checkOutDate,
          adults: selectedHotelData.adults,
          numberOfRooms: selectedHotelData.numberOfRooms,
          offerId: selectedHotelData.offerId,
          description: selectedHotelData.description,
          totalAmount: selectedHotelData.totalAmount
        }
      });
  
      // Handle success (e.g., show a success message)
      console.log('Hotel Data Send Successfully');
      setTimeout(() => {
        setShowDialog(false);
      }, 2000);
    } catch (error) {
      // Handle error (e.g., show an error message)
      console.error('Error submitting hotel data:', error);
    }
  };
  

 const handleMoreInfoSubmit = async (hotelId) => {
  const isLoggedIn = localStorage.getItem('isLoggedIn');
  if (isLoggedIn) {
    await handleMoreInfoClick(hotelId);
    setSelectedHotelData({
      hotelId: hotelId,
      longitude: hotels.find(hotel => hotel.hotelId === hotelId).geoCode.longitude,
      latitude: hotels.find(hotel => hotel.hotelId === hotelId).geoCode.latitude,
      countryCode: hotels.find(hotel => hotel.hotelId === hotelId).address.countryCode,
      checkInDate: new URLSearchParams(location.search).get('checkInDate'),
      checkOutDate: new URLSearchParams(location.search).get('checkOutDate'),
      adults: new URLSearchParams(location.search).get('adults'),
      numberOfRooms: new URLSearchParams(location.search).get('numberOfRooms'),
      offerId: hotelOffers[hotelId].id,
      description: hotelOffers[hotelId].room.description.text,
      totalAmount: hotelOffers[hotelId].price.total
    });
    setShowDialog(true);
  } else {
    setShowLoginDialog(true);
  }
};

  
  
  
  

  return (
    <div className="resultpage-container">
      <div className="hotel-result-dummy-section"></div>
      {loading && <p>Loading...</p>}
      {error && <p>{error}</p>}
      <div className="result-card">
        {hotels.length > 0 && hotels.map((hotel, index) => (
          <div key={index} className="resultpage-col">
            <div className="card-row1"><h2>{hotel.name}</h2></div>
            <div className="card-row2">
              <div className="flexcol">
                <p className='offer-text'>Hotel Id</p>
                <p className='offer-text-value'>{hotel.hotelId}</p>
              </div>
              <div className="flexcol">
                <p className='offer-text'>Latitude</p>
                <p className='offer-text-value'>{hotel.geoCode.latitude}</p>
              </div>
              <div className="flexcol">
                <p className='offer-text'>Longitude</p>
                <p className='offer-text-value'>{hotel.geoCode.longitude}</p>
              </div>
              <div className="flexcol">
                <p className='offer-text'>Country Code</p>
                <p className='offer-text-value'>{hotel.address.countryCode}</p>
              </div>
              <div className="flexcol">
                <button className='btn btn-primary' onClick={() => handleMoreInfoClick(hotel.hotelId)}>More Info</button>
              </div>
            </div>
            {hotelOffers[hotel.hotelId] && (
          <div className="card-row3" style={{marginTop:'-2vh',marginBottom:'-4vh'}}>
           
            <div className="card-row3-col1">
                <p className='offer-text'>Offer ID</p>
                <p className='offer-text-value'>{hotelOffers[hotel.hotelId].id}</p>
              </div>
              <div className="card-row3-col2">
                <p className='offer-text'>Description:</p>
                <p className='offer-text-value'>{hotelOffers[hotel.hotelId].room.description.text}</p>
              </div>
              <div className="card-row3-col3">
                <p className='offer-text'>Total Amount:</p>
                <p className='offer-text-value'>{hotelOffers[hotel.hotelId].price.total}</p>
              </div>
              <div className="card-row3-col4">
              <button className='btn btn-primary' onClick={() => handleMoreInfoSubmit(hotel.hotelId)}>Book Now</button>

              </div>
             
          </div>
        )}


          </div>
          
        ))}
      </div>
      
      {showDialog && (
        <div className="dialog-container">
          <div className="dialog">
            <h3>Hotel Details</h3>
            <table>
              <tbody>
                <tr>
                  <th>Hotel ID</th>
                  <th>Longitude</th>
                  <th>Latitude</th>
                  <th>Country Code</th>
                  <th>Offer ID</th>
                </tr>
               
                <tr>
                <td>{selectedHotelData.hotelId}</td>
                  <td>{selectedHotelData.longitude}</td>
                  <td>{selectedHotelData.latitude}</td>
                  <td>{selectedHotelData.countryCode}</td>
                  <td>{selectedHotelData.offerId}</td>
                </tr>
                <tr style={{ height: '20px' }}> {/* Add space */}
      <td colSpan="5"></td> {/* colspan for the whole row */}
    </tr>
                <tr>
                  <th colSpan="3" >Description</th>
                  <th>Check-in Date</th>
                  <th>Check-out Date</th>
                </tr>
              
                <tr>
                <td colSpan="3" style={{fontWeight:'400'}}>{selectedHotelData.description}</td>
                  <td>{selectedHotelData.checkInDate}</td>
                  <td>{selectedHotelData.checkOutDate}</td>
                </tr>
                <tr style={{ height: '20px' }}> {/* Add space */}
      <td colSpan="5"></td> {/* colspan for the whole row */}
    </tr>
                <tr>
                <th>Adults</th>
                  <th>Number of Rooms</th>
                  <th>Total Amount</th>
                </tr>
                <tr>
                <td>{selectedHotelData.adults}</td>
                  <td>{selectedHotelData.numberOfRooms}</td>
                  <td>{selectedHotelData.totalAmount}</td>
                </tr>
                <tr>

                </tr>
              </tbody>
            </table>
            <div className="dialog-buttons">
              <button className="btn btn-primary" onClick={handleDialogSubmit}>Submit</button>
              <button className="btn btn-secondary" onClick={handleDialogClose}>Close</button>
            </div>
          </div>
        </div>
      )}
       {showLoginDialog && (
        <div className="dialog-container">
          <div className="dialog">
           <p>please login/Register to complete the booking</p>
            <div className="dialog-buttons">
              <button className="btn btn-primary" onClick={handleLoginRedirect}>Login</button>
              <button className="btn btn-secondary" onClick={handleDialogClose}>Close</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default HotelResultPage;
