// import React, { useState,useEffect } from 'react';
// import axios from 'axios';
// import '../../Components/Hotel/hotel.css'
// import Select from 'react-select';

// function HotelAvailable() {

  // const [hotels, setHotels] = useState([]);
  // const [loading, setLoading] = useState(false);
//   const [error, setError] = useState('');
//   const [searchTerm, setSearchTerm] = useState('');
//   const [originOptions, setOriginOptions] = useState([]);
//   const [cityCode, setCityCode] = useState('');

//   const fetchHotels = async () => {
//     try {
//       setLoading(true);
//       const response = await axios.post('${BASE_URL}/hotels-by-city', {
//         cityCode: cityCode.value // Adjusted to send the value of the selected option
//       });
//       setHotels(response.data.data);
//       setLoading(false);
//     } catch (error) {
//       setError('Failed to fetch hotels');
//       setLoading(false);
//     }
//   };
  

//   // useEffect(() => {
//   //   const fetchData = async () => {
//   //     try {
//   //       // First API for searching by name for arrival airport location code
//   //       const responseName = await axios.get('https://airports15.p.rapidapi.com/airports', {
//   //         params: { name: searchTerm },
//   //         headers: {
//   //           'X-RapidAPI-Key': 'baa5306601msh44eade617a3c3d1p188b0djsn9f7772669cc0',
//   //           'X-RapidAPI-Host': 'airports15.p.rapidapi.com',
//   //         },
//   //       });
//   //       // Second API for searching by IATA code  for arrival airport location code
//   //       const responseIata = await axios.get('https://airport-info.p.rapidapi.com/airport', {
//   //         params: { iata: searchTerm },
//   //         headers: {
//   //           'X-RapidAPI-Key': 'baa5306601msh44eade617a3c3d1p188b0djsn9f7772669cc0',
//   //           'X-RapidAPI-Host': 'airport-info.p.rapidapi.com',
//   //         },
//   //       });
//   //       // Combine the results from both APIs in options formate it will show inside dropdown
//   //       const options = [
//   //         ...responseName.data.map((airport) => ({
//   //           value: airport.iataCode,
//   //           label: `${airport.iataCode} - ${airport.city}`,
//   //         })),
//   //         {
//   //           value: responseIata.data.iata,
//   //           label: `${responseIata.data.iata} - ${responseIata.data.city}`,
//   //         },
//   //       ];
//   //       setOriginOptions(options);
//   //       setError(null);
//   //     } catch (error) {
//   //       setOriginOptions([]);
//   //       setError(error.message);
//   //     }
//   //   };
//   //   if (searchTerm) {
//   //     fetchData();
//   //   }
//   // }, [searchTerm]);
//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         // Fetch IATA code and city name based on user input (searchTerm)
//         const response = await axios.post('${BASE_URL}/fetch-iata-city', {
//           query: searchTerm
//         });
  
//         // Update options based on the response
//         const iataCityOptions = response.data.map((airport) => ({
//           value: airport.iata_code,
//           label: `${airport.iata_code} - ${airport.city_name}`
//         }));
  
//         // Fetch options from the earlier APIs
//         const nameOptionsResponse = await axios.get('https://airports15.p.rapidapi.com/airports', {
//           params: { name: searchTerm },
//           headers: {
//             'X-RapidAPI-Key': 'baa5306601msh44eade617a3c3d1p188b0djsn9f7772669cc0',
//             'X-RapidAPI-Host': 'airports15.p.rapidapi.com',
//           },
//         });
  
//         const iataOptionsResponse = await axios.get('https://airport-info.p.rapidapi.com/airport', {
//           params: { iata: searchTerm },
//           headers: {
//             'X-RapidAPI-Key': 'baa5306601msh44eade617a3c3d1p188b0djsn9f7772669cc0',
//             'X-RapidAPI-Host': 'airport-info.p.rapidapi.com',
//           },
//         });
  
//         const nameOptions = nameOptionsResponse.data.map((airport) => ({
//           value: airport.iataCode,
//           label: `${airport.iataCode} - ${airport.city}`,
//         }));
  
//         const iataOption = {
//           value: iataOptionsResponse.data.iata,
//           label: `${iataOptionsResponse.data.iata} - ${iataOptionsResponse.data.city}`,
//         };
  
//         // Combine all options
//         const options = [...nameOptions, iataOption, ...iataCityOptions];
        
//         setOriginOptions(options);
//         setError(null);
//       } catch (error) {
//         setOriginOptions([]);
//         setError('Error fetching data');
//       }
//     };
  
//     if (searchTerm) {
//       fetchData();
//     }
//   }, [searchTerm]);

//   return ( 
//     <div className="hotelcontainer" >
//       <div className="dummy2"></div>
//       <div className="hotelcontainerrow">
//         <div className="flightcol">
//             <label className="form-label">City Code</label>
//             <Select
//               options={originOptions}
//               value={cityCode}
//               onChange={(selectedOption) => setCityCode(selectedOption)}
//               onInputChange={(inputValue) => setSearchTerm(inputValue)}
//               placeholder="Enter airport"
//             />
//           </div>
//           <div className="flightcol">
//           <label className="form-label">Check In Date</label>
//             <input type="date" className='form-control' /></div>
//           <div className="flightcol">
//           <label className="form-label">Check In Date</label>
//             <input type="date" className='form-control' /></div>
//           <div className="flightcol">
//           <label className="form-label">Adults</label>
//             <input type="number" className='form-control'/></div>
//           <div className="flightcol">
//           <label className="form-label">No of Room</label>
//             <input type="number" className='form-control' /></div>

//         <div className="flightcol">
//         <button onClick={fetchHotels} className='btn btn-primary'>Fetch Hotels</button>
//       </div>
//       </div>
//       {loading && <p>Loading...</p>}
//       {error && <p>{error}</p>}
//       {hotels.length > 0 && (
//         <table className="table table-bordered" id="customers" >
//           <thead>
//             <tr>
//               <th>Hotel Id</th>
//               <th>Name</th>
//               <th>Latitude</th>
//               <th>Longitude</th>
//               <th>Country Code</th>
//             </tr>
//           </thead>
//           <tbody>
//             {hotels.map((hotel, index) => (
//               <tr key={index}>
//                 <td>{hotel.hotelId}</td>
//                 <td>{hotel.name}</td>
//                 <td>{hotel.geoCode.latitude}</td>
//                 <td>{hotel.geoCode.longitude}</td>
//                 <td>{hotel.address.countryCode}</td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       )}
//     </div>
 
//   );
// }

// export default HotelAvailable;

import React, { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom/dist';
import axios from 'axios';
import '../../Components/Hotel/hotel.css'
import Select from 'react-select';
import BASE_URL from '../../Components/apiConfig';

function HotelAvailable() {

  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [originOptions, setOriginOptions] = useState([]);
  const [cityCode, setCityCode] = useState('');
  const [checkInDate, setCheckInDate] = useState('');
  const [checkOutDate, setCheckOutDate] = useState('');
  const [adults, setAdults] = useState(1);
  const [numberOfRooms, setNumberOfRooms] = useState(1);
  const navigate=useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch IATA code and city name based on user input (searchTerm)
        const response = await axios.post(`${BASE_URL}/fetch-iata-city`, {
          query: searchTerm
        });
        // Update options based on the response
        const iataCityOptions = response.data.map((airport) => ({
          value: airport.iata_code,
          label: `${airport.iata_code} - ${airport.city_name}`
        }));
        // Fetch options from the earlier APIs
        const nameOptionsResponse = await axios.get('https://airports15.p.rapidapi.com/airports', {
          params: { name: searchTerm },
          headers: {
            'X-RapidAPI-Key': 'baa5306601msh44eade617a3c3d1p188b0djsn9f7772669cc0',
            'X-RapidAPI-Host': 'airports15.p.rapidapi.com',
          },
        });
        const iataOptionsResponse = await axios.get('https://airport-info.p.rapidapi.com/airport', {
          params: { iata: searchTerm },
          headers: {
            'X-RapidAPI-Key': 'baa5306601msh44eade617a3c3d1p188b0djsn9f7772669cc0',
            'X-RapidAPI-Host': 'airport-info.p.rapidapi.com',
          },
        });
  
        const nameOptions = nameOptionsResponse.data.map((airport) => ({
          value: airport.iataCode,
          label: `${airport.iataCode} - ${airport.city}`,
        }));
  
        const iataOption = {
          value: iataOptionsResponse.data.iata,
          label: `${iataOptionsResponse.data.iata} - ${iataOptionsResponse.data.city}`,
        };
  
        // Combine all options
        const options = [...nameOptions, iataOption, ...iataCityOptions];
        
        setOriginOptions(options);
        setError(null);
      } catch (error) {
        setOriginOptions([]);
        setError('Error fetching data');
      }
    };
  
    if (searchTerm) {
      fetchData();
    }
  }, [searchTerm]);
  const handleSubmit = async () => {
    try {
      // Redirect to results page with query parameters
      navigate(`/hotels/available/result?cityCode=${cityCode.value}&checkInDate=${checkInDate}&checkOutDate=${checkOutDate}&adults=${adults}&numberOfRooms=${numberOfRooms}`);
    } catch (error) {
      console.error('Error:', error.message);
    }
  };

  return ( 
    <div className="hotelcontainer" >
      <div className="dummy2"></div>
      <div className="hotelcontainerrow">
        <div className="flightcol">
            <label className="form-label">City Code</label>
            <Select
              options={originOptions}
              value={cityCode}
              onChange={(selectedOption) => setCityCode(selectedOption)}
              onInputChange={(inputValue) => setSearchTerm(inputValue)}
              placeholder="Enter airport"
            />
          </div>
          <div className="flightcol">
          <label className="form-label">Check-In Date</label>
          <input type="date" className="form-control" value={checkInDate} onChange={(e) => setCheckInDate(e.target.value)} />
        </div>
        <div className="flightcol">
          <label className="form-label">Check-Out Date</label>
          <input type="date" className="form-control" value={checkOutDate} onChange={(e) => setCheckOutDate(e.target.value)} />
        </div>
        <div className="flightcol">
          <label className="form-label">Adults</label>
          <input type="number" className="form-control" value={adults} onChange={(e) => setAdults(e.target.value)} />
        </div>
        <div className="flightcol">
          <label className="form-label">Number of Rooms</label>
          <input type="number" className="form-control" value={numberOfRooms} onChange={(e) => setNumberOfRooms(e.target.value)} />
        </div>
        <div className="flightcol">
          <button onClick={handleSubmit} className="btn btn-primary">Fetch Hotels</button>
        </div>
      </div>
      
    </div>
 
  );
}

export default HotelAvailable;
