import React, { useState } from 'react';
import axios from 'axios';

function FlightOffers() {
    const [formData, setFormData] = useState({
        originLocationCode: '',
        destinationLocationCode: '',
        departureDate: '',
        adults: 1
    });
    const [flightOffers, setFlightOffers] = useState([]);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('http://localhost:3001/flight-offers', formData);
            setFlightOffers(response.data.data);
        } catch (error) {
            console.error('Error fetching flight offers:', error);
        }
    };

    return (
        <div style={{padding:'100px'}}>
            <form onSubmit={handleSubmit}>
                <input type="text" name="originLocationCode" placeholder="Origin" value={formData.originLocationCode} onChange={handleChange} />
                <input type="text" name="destinationLocationCode" placeholder="Destination" value={formData.destinationLocationCode} onChange={handleChange} />
                <input type="date" name="departureDate" value={formData.departureDate} onChange={handleChange} />
                <input type="number" name="adults" value={formData.adults} onChange={handleChange} />
                <button type="submit">Search</button>
            </form>
            <div>
                {flightOffers.map(offer => (
                    <div key={offer.id}>
                        <p>Offer ID: {offer.id}</p>
                        {/* Display other flight offer details as needed */}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default FlightOffers;
