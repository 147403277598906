// import React, { useState, useEffect } from 'react';
// import axios from 'axios';

// const AirportNameSearch = () => {
//   const [searchTerm, setSearchTerm] = useState('');
//   const [airportData, setAirportData] = useState(null);
//   const [error, setError] = useState(null);

//   const handleDepartureAirportSearch = async () => {
//     try {
//       const response = await axios.get('https://airports15.p.rapidapi.com/airports', {
//         params: { name: searchTerm },
//         headers: {
//           'X-RapidAPI-Key': 'baa5306601msh44eade617a3c3d1p188b0djsn9f7772669cc0',
//           'X-RapidAPI-Host': 'airports15.p.rapidapi.com',
//         },
//       });

//       setAirportData(response.data);
//       setError(null);
//     } catch (error) {
//       setAirportData(null);
//       setError(error.message);
//     }
//   };

//   useEffect(() => {
//     if (searchTerm) {
//       handleDepartureAirportSearch();
//     }
//   }, [searchTerm]);

//   return (
//     <div style={{ padding: '100px' }}>
//     <h1>Airport Name Search</h1>
//     <input
//       type="text"
//       value={searchTerm}
//       onChange={(e) => setSearchTerm(e.target.value)}
//       placeholder="Enter airport name"
//     />

//     {error && <p style={{ color: 'red' }}>{error}</p>}

//     {airportData && airportData.length > 0 ? (
//       <div>
//         <p>
//           {airportData[0].iataCode} -- {airportData[0].city}
//         </p>
//       </div>
//     ) : (
//       <p>No matching airports found</p>
//     )}
//   </div>
//   );
// };

// export default AirportNameSearch;
// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import Select from 'react-select';

// const AirportNameSearch = () => {
//   const [searchTerm, setSearchTerm] = useState('');
//   const [airportData, setAirportData] = useState([]);
//   const [error, setError] = useState(null);

//   const handleDepartureAirportSearch = async () => {
//     try {
//       const response = await axios.get('https://airports15.p.rapidapi.com/airports', {
//         params: { name: searchTerm },
//         headers: {
//           'X-RapidAPI-Key': 'baa5306601msh44eade617a3c3d1p188b0djsn9f7772669cc0',
//           'X-RapidAPI-Host': 'airports15.p.rapidapi.com',
//         },
//       });

//       setAirportData(response.data);
//       setError(null);
//     } catch (error) {
//       setAirportData([]);
//       setError(error.message);
//     }
//   };

//   useEffect(() => {
//     if (searchTerm) {
//       handleDepartureAirportSearch();
//     }
//   }, [searchTerm]);

//   const options = airportData.map((airport) => ({
//     value: airport.iataCode,
//     label: `${airport.iataCode} - ${airport.city}`,
//   }));

//   return (
//     <div style={{ padding: '100px' }}>
//       <h1>Airport Name Search</h1>
//       <input
//         type="text"
//         value={searchTerm}
//         onChange={(e) => setSearchTerm(e.target.value)}
//         placeholder="Enter airport name"
//       />

//       {error && <p style={{ color: 'red' }}>{error}</p>}

//       {airportData.length > 0 ? (
//         <div>
//           <Select options={options} />
//         </div>
//       ) : (
//         <p>No matching airports found</p>
//       )}
//     </div>
//   );
// };

// export default AirportNameSearch;
// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import Select from 'react-select';

// const AirportNameSearch = () => {
//   const [searchTerm, setSearchTerm] = useState('');
//   const [airportOptions, setAirportOptions] = useState([]);
//   const [selectedAirport, setSelectedAirport] = useState(null);
//   const [error, setError] = useState(null);

//   const handleDepartureAirportSearch = async () => {
//     try {
//       const response = await axios.get('https://airports15.p.rapidapi.com/airports', {
//         params: { name: searchTerm },
//         headers: {
//           'X-RapidAPI-Key': 'baa5306601msh44eade617a3c3d1p188b0djsn9f7772669cc0',
//           'X-RapidAPI-Host': 'airports15.p.rapidapi.com',
//         },
//       });
//       const options = response.data.map((airport) => ({
//         value: airport.iataCode,
//         label: `${airport.iataCode} - ${airport.city}`,
//       }));
//       setAirportOptions(options);
//       setError(null);
//     } catch (error) {
//       setAirportOptions([]);
//       setError(error.message);
//     }
//   };
//   useEffect(() => {
//     if (searchTerm) {
//       handleDepartureAirportSearch();
//     }
//   }, [searchTerm]);

//   return (
//     // <div style={{ padding: '100px' }}>
//     //   <Select
//     //     options={airportOptions}
//     //     value={selectedAirport}
//     //     onChange={(selectedOption) => setSelectedAirport(selectedOption)}
//     //     onInputChange={(inputValue) => setSearchTerm(inputValue)}
//     //     placeholder="Enter airport name"
//     //   />
//     //   {error && <p style={{ color: 'red' }}>{error}</p>}
//     //   {selectedAirport ? (
//     //     <div>
       
//     //     </div>
//     //   ) : (
//     //     <p>No matching airports found</p>
//     //   )}
//     // </div>

//         <div class="flightsearchcontainer">
// <div class="flightsearchcontainerrow1">
// <label>
//           <input type="radio" value="oneWay" />
//           One Way
//         </label>
//         <label>
//           <input type="radio"  value="return" />
//           Return
//         </label>
//      </div>
// <div class="flightsearchcontainerrow2">
// <div className="flightrow">
//       <div className="flightcol">
//       <label htmlFor="fromStation" className="form-label">From:</label>
//       <div >
//       <Select
//         options={airportOptions}
//         value={selectedAirport}
//         onChange={(selectedOption) => setSelectedAirport(selectedOption)}
//         onInputChange={(inputValue) => setSearchTerm(inputValue)}
//         placeholder="Enter airport name"
//       />
//       {error && <p style={{ color: 'red' }}>{error}</p>}
//       {selectedAirport ? (
//         <div>
//         </div>
//       ) : (
//         <p>No matching airports found</p>
//       )}
//     </div>
//     </div>
//         <div className="flightcol">
//           <label htmlFor="toStation" className="form-label">To:</label>
//           <input
//             type="text"
//             className="form-control"
//             id="toStation"
//           />
//         </div>
//         <div className="flightcol">          
//           <label htmlFor="dateOfJourney" className="form-label">Departure Date</label>
//           <input
//             type="date"
//             className="form-control"
//             id="dateOfJourney"
//           />
//         </div>
//         <div className="flightcol">
//         <label htmlFor="dateOfJourney" className="form-label">Traveler</label>
//         </div>
//         <div className="flightcol">
//           <button className="btn btn-primary" >Search Flights</button>
//         </div>
//       </div>
//        </div>
// <div class="flightsearchcontainerrow3"> </div>
// </div>

//   );
// };
//   all working fine with the from and to both rect select fetching the data from api
// export default AirportNameSearch;
// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import Select from 'react-select';

// const AirportNameSearch = () => {
//   const [searchTerm, setSearchTerm] = useState('');
//   const [airportOptions, setAirportOptions] = useState([]);
//   const [selectedAirport, setSelectedAirport] = useState(null);
//   const [error, setError] = useState(null);
//   const [searchTermTo, setSearchTermTo] = useState('');
//   const [airportOptionsTo, setAirportOptionsTo] = useState([]);
//   const [selectedAirportTo, setSelectedAirportTo] = useState(null);
//   const [errorTo, setErrorTo] = useState(null);



//   const handleDepartureAirportSearch = async ( ) => {
//     try {
//       const response = await axios.get('https://airports15.p.rapidapi.com/airports', {
//         params: { name: searchTerm },
//         headers: {
//           'X-RapidAPI-Key': 'baa5306601msh44eade617a3c3d1p188b0djsn9f7772669cc0',
//           'X-RapidAPI-Host': 'airports15.p.rapidapi.com',
//         },
//       });
//       const options = response.data.map((airport) => ({
//         value: airport.iataCode,
//         label: `${airport.iataCode} - ${airport.city}`,
//       }));
//       setAirportOptions(options);
//       setError(null);
//     } catch (error) {
//       setAirportOptions([]);
//       setError(error.message);
//     }
//   };
//   useEffect(() => {
//     if (searchTerm) {
//       handleDepartureAirportSearch();
//     }
//   }, [searchTerm]);

//   const handleAirportSearchDestination = async () => {
//     try {
//       const response = await axios.get('https://airports15.p.rapidapi.com/airports', {
//         params: { name: searchTermTo },
//         headers: {
//           'X-RapidAPI-Key': 'baa5306601msh44eade617a3c3d1p188b0djsn9f7772669cc0',
//           'X-RapidAPI-Host': 'airports15.p.rapidapi.com',
//         },
//       });
//       const options = response.data.map((airport) => ({
//         value: airport.iataCode,
//         label: `${airport.iataCode} - ${airport.city}`,
//       }));
//       setAirportOptionsTo(options);
//       setErrorTo(null);
//     } catch (error) {
//       setAirportOptionsTo([]);
//       setErrorTo(error.message);
//     }
//   };

//   useEffect(() => {
//     if (searchTermTo) {
//       handleAirportSearchDestination();
//     }
//   }, [searchTermTo]);

//   return (
//         <div class="flightsearchcontainer">
//          <div class="flightsearchcontainerrow1">
//           <label>
//           <input type="radio" value="oneWay" />
//           One Way
//         </label>
//         <label>
//           <input type="radio"  value="return" />
//           Return
//         </label>
//      </div>
// <div class="flightsearchcontainerrow2">
// <div className="flightrow">
//       <div className="flightcol">
//       <label htmlFor="fromStation" className="form-label">From:</label>
//       <div >
//       <Select
//         options={airportOptions}
//         value={selectedAirport}
//         onChange={(selectedOption) => setSelectedAirport(selectedOption)}
//         onInputChange={(inputValue) => setSearchTerm(inputValue)}
//         placeholder="Enter airport name"
//       />
//       {error && <p style={{ color: 'red' }}>{error}</p>}
//       {selectedAirport ? (
//         <div>
//         </div>
//       ) : (
//         <p>No matching airports found</p>
//       )}
//     </div>
//     </div>
//     <div className="flightcol">
//         <label htmlFor="toStation" className="form-label">To:</label>
//         <div>
//           <Select
//             options={airportOptionsTo}
//             value={selectedAirportTo}
//             onChange={(selectedOption) => setSelectedAirportTo(selectedOption)}
//             onInputChange={(inputValue) => setSearchTermTo(inputValue)}
//             placeholder="Enter airport name"
//           />
//           {errorTo && <p style={{ color: 'red' }}>{errorTo}</p>}
//           {selectedAirportTo ? (
//             <div>
//               {/* Additional details for selected airport if needed */}
//             </div>
//           ) : (
//             <p>No matching airports found</p>
//           )}
//         </div>
//       </div>
//         <div className="flightcol">          
//           <label htmlFor="dateOfJourney" className="form-label">Departure Date</label>
//           <input
//             type="date"
//             className="form-control"
//             id="dateOfJourney"
//           />
//         </div>
//         <div className="flightcol">
//         <label htmlFor="dateOfJourney" className="form-label">Traveler</label>
//         </div>
//         <div className="flightcol">
//           <button className="btn btn-primary" >Search Flights</button>
//         </div>
//       </div>
//        </div>
// <div class="flightsearchcontainerrow3"> </div>


// </div>

//   );
// };

// export default AirportNameSearch;
import React, { useState, useEffect,useRef } from 'react';
import axios from 'axios';
import Select from 'react-select';

const AirportNameSearch = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [airportOptions, setAirportOptions] = useState([]);
  const [selectedAirport, setSelectedAirport] = useState(null);
  const [error, setError] = useState(null);
  const [searchTermTo, setSearchTermTo] = useState('');
  const [airportOptionsTo, setAirportOptionsTo] = useState([]);
  const [selectedAirportTo, setSelectedAirportTo] = useState(null);
  const [errorTo, setErrorTo] = useState(null);
  const [isFormVisible, setFormVisible] = useState(false);

  const toggleFormVisibility = () => {
    setFormVisible(!isFormVisible);
  };
  const formRef = useRef(null);


  const closeFormOnOutsideClick = (event) => {
    if (formRef.current && !formRef.current.contains(event.target)) {
      setFormVisible(false);
    }
  };

  useEffect(() => {
    if (isFormVisible) {
      document.addEventListener('click', closeFormOnOutsideClick);
    }

    return () => {
      document.removeEventListener('click', closeFormOnOutsideClick);
    };
  }, [isFormVisible]);
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        // First API for searching by name
        const responseName = await axios.get('https://airports15.p.rapidapi.com/airports', {
          params: { name: searchTerm },
          headers: {
            'X-RapidAPI-Key': 'baa5306601msh44eade617a3c3d1p188b0djsn9f7772669cc0',
            'X-RapidAPI-Host': 'airports15.p.rapidapi.com',
          },
        });

        // Second API for searching by IATA code
        const responseIata = await axios.get('https://airport-info.p.rapidapi.com/airport', {
          params: { iata: searchTerm },
          headers: {
            'X-RapidAPI-Key': 'baa5306601msh44eade617a3c3d1p188b0djsn9f7772669cc0',
            'X-RapidAPI-Host': 'airport-info.p.rapidapi.com',
          },
        });

        // Combine the results from both APIs
        const options = [
          ...responseName.data.map((airport) => ({
            value: airport.iataCode,
            label: `${airport.iataCode} - ${airport.city}`,
          })),
          {
            value: responseIata.data.iata,
            label: `${responseIata.data.iata} - ${responseIata.data.city}`,
          },
        ];
        setAirportOptions(options);
        setError(null);
      } catch (error) {
        setAirportOptions([]);
        setError(error.message);
      }
    };
    if (searchTerm) {
      fetchData();
    }
  }, [searchTerm]);

  const handleAirportSearchDestination = async () => {
    try {
      const response = await axios.get('https://airports15.p.rapidapi.com/airports', {
        params: { name: searchTermTo },
        headers: {
          'X-RapidAPI-Key': 'baa5306601msh44eade617a3c3d1p188b0djsn9f7772669cc0',
          'X-RapidAPI-Host': 'airports15.p.rapidapi.com',
        },
      });
      const options = response.data.map((airport) => ({
        value: airport.iataCode,
        label: `${airport.iataCode} - ${airport.city}`,
      }));
      setAirportOptionsTo(options);
      setErrorTo(null);
    } catch (error) {
      setAirportOptionsTo([]);
      setErrorTo(error.message);
    }
  };

  useEffect(() => {
    if (searchTermTo) {
      handleAirportSearchDestination();
    }
  }, [searchTermTo]);
  const [selectedOption, setSelectedOption] = useState(null);

  const handleButtonClick = (option) => {
    setSelectedOption(option === selectedOption ? null : option);
  };
  return (
        <div class="flightsearchcontainer" style={{marginTop:'100px'}}>
         <div class="flightsearchcontainerrow1">
         <button
        onClick={() => handleButtonClick('oneWay')}
        style={{ backgroundColor: selectedOption === 'oneWay' ? 'green' : '' }}
      >
        One Way
      </button>
      <button
        onClick={() => handleButtonClick('return')}
        style={{ backgroundColor: selectedOption === 'return' ? 'green' : '' }}
      >
        Return
      </button>
     </div>
<div class="flightsearchcontainerrow2">
<div className="flightrow">
      <div className="flightcol">
      <label htmlFor="fromStation" className="form-label">From:</label>
      <div>
      <Select
        options={airportOptions}
        value={selectedAirport}
        onChange={(selectedOption) => setSelectedAirport(selectedOption)}
        onInputChange={(inputValue) => setSearchTerm(inputValue)}
        placeholder="Enter airport name or IATA code"
      />
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {selectedAirport ? (
        <div>
          {/* Additional details for the selected airport if needed */}
        </div>
      ) : (
        <p style={{fontSize:'0.8rem'}}>No matching airports found</p>
      )}
    </div>
    </div>
    <div className="flightcol">
        <label htmlFor="toStation" className="form-label">To:</label>
        <div>
          <Select
            options={airportOptionsTo}
            value={selectedAirportTo}
            onChange={(selectedOption) => setSelectedAirportTo(selectedOption)}
            onInputChange={(inputValue) => setSearchTermTo(inputValue)}
            placeholder="Enter airport name or IATA code"
          />
          {errorTo && <p style={{ color: 'red' }}>{errorTo}</p>}
          {selectedAirportTo ? (
            <div>
              {/* Additional details for selected airport if needed */}
            </div>
          ) : (
            <p style={{fontSize:'0.8rem'}}>No matching airports found</p>
          )}
        </div>
      </div>
        <div className="flightcol">          
          <label htmlFor="dateOfJourney" className="form-label">Departure Date</label>
          <input
            type="date"
            className="form-control"
            id="dateOfJourney"
          />
        </div>
        <div className="flightcol">
      <label className="form-label">Traveler & class </label>
      <p
        style={{ fontSize: '2rem', fontWeight: '700', cursor: 'pointer' }}
        onClick={toggleFormVisibility}
      >
        Traveller<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 0a256 256 0 1 0 0 512A256 256 0 1 0 256 0zM135 241c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l87 87 87-87c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9L273 345c-9.4 9.4-24.6 9.4-33.9 0L135 241z"/></svg>
      </p>

      {isFormVisible && (
        <div  className="dropdown-form"  style={{
          
          position: 'absolute',
          top: '55%',
          transform: 'translate(-50%, -50%)',
          width: '100%',
          padding: '10px',
          zIndex: 1000,
        }}>
    <div>Adults 12+yrs  <button>+</button> 1 <button>-</button></div>
         
        </div>
      )}
    </div>
        <div className="flightcol">
          <button className="btn btn-primary" >Search Flights</button>
        </div>
      </div>
       </div>
<div class="flightsearchcontainerrow3"> </div>
<br /><br /><br />
<div className="gradient-background">
    
</div>
</div>
  );
};
export default AirportNameSearch;